import React from 'react';
import { useDispatch } from 'react-redux';
import Icon, { ICONS } from 'components/Icon/Icon';
import colours from 'components/App/InvestoristColours.scss';

import { showAcceptCookiesPopup } from './acceptCookiesPopupSlice';

import './AcceptCookiesPopupButton.scss';

function AcceptCookiesPopupButton() {
  const dispatch = useDispatch();

  return (
    <button
      type="button"
      className="icon-button"
      onClick={() => dispatch(showAcceptCookiesPopup())}>
      <Icon
        icon={ICONS.cookies}
        colour={colours.greyDarkest}
        stroke={colours.greyDarkest}
        strokeWidth="0.4"
        width="15px"
        height="15px"
        viewBox="0 0 15 15" />
    </button>
  );
}

export default AcceptCookiesPopupButton;
