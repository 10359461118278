import { getActiveLanguage } from 'react-localize-redux';

import {
  loadLoggedInUserDetailsApi,
  quickSearchSectionsApi,
  sendTrialUpgradeRequestApi,
  getLoggedInUserSubscriptionApi,
} from 'services/user.api';
import { loadBrochureProjectDetailsApi } from 'services/brochure.api';
import { cacheBrochure } from 'cache/CacheActions';
import { loadFeaturedProjectsThunk } from 'components/ProjectPage/ProjectActions';
import { getCurrentBrochureId } from 'components/Brochure/BrochureSelectors';
import { hasUserCookies, isOnBrochurePage } from 'utility/utility';
import { initialiseIntercom } from 'utility/intercom';
import { getIntercomIntegration } from 'components/Integration/IntegrationSelectors';
import { fetchCurrentAdvertisementsThunk } from 'components/Advertisements/AdvertisementsActions';

import { getLoggedInUser } from './LoggedInUserSelectors';

export const FETCH_LOGGED_IN_USER_DETAILS = 'FETCH_LOGGED_IN_USER_DETAILS';
export const FETCH_LOGGED_IN_USER_DETAILS_SUCCESS = 'FETCH_LOGGED_IN_USER_DETAILS_SUCCESS';
export const FETCH_LOGGED_IN_USER_DETAILS_FAILURE = 'FETCH_LOGGED_IN_USER_DETAILS_FAILURE';

export const FETCH_QUICK_SEARCH_SECTIONS = 'FETCH_QUICK_SEARCH_SECTIONS';
export const FETCH_QUICK_SEARCH_SECTIONS_SUCCESS = 'FETCH_QUICK_SEARCH_SECTIONS_SUCCESS';
export const FETCH_QUICK_SEARCH_SECTIONS_FAILURE = 'FETCH_QUICK_SEARCH_SECTIONS_FAILURE';

export const SET_MEASUREMENT_UNITS = 'SET_MEASUREMENT_UNITS';

export const SET_PRESENTATION_MODE = 'SET_PRESENTATION_MODE';

export const SET_CONNECTION_UP = 'SET_CONNECTION_UP';
export const SET_CONNECTION_DOWN = 'SET_CONNECTION_DOWN';
export const SET_CONNECTION_RETRY_TIME = 'SET_CONNECTION_RETRY_TIME';

export const SET_TRIAL_POPUP_SHOWN_DATE = 'SET_TRIAL_POPUP_SHOWN_DATE';
export const RESET_TRIAL_POPUP_SHOWN_DATE = 'RESET_TRIAL_POPUP_SHOWN_DATE';

export const CHANGE_ONBOARDING_PAGE = 'CHANGE_ONBOARDING_PAGE';

export const SEND_TRIAL_UPGRADE_REQUEST = 'SEND_TRIAL_UPGRADE_REQUEST';
export const SEND_TRIAL_UPGRADE_REQUEST_SUCCESS = 'SEND_TRIAL_UPGRADE_REQUEST_SUCCESS';
export const SEND_TRIAL_UPGRADE_REQUEST_FAILURE = 'SEND_TRIAL_UPGRADE_REQUEST_FAILURE';

export const GET_LOGGED_IN_USER_SUBSCRIPTION = 'GET_LOGGED_IN_USER_SUBSCRIPTION';
export const GET_LOGGED_IN_USER_SUBSCRIPTION_SUCCESS = 'GET_LOGGED_IN_USER_SUBSCRIPTION_SUCCESS';
export const GET_LOGGED_IN_USER_SUBSCRIPTION_FAILURE = 'GET_LOGGED_IN_USER_SUBSCRIPTION_FAILURE';

// Fetch logged in user details actions
export function fetchLoggedInUserDetails() {
  return {
    type: FETCH_LOGGED_IN_USER_DETAILS,
  };
}

export function fetchLoggedInUserDetailsSuccess(loggedInUser) {
  return {
    type: FETCH_LOGGED_IN_USER_DETAILS_SUCCESS,
    loggedInUser,
  };
}

export function fetchLoggedInUserDetailsFailure(error) {
  return {
    type: FETCH_LOGGED_IN_USER_DETAILS_FAILURE,
    error,
  };
}

// Fetch the quick search links for the current user
export function fetchQuickSearchSections() {
  return {
    type: FETCH_QUICK_SEARCH_SECTIONS,
  };
}

export function fetchQuickSearchSectionsSuccess(quickSearchSections) {
  return {
    type: FETCH_QUICK_SEARCH_SECTIONS_SUCCESS,
    quickSearchSections,
  };
}

export function fetchQuickSearchSectionsFailure(error) {
  return {
    type: FETCH_QUICK_SEARCH_SECTIONS_FAILURE,
    error,
  };
}

// Set the measurement units for the user
export function setMeasurementUnits(units) {
  return {
    type: SET_MEASUREMENT_UNITS,
    units,
  };
}

// Set the presentation mode for the user
export function setPresentationMode(state) {
  return {
    type: SET_PRESENTATION_MODE,
    state,
  };
}

// Set whether a connection issue has occured or not
export function setConnectionUp() {
  return {
    type: SET_CONNECTION_UP,
  };
}
export function setConnectionDown() {
  return {
    type: SET_CONNECTION_DOWN,
  };
}

export function setConnectionRetryTime(time) {
  return {
    type: SET_CONNECTION_RETRY_TIME,
    time,
  };
}

export function setTrialPopUpShownDate(date) {
  return {
    type: SET_TRIAL_POPUP_SHOWN_DATE,
    date,
  };
}

export function resetTrialPopUpShownDate() {
  return {
    type: RESET_TRIAL_POPUP_SHOWN_DATE,
  };
}

export function changeOnboardingPage(newPage) {
  return {
    type: CHANGE_ONBOARDING_PAGE,
    newPage,
  };
}

export function sendTrialUpgradeRequest() {
  return {
    type: SEND_TRIAL_UPGRADE_REQUEST,
  };
}

export function sendTrialUpgradeRequestSuccess(trialUpgradeResult) {
  return {
    type: SEND_TRIAL_UPGRADE_REQUEST_SUCCESS,
    trialUpgradeResult,
  };
}

export function sendTrialUpgradeRequestFailure(error) {
  return {
    type: SEND_TRIAL_UPGRADE_REQUEST_FAILURE,
    error,
  };
}

export function getLoggedInUserSubscription() {
  return {
    type: GET_LOGGED_IN_USER_SUBSCRIPTION,
  };
}

export function getLoggedInUserSubscriptionSuccess(subscription) {
  return {
    type: GET_LOGGED_IN_USER_SUBSCRIPTION_SUCCESS,
    subscription,
  };
}

export function getLoggedInUserSubscriptionFailure(error) {
  return {
    type: GET_LOGGED_IN_USER_SUBSCRIPTION_FAILURE,
    error,
  };
}

// Thunks
export function fetchQuickSearchSectionsThunk() {
  return (dispatch) => {
    dispatch(fetchQuickSearchSections());
    quickSearchSectionsApi()
      .then((result) => {
        if (result.error) {
          dispatch(fetchQuickSearchSectionsFailure(new Error(result.error)));
        } else {
          dispatch(fetchQuickSearchSectionsSuccess(result.data.quickSearchSections));
        }
      }, (error) => {
        dispatch(fetchQuickSearchSectionsFailure(error));
      });
  };
}

export function fetchLoggedInUserDetailsThunk() {
  return (dispatch) => {
    if (hasUserCookies()) {
      // get the user details
      dispatch(fetchLoggedInUserDetails());
      loadLoggedInUserDetailsApi()
        .then((result) => {
          if (result.error) {
            dispatch(fetchLoggedInUserDetailsFailure(new Error(result.error)));
          } else {
            dispatch(fetchLoggedInUserDetailsSuccess(result.data.loggedInUser));
            if (!isOnBrochurePage()) {
              // get the quick search links
              dispatch(fetchQuickSearchSectionsThunk());
            }
          }
        }, (error) => {
          dispatch(fetchLoggedInUserDetailsFailure(error));
        });
    }
  };
}

export function changeLanguageThunk() {
  return (dispatch, getState) => {
    // TODO: remove this isOnBrochurePage if block and detect if the
    // activeLanguage has changed on the brochure page itself.
    // (This reloading of details should definitely not be done here!!!)

    if (isOnBrochurePage()) {
      const currentBrochureId = getCurrentBrochureId(getState());
      if (currentBrochureId) {
        loadBrochureProjectDetailsApi(currentBrochureId)
          .then((result) => {
            if (!result.error) {
              dispatch(cacheBrochure(result.data.brochure));
            }
          // eslint-disable-next-line
          }, (error) => {}); // needed to allow google bot to index our pages
      }
    } else {
      initialiseIntercom(
        getIntercomIntegration(getState()),
        getActiveLanguage(getState().localize).code,
      );

      const loggedInUser = getLoggedInUser(getState());

      if (!loggedInUser) {
        return;
      }

      // TODO: remove this code and detect if the activeLanguage has changed on
      // the search page itself. (This reloading of details should definitely
      // not be done here!!!)

      // get the quick search links
      dispatch(fetchQuickSearchSections());
      quickSearchSectionsApi()
        .then((result) => {
          if (result.error) {
            dispatch(fetchQuickSearchSectionsFailure(new Error(result.error)));
          } else {
            dispatch(fetchQuickSearchSectionsSuccess(result.data.quickSearchSections));
          }
        }, (error) => {
          dispatch(fetchQuickSearchSectionsFailure(error));
        });

      if (!loggedInUser.organisation) {
        return;
      }

      switch (loggedInUser.organisation.type) {
        case 'AGENT':
          dispatch(loadFeaturedProjectsThunk());
          break;

        case 'SELLER':
          dispatch(fetchCurrentAdvertisementsThunk());
          break;

        default:
          break;
      }
    }
  };
}

export function sendTrialUpgradeRequestThunk() {
  return (dispatch) => {
    dispatch(sendTrialUpgradeRequest());
    sendTrialUpgradeRequestApi()
      .then((result) => {
        if (result.error) {
          dispatch(sendTrialUpgradeRequestFailure(new Error(result.error)));
        } else {
          dispatch(sendTrialUpgradeRequestSuccess(result.data.sendTrialUpgradeRequest));
        }
      }, (error) => {
        dispatch(sendTrialUpgradeRequestFailure(error));
      });
  };
}

export function getLoggedInUserSubscriptionThunk() {
  return (dispatch) => {
    dispatch(getLoggedInUserSubscription());
    getLoggedInUserSubscriptionApi()
      .then((result) => {
        if (result.error) {
          dispatch(getLoggedInUserSubscriptionFailure(new Error(result.error)));
        } else {
          dispatch(getLoggedInUserSubscriptionSuccess(result.data.loggedInUser.subscription));
        }
      }, (error) => {
        dispatch(getLoggedInUserSubscriptionFailure(error));
      });
  };
}
