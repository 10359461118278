import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  getBrochureOwnerOrganisation,
  getBrochurePortalSettings
} from 'components/Brochure/BrochureSelectors';

function useBrochure() {
  const { pathname } = useLocation();
  const isBrochurePage = /^\/neo\/brochure\/.*/.test(pathname);

  const brochureOwnerOrganisation = useSelector(getBrochureOwnerOrganisation);
  const brochurePortalSettings = useSelector(getBrochurePortalSettings);

  return {
    isBrochurePage,
    brochureOwnerOrganisation,
    brochurePortalSettings,
  };
}

export default useBrochure;
