import {
  DISPLAY_POPUP,
  DISMISS_POPUP,
} from './DashboardPageActions';

function displayPopup(state, action) {
  return {
    ...state,
    currentPopup: action.popup,
    popupData: action.data,
  };
}

function dismissPopup(state, action) {
  if (action.popup === state.currentPopup) {
    return {
      ...state,
      currentPopup: null,
      popupData: null,
    };
  }

  return state;
}

const initialState = {
  currentPopup: null,
  popupData: null,
};

export default function DashboardPageReducers(state = initialState, action) {
  switch (action.type) {
    case DISPLAY_POPUP:
      return displayPopup(state, action);
    case DISMISS_POPUP:
      return dismissPopup(state, action);

    default:
      return state;
  }
}
