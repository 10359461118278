import HttpResponseError from 'services/HttpResponseError';
import NoConnectionError from 'services/NoConnectionError';
import TimeoutError from 'services/TimeoutError';
import ApiError from 'services/ApiError';

import {
  buildBrochureApi,
  loadBrochureDetailsApi,
  loadBrochureProjectDetailsApi,
  loadBrochureProjectOverviewApi,
  loadBrochureProjectImagesApi,
  loadBrochureStockListApi,
} from 'services/brochure.api';
import { cacheBrochure } from 'cache/CacheActions';

import { history } from 'utility/history';

export const BUILD_BROCHURE = 'BUILD_BROCHURE';
export const BUILD_BROCHURE_RESET = 'BUILD_BROCHURE_RESET';
export const BUILD_BROCHURE_SUCCESS = 'BUILD_BROCHURE_SUCCESS';
export const BUILD_BROCHURE_FAILURE = 'BUILD_BROCHURE_FAILURE';

export const FETCH_BROCHURE_DETAILS = 'FETCH_BROCHURE_DETAILS';
export const FETCH_BROCHURE_DETAILS_SUCCESS = 'FETCH_BROCHURE_DETAILS_SUCCESS';
export const FETCH_BROCHURE_DETAILS_FAILURE = 'FETCH_BROCHURE_DETAILS_FAILURE';

export const FETCH_BROCHURE_PROJECT_DETAILS = 'FETCH_BROCHURE_PROJECT_DETAILS';
export const FETCH_BROCHURE_PROJECT_DETAILS_SUCCESS = 'FETCH_BROCHURE_PROJECT_DETAILS_SUCCESS';
export const FETCH_BROCHURE_PROJECT_DETAILS_FAILURE = 'FETCH_BROCHURE_PROJECT_DETAILS_FAILURE';

export const FETCH_BROCHURE_PROJECT_OVERVIEW = 'FETCH_BROCHURE_PROJECT_OVERVIEW';
export const FETCH_BROCHURE_PROJECT_OVERVIEW_SUCCESS = 'FETCH_BROCHURE_PROJECT_OVERVIEW_SUCCESS';
export const FETCH_BROCHURE_PROJECT_OVERVIEW_FAILURE = 'FETCH_BROCHURE_PROJECT_OVERVIEW_FAILURE';

export const FETCH_BROCHURE_PROJECT_IMAGES = 'FETCH_BROCHURE_PROJECT_IMAGES';
export const FETCH_BROCHURE_PROJECT_IMAGES_SUCCESS = 'FETCH_BROCHURE_PROJECT_IMAGES_SUCCESS';
export const FETCH_BROCHURE_PROJECT_IMAGES_FAILURE = 'FETCH_BROCHURE_PROJECT_IMAGES_FAILURE';

export const FETCH_BROCHURE_STOCK_LIST = 'FETCH_BROCHURE_STOCK_LIST';
export const FETCH_BROCHURE_STOCK_LIST_SUCCESS = 'FETCH_BROCHURE_STOCK_LIST_SUCCESS';
export const FETCH_BROCHURE_STOCK_LIST_FAILURE = 'FETCH_BROCHURE_STOCK_LIST_FAILURE';

export function buildBrochure() {
  return {
    type: BUILD_BROCHURE,
  };
}

export function buildBrochureReset() {
  return {
    type: BUILD_BROCHURE_RESET,
  };
}

export function buildBrochureSuccess(brochureId) {
  return {
    type: BUILD_BROCHURE_SUCCESS,
    brochureId,
  };
}

export function buildBrochureFailure(error) {
  return {
    type: BUILD_BROCHURE_FAILURE,
    error,
  };
}

export function fetchBrochureDetails(brochureId) {
  return {
    type: FETCH_BROCHURE_DETAILS,
    brochureId,
  };
}

export function fetchBrochureDetailsSuccess(brochuredetails) {
  return {
    type: FETCH_BROCHURE_DETAILS_SUCCESS,
    brochuredetails,
  };
}

export function fetchBrochureDetailsFailure(error) {
  return {
    type: FETCH_BROCHURE_DETAILS_FAILURE,
    error,
  };
}

export function fetchBrochureProjectDetails(brochureId) {
  return {
    type: FETCH_BROCHURE_PROJECT_DETAILS,
    brochureId,
  };
}

export function fetchBrochureProjectDetailsSuccess() {
  return {
    type: FETCH_BROCHURE_PROJECT_DETAILS_SUCCESS,
  };
}

export function fetchBrochureProjectDetailsFailure(error) {
  return {
    type: FETCH_BROCHURE_PROJECT_DETAILS_FAILURE,
    error,
  };
}

export function fetchBrochureProjectOverview(brochureId) {
  return {
    type: FETCH_BROCHURE_PROJECT_OVERVIEW,
    brochureId,
  };
}

export function fetchBrochureProjectOverviewSuccess() {
  return {
    type: FETCH_BROCHURE_PROJECT_OVERVIEW_SUCCESS,
  };
}

export function fetchBrochureProjectOverviewFailure(error) {
  return {
    type: FETCH_BROCHURE_PROJECT_OVERVIEW_FAILURE,
    error,
  };
}

export function fetchBrochureProjectImages(brochureId) {
  return {
    type: FETCH_BROCHURE_PROJECT_IMAGES,
    brochureId,
  };
}

export function fetchBrochureProjectImagesSuccess() {
  return {
    type: FETCH_BROCHURE_PROJECT_IMAGES_SUCCESS,
  };
}

export function fetchBrochureProjectImagesFailure(error) {
  return {
    type: FETCH_BROCHURE_PROJECT_IMAGES_FAILURE,
    error,
  };
}

export function fetchBrochureStockList(brochureId) {
  return {
    type: FETCH_BROCHURE_STOCK_LIST,
    brochureId,
  };
}

export function fetchBrochureStockListSuccess() {
  return {
    type: FETCH_BROCHURE_STOCK_LIST_SUCCESS,
  };
}

export function fetchBrochureStockListFailure(error) {
  return {
    type: FETCH_BROCHURE_STOCK_LIST_FAILURE,
    error,
  };
}

// Thunks
export function buildBrochureThunk(title, description, includedDistributionIds) {
  return (dispatch) => {
    dispatch(buildBrochure());
    buildBrochureApi(title, description, Array.from(includedDistributionIds))
      .then((result) => {
        if (result.error) throw new Error(result.error);

        dispatch(buildBrochureSuccess(result.data.buildBrochure));
      })
      .catch((error) => {
        dispatch(buildBrochureFailure(error));
      });
  };
}

function handleBrochureQuery(promise, onResult, onError) {
  return promise
    .catch((error) => {
      if (error instanceof HttpResponseError) {
        return error.response.json();
      }

      throw error;
    })
    .then((result) => {
      if (!result.errors) {
        return result;
      }

      if (result.errors.length === 1) {
        const errorCode = result.errors[0].extensions.code;
        if (errorCode === 'NO_LONGER_AVAILABLE') {
          result.error = errorCode;
          return result;
        }
      }

      throw new ApiError(result.errors);
    })
    .then((result) => {
      if (result.error) throw new Error(result.error);

      onResult(result);
    })
    .catch((error) => {
      if (error instanceof TimeoutError || error instanceof NoConnectionError) {
        if (history.location.pathname !== '/neo/Status/ConnectionIssue') {
          history.push('/neo/Status/ConnectionIssue');
        }
      }

      throw error;
    })
    .catch(onError);
}

export function fetchBrochureDetailsThunk(brochureId) {
  return (dispatch) => {
    dispatch(fetchBrochureDetails(brochureId));
    handleBrochureQuery(
      loadBrochureDetailsApi(brochureId),
      (result) => {
        const { brochure } = result.data;
        dispatch(cacheBrochure(brochure));
        dispatch(fetchBrochureDetailsSuccess(brochure));
      },
      (error) => {
        dispatch(fetchBrochureDetailsFailure(error));
      }
    );
  };
}

export function fetchBrochureProjectDetailsThunk(brochureId) {
  return (dispatch) => {
    dispatch(fetchBrochureProjectDetails(brochureId));
    handleBrochureQuery(
      loadBrochureProjectDetailsApi(brochureId),
      (result) => {
        const { brochure } = result.data;
        dispatch(cacheBrochure(brochure));
        dispatch(fetchBrochureProjectDetailsSuccess(brochure));
      },
      (error) => {
        dispatch(fetchBrochureProjectDetailsFailure(error));
      }
    );
  };
}

export function fetchBrochureProjectOverviewThunk(brochureId) {
  return (dispatch) => {
    dispatch(fetchBrochureProjectOverview(brochureId));
    handleBrochureQuery(
      loadBrochureProjectOverviewApi(brochureId),
      (result) => {
        const { brochure } = result.data;
        dispatch(cacheBrochure(brochure));
        dispatch(fetchBrochureProjectOverviewSuccess(brochure));
      },
      (error) => {
        dispatch(fetchBrochureProjectOverviewFailure(error));
      }
    );
  };
}

export function fetchBrochureProjectImagesThunk(brochureId) {
  return (dispatch) => {
    dispatch(fetchBrochureProjectImages(brochureId));
    handleBrochureQuery(
      loadBrochureProjectImagesApi(brochureId),
      (result) => {
        const { brochure } = result.data;
        dispatch(cacheBrochure(brochure));
        dispatch(fetchBrochureProjectImagesSuccess(brochure));
      },
      (error) => {
        dispatch(fetchBrochureProjectImagesFailure(error));
      }
    );
  };
}

export function fetchBrochureStockListThunk(brochureId) {
  return (dispatch) => {
    dispatch(fetchBrochureStockList(brochureId));
    handleBrochureQuery(
      loadBrochureStockListApi(brochureId),
      (result) => {
        const { brochure } = result.data;
        dispatch(cacheBrochure(brochure));
        dispatch(fetchBrochureStockListSuccess(brochure));
      },
      (error) => {
        dispatch(fetchBrochureStockListFailure(error));
      }
    );
  };
}
