import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';

import { getAlertPopupCustomData } from './AlertPopupSelectors';

import './AlertPopup.scss';

export default function SimpleAlertPopup({
  isVisible,
  heading,
  content,
  icon,
  button,
  closeable,
  onHide,
}) {
  const customData = useSelector(getAlertPopupCustomData);

  if (!isVisible) {
    return null;
  }

  const onClick = () => {
    if (button.func) {
      button.func(customData);
    }
  };

  function renderContent() {
    if (content instanceof Array) {
      return content.map(({ key, value }) => value && <p test-attr="content-item" key={key} className="text-center">{value}</p>);
    }

    return <p test-attr="content-item" className="text-center">{content}</p>;
  }

  return (
    <Modal
      test-attr="component-alert-popup"
      className="alertPopup"
      show={isVisible}
      onHide={onHide}>
      <Modal.Header closeButton={closeable} />
      <Modal.Body test-attr="alert-popup-content">
        {icon}
        <h1 test-attr="heading" className="text-center">{heading}</h1>
        {content && renderContent()}
        {button && (
          <Button test-attr="custom-button" className={button.className} onClick={onClick}>
            {button.text}
          </Button>
        )}
      </Modal.Body>
    </Modal>
  );
}

SimpleAlertPopup.defaultProps = {
  content: null,
  icon: null,
  button: null,
  closeable: true,
};

SimpleAlertPopup.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  heading: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.any,
        value: PropTypes.any
      })
    )
  ]),
  icon: PropTypes.node,
  button: PropTypes.shape({
    text: PropTypes.string,
    className: PropTypes.string,
    func: PropTypes.func
  }),
  closeable: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
};
