/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-number-input';

import InputField from 'components/InputFields/InputField/InputField';

import './PhoneInputField.css';

function PhoneInputField({
  label,
  labelDescription,
  meta,
  usersCountry,
  change,
  placeholder,
}) {
  useEffect(() => { change('countryCode', usersCountry); }, []);
  const [value, setValue] = useState();
  const [country, setCountry] = useState();

  const { touched, error } = meta;

  return (
    <InputField
      test-attr="component-phone-input-field"
      label={label}
      labelDescription={labelDescription}
      meta={meta}>
      <div className={`${touched && error ? 'redInputBorder' : ''} phone-input form-control`}>
        <PhoneInput
          value={value}
          international={false}
          defaultCountry={usersCountry}
          country={country}
          placeholder={placeholder}
          onChange={(value) => {
            setValue(value);
            change('phone', value || '');
          }}
          onCountryChange={(country) => {
            setCountry(country);
            change('countryCode', country);
          }} />
      </div>
    </InputField>
  );
}

PhoneInputField.defaultProps = {
  labelDescription: null,
};

PhoneInputField.propTypes = {
  label: PropTypes.string.isRequired,
  labelDescription: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    error: PropTypes.string,
  }).isRequired,
  usersCountry: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default PhoneInputField;
