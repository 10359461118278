import { loadPortalApi } from 'services/portal.api';

export const FETCH_PORTAL = 'FETCH_PORTAL';
export const FETCH_PORTAL_SUCCESS = 'FETCH_PORTAL_SUCCESS';
export const FETCH_PORTAL_FAILURE = 'FETCH_PORTAL_FAILURE';

export function fetchPortal() {
  return {
    type: FETCH_PORTAL,
  };
}

export function fetchPortalSuccess(portal) {
  return {
    type: FETCH_PORTAL_SUCCESS,
    portal,
  };
}

export function fetchPortalFailure() {
  return {
    type: FETCH_PORTAL_FAILURE,
  };
}

export function fetchPortalThunk() {
  return (dispatch) => {
    dispatch(fetchPortal());
    loadPortalApi()
      .then((result) => {
        if (result.error) {
          dispatch(fetchPortalFailure(new Error(result.error)));
        } else {
          dispatch(fetchPortalSuccess(result.data.portal));
        }
      }, (error) => {
        dispatch(fetchPortalFailure(error));
      });
  };
}
