import { graphQLQuery } from './api';

export function loadAgentProfileDetailsApi(id) {
  const queryString = `
  query ($id: InvestoristID!) {
    agentprofile(id: $id) {
        id,
        name,
        logo { id, url},
        phone,
        email,
        description { short },
            owner {
                id,
                firstName,
                lastName,
                phone,
                lastLogin,
                profileImage { url }
            },
            address {
                fullDescription,
                shortDescription
                country { 
                  id,
                  code,
                  name
                },
                stateOrRegion {
                  id,
                  name
                }
           }
      }
  }`;
  const variables = {
    id,
  };
  return graphQLQuery(queryString, variables);
}

export function agentProjectViewsByLocationApi(agentId, eventTypeFilter, timeRangeFilter) {
  const queryString = `
    query ($agentId: ID!, $eventTypeFilter: GraphableEventType!, $timeRangeFilter: TimeRangeFilterInput) {
      agentProjectView(id: $agentId, eventTypeFilter: $eventTypeFilter, timeRangeFilter: $timeRangeFilter) {
        geocoordinates { latitude, longitude },
        viewport {
          northeast { latitude, longitude },
          southwest { latitude, longitude }
        }
      }
    }`;
  const variables = {
    agentId,
    eventTypeFilter,
    timeRangeFilter
  };
  return graphQLQuery(queryString, variables);
}
