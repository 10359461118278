export const GET_CURRENT_POSITION_RESULTS = 'GET_CURRENT_POSITION_RESULTS';
export const GET_CURRENT_POSITION_FAILURE = 'GET_CURRENT_POSITION_FAILURE';

export function getCurrentPositionResults(results) {
  return {
    type: GET_CURRENT_POSITION_RESULTS,
    results
  };
}

export function getCurrentPositionFailure(error) {
  return {
    type: GET_CURRENT_POSITION_FAILURE,
    error
  };
}

export function getCurrentPositionThunk() {
  return (dispatch) => {
    const { geolocation } = navigator;

    const location = new Promise((resolve, reject) => {
      if (!geolocation) {
        reject(new Error('Not Supported'));
      }

      geolocation.getCurrentPosition((position) => {
        resolve(position);
      }, (error) => {
        reject(error);
      });
    });

    location
      .then((result) => {
        dispatch(getCurrentPositionResults(result));
      }, (positionError) => {
        const error = new Error(positionError.message);
        error.positionError = positionError;
        dispatch(getCurrentPositionFailure(error));
      });
  };
}
