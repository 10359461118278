import { graphQLQuery } from './api';

export function initiateChatApi(id, message, contextId) {
  const mutationString = `
  mutation ($id: InvestoristID!, $message: String!, $contextId: InvestoristID) {
    initiateChat(id: $id, message: $message, contextId: $contextId) {
      conversationExternalId
    }
  }`;
  const variables = {
    id,
    message,
    contextId
  };
  return graphQLQuery(mutationString, variables, { errorHandler: null });
}
