import {
  SUBMIT_SEARCH,

  SUBMIT_SEARCH_QUERY_VIEWPORT,
  SEARCH_QUERY_VIEWPORT_RESULTS,
  SEARCH_QUERY_VIEWPORT_FAILURE,

  SUBMIT_PROJECT_SEARCH,
  PROJECT_SEARCH_RESULTS,
  PROJECT_SEARCH_FAILURE,

  SUBMIT_VIEWPORT_SEARCH,
  VIEWPORT_SEARCH_RESULTS,
  VIEWPORT_SEARCH_FAILURE,

  SUBMIT_LOCATION_SEARCH,
  LOCATION_SEARCH_RESULTS,
  LOCATION_SEARCH_FAILURE,

  FETCH_ALL_PROJECTS,
  FETCH_ALL_PROJECTS_SUCCESS,
  FETCH_ALL_PROJECTS_FAILURE,

  SET_CURRENT_MAP_VIEWPORT,

  CLEAR_SEARCH_DATA,

  START_HOVER_SEARCH_RESULT,
  END_HOVER_SEARCH_RESULT,
} from './SearchActions';

function submitSearch(state, action) {
  return {
    ...state,
    searchQuery: action.searchQuery,
    searchFilters: action.searchFilters
  };
}

function submitSearchQueryViewport(state) {
  return {
    ...state,

    searchQueryViewportLoading: true,
    searchQueryViewportViewport: null,
    searchQueryViewportCountryCode: null,
    searchQueryViewportStateOrRegion: null,
    searchQueryViewportError: null,

    searchSeed: Math.random(),
  };
}

function searchQueryViewportResults(state, action) {
  const projectIdList = [];
  action.results.projectResults.forEach((project) => {
    projectIdList.push(project.item.id);
  });

  return {
    ...state,
    searchQueryViewportLoading: false,
    searchQueryViewportViewport: action.results.viewport,
    searchQueryViewportCountryCode:
      action.results.country ? action.results.country.code : null,
    searchQueryViewportStateOrRegion:
      action.results.stateOrRegion ? action.results.stateOrRegion.abbreviation : null,
    searchQueryViewportError: null,
    viewportSearchResults: [
      ...new Set([
        ...state.viewportSearchResults,
        ...projectIdList,
      ]),
    ],
  };
}

function searchQueryViewportFailure(state, action) {
  return {
    ...state,
    searchQueryViewportLoading: false,
    searchQueryViewportViewport: null,
    searchQueryViewportCountryCode: null,
    searchQueryViewportStateOrRegion: null,
    searchQueryViewportError: action.error,
  };
}

function submitProjectSearch(state) {
  return {
    ...state,
    projectSearchLoading: true,
    projectSearchViewport: null,
    projectSearchCountryCode: null,
    projectSearchStateOrRegion: null,
    projectSearchResults: [],
    projectSearchError: null,
  };
}

function projectSearchResults(state, action) {
  const projectIdList = [];
  action.results.projectResults.forEach((project) => {
    projectIdList.push(project.item.id);
  });

  return {
    ...state,
    projectSearchLoading: false,
    projectSearchViewport: action.results.viewport,
    projectSearchCountryCode:
      action.results.country ? action.results.country.code : null,
    projectSearchStateOrRegion:
      action.results.stateOrRegion ? action.results.stateOrRegion.abbreviation : null,
    projectSearchResults: projectIdList,
    projectSearchError: null,
  };
}

function projectSearchError(state, action) {
  return {
    ...state,
    projectSearchLoading: false,
    projectSearchViewport: null,
    projectSearchCountryCode: null,
    projectSearchStateOrRegion: null,
    projectSearchResults: [],
    projectSearchError: action.error,
  };
}

function submitViewportSearch(state, action) {
  return {
    ...state,
    viewportSearchLoading: true,
    viewportSearchViewport: action.viewport,
    viewportSearchExcludedViewport: action.excludedViewport,
    viewportSearchError: null,
    viewportSearchLastViewport: state.viewportSearchViewport,
    viewportSearchCount: state.viewportSearchCount + 1,
  };
}

function viewportSearchResults(state, action) {
  const projectIdList = [];
  action.results.projectResults.forEach((project) => {
    projectIdList.push(project.item.id);
  });

  return {
    ...state,
    viewportSearchLoading: false,
    viewportSearchResults: [
      ...new Set([
        ...state.viewportSearchResults,
        ...projectIdList,
      ]),
    ],
    viewportSearchError: null,
  };
}

function viewportSearchFailure(state, action) {
  return {
    ...state,
    viewportSearchLoading: false,
    viewportSearchViewport: null,
    viewportSearchExcludedViewport: null,
    viewportSearchResults: [],
    viewportSearchError: action.error,
    viewportSearchLastViewport: null,
  };
}

function submitLocationSearch(state) {
  return {
    ...state,
    locationSearchLoading: true,
    locationSearchViewport: null,
    locationSearchRegionId: null,
    locationSearchResults: [],
    locationSearchError: null,
  };
}

function locationSearchResults(state, action) {
  const projectIdList = [];
  action.results.projectResults.forEach((project) => {
    projectIdList.push(project.item.id);
  });

  return {
    ...state,
    locationSearchLoading: false,
    locationSearchViewport: action.results.viewport,
    locationSearchRegionId: action.results.region.id,
    locationSearchResults: projectIdList,
    locationSearchError: null,
  };
}

function locationSearchFailure(state, action) {
  return {
    ...state,
    locationSearchLoading: false,
    locationSearchViewport: null,
    locationSearchRegionId: null,
    locationSearchResults: [],
    locationSearchError: action.error,
  };
}

function fetchAllProjects(state) {
  return {
    ...state,
    fetchAllProjectsLoading: true,
    fetchAllProjectsError: null,
    fetchAllProjectsViewport: null,
    fetchAllProjectsResults: [],
  };
}

function fetchAllProjectsSuccess(state, action) {
  const projectIdList = [];
  action.results.projectResults.forEach((project) => {
    projectIdList.push(project.item.id);
  });

  return {
    ...state,
    fetchAllProjectsLoading: false,
    fetchAllProjectsViewport: action.results.viewport,
    fetchAllProjectsResults: [
      ...new Set([
        ...projectIdList,
      ]),
    ],
  };
}

function fetchAllProjectsFailure(state, action) {
  return {
    ...state,
    fetchAllProjectsLoading: false,
    fetchAllProjectsError: action.error,
    fetchAllProjectsViewport: null,
    fetchAllProjectsResults: [],
  };
}

function setCurrentMapViewport(state, action) {
  return {
    ...state,
    currentMapViewport: action.viewport,
  };
}

function startHoverSearchResult(state, action) {
  return {
    ...state,
    hoveredResult: action.id,
  };
}

function endHoverSearchResult(state) {
  return {
    ...state,
    hoveredResult: null,
  };
}

const initialState = {
  searchQuery: null,
  searchFilters: null,

  searchQueryViewportLoading: false,
  searchQueryViewportViewport: null,
  searchQueryViewportCountryCode: null,
  searchQueryViewportStateOrRegion: null,
  searchQueryViewportError: null,

  projectSearchLoading: false,
  projectSearchViewport: null,
  projectSearchCountryCode: null,
  projectSearchStateOrRegion: null,
  projectSearchResults: [],
  projectSearchError: null,

  viewportSearchLoading: false,
  viewportSearchViewport: null,
  viewportSearchExcludedViewport: null,
  viewportSearchResults: [],
  viewportSearchError: null,
  viewportSearchLastViewport: null,
  viewportSearchCount: 0,

  locationSearchLoading: false,
  locationSearchViewport: null,
  locationSearchRegionId: null,
  locationSearchResults: [],
  locationSearchError: null,

  fetchAllProjectsLoading: false,
  fetchAllProjectsError: null,
  fetchAllProjectsViewport: null,
  fetchAllProjectsResults: [],

  currentMapViewport: null,

  searchSeed: 1,

  hoveredResult: null,
};

function clearSearchData(state) {
  return {
    ...state,
    ...initialState,
  };
}

export default function SearchReducers(state = initialState, action) {
  switch (action.type) {
    case SUBMIT_SEARCH:
      return submitSearch(state, action);

    case SUBMIT_SEARCH_QUERY_VIEWPORT:
      return submitSearchQueryViewport(state);
    case SEARCH_QUERY_VIEWPORT_RESULTS:
      return searchQueryViewportResults(state, action);
    case SEARCH_QUERY_VIEWPORT_FAILURE:
      return searchQueryViewportFailure(state, action);

    case SUBMIT_PROJECT_SEARCH:
      return submitProjectSearch(state);
    case PROJECT_SEARCH_RESULTS:
      return projectSearchResults(state, action);
    case PROJECT_SEARCH_FAILURE:
      return projectSearchError(state, action);

    case SUBMIT_VIEWPORT_SEARCH:
      return submitViewportSearch(state, action);
    case VIEWPORT_SEARCH_RESULTS:
      return viewportSearchResults(state, action);
    case VIEWPORT_SEARCH_FAILURE:
      return viewportSearchFailure(state, action);

    case SUBMIT_LOCATION_SEARCH:
      return submitLocationSearch(state);
    case LOCATION_SEARCH_RESULTS:
      return locationSearchResults(state, action);
    case LOCATION_SEARCH_FAILURE:
      return locationSearchFailure(state, action);

    case FETCH_ALL_PROJECTS:
      return fetchAllProjects(state);
    case FETCH_ALL_PROJECTS_SUCCESS:
      return fetchAllProjectsSuccess(state, action);
    case FETCH_ALL_PROJECTS_FAILURE:
      return fetchAllProjectsFailure(state, action);

    case SET_CURRENT_MAP_VIEWPORT:
      return setCurrentMapViewport(state, action);

    case CLEAR_SEARCH_DATA:
      return clearSearchData(state);

    case START_HOVER_SEARCH_RESULT:
      return startHoverSearchResult(state, action);
    case END_HOVER_SEARCH_RESULT:
      return endHoverSearchResult(state);

    default:
      return state;
  }
}
