import {
  SHOW_SIGN_IN_NOTIFICATION_POPUP,
  HIDE_SIGN_IN_NOTIFICATION_POPUP,
  SHOW_SIGN_IN_VIA_EMAIL_FORM,
} from './SigninNotificationPopupActions';

function showSignInNotificationPopup(state, action) {
  return {
    ...state,
    visible: true,
    canClose: action.canClose,
  };
}

function hideSignInNotificationPopup(state) {
  return {
    ...state,
    visible: false,
  };
}

function showSigninViaEmailForm(state, action) {
  return {
    ...state,
    showEmailForm: action.showEmailForm,
  };
}

const initialState = {
  visible: true,
  canClose: true,
  showEmailForm: false,
};

export default function SignInNotificationPopupReducers(state = initialState, action) {
  switch (action.type) {
    case SHOW_SIGN_IN_NOTIFICATION_POPUP:
      return showSignInNotificationPopup(state, action);
    case HIDE_SIGN_IN_NOTIFICATION_POPUP:
      return hideSignInNotificationPopup(state, action);
    case SHOW_SIGN_IN_VIA_EMAIL_FORM:
      return showSigninViaEmailForm(state, action);
    default:
      return state;
  }
}
