import { getCurrentUserAlertsApi } from 'services/alerts.api';

export const FETCH_CURRENT_USER_ALERTS = 'FETCH_CURRENT_USER_ALERTS';
export const FETCH_CURRENT_USER_ALERTS_SUCCESS = 'FETCH_CURRENT_USER_ALERTS_SUCCESS';
export const FETCH_CURRENT_USER_ALERTS_FAILURE = 'FETCH_CURRENT_USER_ALERTS_FAILURE';

export const DISMISS_ALERT = 'DISMISS_ALERT';

export function fetchCurrentUserAlerts() {
  return {
    type: FETCH_CURRENT_USER_ALERTS,
  };
}

export function fetchCurrentUserAlertsSuccess(alerts) {
  return {
    type: FETCH_CURRENT_USER_ALERTS_SUCCESS,
    alerts,
  };
}

export function fetchCurrentUserAlertsFailure(error) {
  return {
    type: FETCH_CURRENT_USER_ALERTS_FAILURE,
    error,
  };
}

export function dismissAlert(alertType) {
  return {
    type: DISMISS_ALERT,
    alertType,
  };
}

export function fetchCurrentUserAlertsThunk() {
  return (dispatch) => {
    dispatch(fetchCurrentUserAlerts());
    getCurrentUserAlertsApi()
      .then((result) => {
        if (result.error) {
          dispatch(fetchCurrentUserAlertsFailure(new Error(result.error)));
        } else {
          dispatch(fetchCurrentUserAlertsSuccess(result.data.currentUserAlerts));
        }
      }, (error) => {
        dispatch(fetchCurrentUserAlertsFailure(error));
      });
  };
}
