import HttpResponseError from 'services/HttpResponseError';
import NoConnectionError from 'services/NoConnectionError';
import TimeoutError from 'services/TimeoutError';

import {
  isFollowingApi,
  setFollowingApi,
} from 'services/follow.api';

import { history } from 'utility/history';

export const REQUEST_IS_FOLLOWING_STATUS = 'REQUEST_IS_FOLLOWING_STATUS';
export const REQUEST_IS_FOLLOWING_STATUS_SUCCESS = 'REQUEST_IS_FOLLOWING_STATUS_SUCCESS';
export const REQUEST_IS_FOLLOWING_STATUS_FAILURE = 'REQUEST_IS_FOLLOWING_STATUS_FAILURE';

export const SET_FOLLOWING_SUCCESS = 'SET_FOLLOWING_SUCCESS';
export const SET_FOLLOWING_FAILURE = 'SET_FOLLOWING_FAILURE';

export function requestIsFollowingStatus() {
  return {
    type: REQUEST_IS_FOLLOWING_STATUS,
  };
}

export function requestIsFollowingStatusSuccess(id, status) {
  return {
    type: REQUEST_IS_FOLLOWING_STATUS_SUCCESS,
    id,
    status,
  };
}

export function requestIsFollowingStatusFailure(error) {
  return {
    type: REQUEST_IS_FOLLOWING_STATUS_FAILURE,
    error,
  };
}

export function setFollowingSuccess(id, status) {
  return {
    type: SET_FOLLOWING_SUCCESS,
    id,
    status,
  };
}

export function setFollowingFailure(error) {
  return {
    type: SET_FOLLOWING_FAILURE,
    error,
  };
}

// Thunks
export function requestIsFollowingThunk(id) {
  return (dispatch) => {
    dispatch(requestIsFollowingStatus());
    isFollowingApi(id)
      .catch((error) => {
        if (error instanceof HttpResponseError) {
          return error.response.json();
        }
        throw error;
      })
      .then((result) => {
        if (result.errors) {
          throw new Error(result.errors);
        }
        return result;
      })
      .then((result) => {
        dispatch(requestIsFollowingStatusSuccess(id, result.data.isFollowing));
      })
      .catch((error) => {
        if (error instanceof TimeoutError || error instanceof NoConnectionError) {
          if (history.location.pathname !== '/neo/Status/ConnectionIssue') {
            history.push('/neo/Status/ConnectionIssue');
          }
        } else {
          dispatch(requestIsFollowingStatusFailure(error));
        }
      });
  };
}

export function setFollowingThunk(id, status) {
  return (dispatch) => {
    setFollowingApi(id, status)
      .catch((error) => {
        if (error instanceof HttpResponseError) {
          return error.response.json();
        }
        throw error;
      })
      .then((result) => {
        if (result.errors) {
          throw new Error(result.errors);
        }
        return result;
      })
      .then((result) => {
        if (result.data) {
          dispatch(setFollowingSuccess(id, status));
        }
      })
      .catch((error) => {
        if (error instanceof TimeoutError || error instanceof NoConnectionError) {
          if (history.location.pathname !== '/neo/Status/ConnectionIssue') {
            history.push('/neo/Status/ConnectionIssue');
          }
        } else {
          dispatch(requestIsFollowingStatusFailure(error));
        }
      });
  };
}
