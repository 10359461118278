function getHexadecimalRegularExpression(characterCount) {
  return new RegExp('^#[0-9A-Fa-f]{' + characterCount + '}$');
}

export function isValidColourFormat(value, ...acceptedFormats) {
  for (let i = 0; i < acceptedFormats.length; i += 1) {
    switch (acceptedFormats[i]) {
      case 'Hexadecimal3':
        if (getHexadecimalRegularExpression(3).test(value)) {
          return true;
        }
        break;

      case 'Hexadecimal4':
        if (getHexadecimalRegularExpression(4).test(value)) {
          return true;
        }
        break;

      case 'Hexadecimal6':
        if (getHexadecimalRegularExpression(6).test(value)) {
          return true;
        }
        break;

      case 'Hexadecimal8':
        if (getHexadecimalRegularExpression(8).test(value)) {
          return true;
        }
        break;

      default:
        break;
    }
  }

  return false;
}
