import { createSelector } from 'reselect';

const pageLoadIdSelector = (state) => state.app.pageLoadId;
const referrerUrlSelector = (state) => state.app.referrerUrl;

export const getPageLoadId = createSelector(
  [pageLoadIdSelector],
  (pageLoadId) => (pageLoadId === null ? '' : pageLoadId),
);

export const getReferrerUrl = createSelector(
  [referrerUrlSelector],
  (state) => state,
);
