import { useEffect } from 'react';

import useEnvironment from 'hooks/useEnvironment';
import useBrochure from 'hooks/useBrochure';

const setBrandColours = ({
  mainBrandColour, mainBrandColourDark, secondaryBrandColour, secondaryBrandColourDark
}) => {
  document.documentElement.style.setProperty('--main-brand-colour', mainBrandColour);
  document.documentElement.style.setProperty('--secondary-brand-colour', secondaryBrandColour);
  document.documentElement.style.setProperty('--main-brand-colour-dark', mainBrandColourDark || mainBrandColour);
  if (secondaryBrandColourDark) {
    document.documentElement.style.setProperty('--secondary-brand-colour-dark', secondaryBrandColourDark);
    document.documentElement.style.setProperty('--secondary-brand-colour-dark-highlight', secondaryBrandColour);
  } else {
    document.documentElement.style.setProperty('--secondary-brand-colour-dark', 'white');
    document.documentElement.style.setProperty('--secondary-brand-colour-dark-highlight', 'white');
  }
};

function useBranding() {
  const { environment } = useEnvironment();
  const { isBrochurePage, brochurePortalSettings } = useBrochure();

  useEffect(() => {
    if (brochurePortalSettings) {
      setBrandColours(brochurePortalSettings);
      return;
    }

    if (!isBrochurePage && environment) {
      setBrandColours(environment);
    }
  }, [environment, brochurePortalSettings, isBrochurePage]);
}

export default useBranding;
