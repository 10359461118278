import React from 'react';
import { Translate } from 'react-localize-redux';
import { Row } from 'react-bootstrap';

import verifyImage from 'assets/images/pending-agent-ob-verifiy.png';

import OnboardingModal from '../OnboardingModal/OnboardingModal';

const PendingAgentOnboarding = () => {
  const renderPage1 = () => (
    <div>
      <div className="imageSection">
        <img src={verifyImage} alt="Onboarding overview" width="100%" />
      </div>
      <div className="textSection">
        <Row className="text-center">
          <h1>
            <Translate id="onboarding.pendingAgent.page1.heading" />
          </h1>
        </Row>
        <Row className="text-center">
          <p>
            <Translate id="onboarding.pendingAgent.page1.message" />
          </p>
        </Row>
      </div>
    </div>
  );

  const onboardingSlides = [renderPage1()];

  return (
    <OnboardingModal
      onboardingSlides={onboardingSlides}
      localStorageKey="lastOnboardingPagePendingAgent" />
  );
};

export default PendingAgentOnboarding;
