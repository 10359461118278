import React from 'react';
import { Translate } from 'react-localize-redux';
import { Row } from 'react-bootstrap';

import overviewImage from 'assets/images/ob-overview.png';
import projectInformationImage from 'assets/images/agent-ob-project-info.png';
import messagingImage from 'assets/images/agent-ob-messaging.png';
import brochureImage from 'assets/images/agent-ob-brochure.png';
import reservationImage from 'assets/images/agent-ob-reservation.png';

import OnboardingModal from '../OnboardingModal/OnboardingModal';

const AgentOnboarding = () => {
  const renderPage1 = () => (
    <div>
      <div className="imageSection">
        <img
          src={overviewImage}
          alt="Onboarding overview"
          width="100%" />
      </div>
      <div className="textSection">
        <Row className="text-center">
          <h1>
            <Translate id="onboarding.agent.page1.heading" />
          </h1>
        </Row>
        <Row className="text-center">
          <p>
            <Translate id="onboarding.agent.page1.message" />
          </p>
        </Row>
      </div>
    </div>
  );

  const renderPage2 = () => (
    <div>
      <div className="imageSection">
        <img
          src={projectInformationImage}
          alt="Onboarding project"
          width="100%" />
      </div>
      <div className="textSection">
        <Row className="text-center">
          <h1>
            <Translate id="onboarding.agent.page2.heading" />
          </h1>
        </Row>
        <Row className="text-center">
          <p>
            <Translate id="onboarding.agent.page2.message" />
          </p>
        </Row>
      </div>
    </div>
  );

  const renderPage3 = () => (
    <div>
      <div className="imageSection">
        <img
          src={messagingImage}
          alt="Onboarding messaging"
          width="100%" />
      </div>
      <div className="textSection">
        <Row className="text-center">
          <h1>
            <Translate id="onboarding.agent.page3.heading" />
          </h1>
        </Row>
        <Row className="text-center">
          <p>
            <Translate id="onboarding.agent.page3.message" />
          </p>
        </Row>
      </div>
    </div>
  );

  const renderPage4 = () => (
    <div>
      <div className="imageSection">
        <img
          src={brochureImage}
          alt="Onboarding brochure"
          width="100%" />
      </div>
      <div className="textSection">
        <Row className="text-center">
          <h1>
            <Translate id="onboarding.agent.page4.heading" />
          </h1>
        </Row>
        <Row className="text-center">
          <p>
            <Translate id="onboarding.agent.page4.message" />
          </p>
        </Row>
      </div>
    </div>
  );

  const renderPage5 = () => (
    <div>
      <div className="imageSection">
        <img
          src={reservationImage}
          alt="Onboarding search"
          width="100%" />
      </div>
      <div className="textSection">
        <Row className="text-center">
          <h1>
            <Translate id="onboarding.agent.page5.heading" />
          </h1>
        </Row>
        <Row className="text-center">
          <p>
            <Translate id="onboarding.agent.page5.message" />
          </p>
        </Row>
      </div>
    </div>
  );

  const onboardingSlides = [
    renderPage1(),
    renderPage2(),
    renderPage3(),
    renderPage4(),
    renderPage5(),
  ];

  return (
    <OnboardingModal
      onboardingSlides={onboardingSlides}
      localStorageKey="lastOnboardingPageAgent" />
  );
};

export default AgentOnboarding;
