import {
  loadAgentProfileDetailsApi,
  agentProjectViewsByLocationApi,
} from 'services/profile.api';

export const FETCH_AGENT_PROFILE_DETAILS = 'FETCH_AGENT_PROFILE_DETAILS';
export const FETCH_AGENT_PROFILE_DETAILS_SUCCESS = 'FETCH_AGENT_PROFILE_DETAILS_SUCCESS';
export const FETCH_AGENT_PROFILE_DETAILS_FAILURE = 'FETCH_AGENT_PROFILE_DETAILS_FAILURE';
export const FETCH_AGENT_PROJECT_VIEWS_LOCATION_BY_TIME_STEP = 'FETCH_AGENT_PROJECT_VIEWS_LOCATION_BY_TIME_STEP';
export const FETCH_AGENT_PROJECT_VIEWS_LOCATION_BY_TIME_STEP_SUCCESS = 'FETCH_AGENT_PROJECT_VIEWS_LOCATION_BY_TIME_STEP_SUCCESS';
export const FETCH_AGENT_PROJECT_VIEWS_LOCATION_BY_TIME_STEP_FAILURE = 'FETCH_AGENT_PROJECT_VIEWS_LOCATION_BY_TIME_STEP_FAILURE';

export function fetchAgentProfileDetails() {
  return {
    type: FETCH_AGENT_PROFILE_DETAILS,
  };
}

export function fetchAgentProfileDetailsSuccess(agentProfile) {
  return {
    type: FETCH_AGENT_PROFILE_DETAILS_SUCCESS,
    agentProfile,
  };
}

export function fetchAgentProfileDetailsFailure(error) {
  return {
    type: FETCH_AGENT_PROFILE_DETAILS_FAILURE,
    error,
  };
}

export function fetchAgentProjectViewsLocationByTimeStep() {
  return {
    type: FETCH_AGENT_PROJECT_VIEWS_LOCATION_BY_TIME_STEP,
  };
}

export function fetchAgentProjectViewsLocationByTimeStepSuccess(agentProjectViews) {
  return {
    type: FETCH_AGENT_PROJECT_VIEWS_LOCATION_BY_TIME_STEP_SUCCESS,
    agentProjectViews,
  };
}

export function fetchAgentProjectViewsLocationByTimeStepFailure(error) {
  return {
    type: FETCH_AGENT_PROJECT_VIEWS_LOCATION_BY_TIME_STEP_FAILURE,
    error,
  };
}

export function fetchAgentProfileDetailsThunk(agentId) {
  return (dispatch) => {
    dispatch(fetchAgentProfileDetails());
    loadAgentProfileDetailsApi(agentId)
      .then((result) => {
        if (result.error) {
          dispatch(fetchAgentProfileDetailsFailure(new Error(result.error)));
        } else {
          dispatch(fetchAgentProfileDetailsSuccess(result.data.agentprofile));
        }
      }, (error) => {
        dispatch(fetchAgentProfileDetailsFailure(error));
      });
  };
}

export function fetchAgentProjectViewsLocationByTimeStepThunk(
  agentId, eventTypeFilter, timeRangeFilter) {
  return (dispatch) => {
    dispatch(fetchAgentProjectViewsLocationByTimeStep());
    agentProjectViewsByLocationApi(agentId, eventTypeFilter, timeRangeFilter)
      .then((result) => {
        if (result.error) {
          dispatch(fetchAgentProjectViewsLocationByTimeStepFailure(new Error(result.error)));
        } else {
          dispatch(fetchAgentProjectViewsLocationByTimeStepSuccess(result.data.agentProjectView));
        }
      }, (error) => {
        dispatch(fetchAgentProjectViewsLocationByTimeStepFailure(error));
      });
  };
}
