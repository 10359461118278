import moment from 'moment';

import {
  FETCH_CURRENT_ADVERTISEMENTS,
  FETCH_CURRENT_ADVERTISEMENTS_SUCCESS,
  FETCH_CURRENT_ADVERTISEMENTS_FAILURE,
  DISMISS_ADVERTISEMENT,
} from './AdvertisementsActions';

function fetchCurrentAdvertisements(state) {
  return {
    ...state,
    advertisementsLoading: true,
  };
}

function fetchCurrentAdvertisementsSuccess(state, action) {
  const newAdvertisements = [...action.currentAdvertisements.advertisements];
  newAdvertisements.forEach((advertisement) => {
    let oldAdvertisementData = null;
    if (state.advertisements) {
      oldAdvertisementData = state.advertisements
        .find((element) => element.id === advertisement.id);
      if (oldAdvertisementData && oldAdvertisementData.dismissUntil) {
        advertisement.dismissUntil = oldAdvertisementData.dismissUntil;
      }
    }
  });
  return {
    ...state,
    advertisementsLoading: false,
    advertisements: newAdvertisements,
  };
}

function fetchCurrentAdvertisementsFailure(state, action) {
  return {
    ...state,
    advertisementsLoading: false,
    advertisementsError: action.error,
  };
}

function dismissAdvertisement(state, action) {
  const advertisements = [...state.advertisements];
  advertisements.forEach((advertisement) => {
    if (advertisement.type === action.advertisementType) {
      // on dismissal the advertisement won't show up again for 16 hours
      advertisement.dismissUntil = moment.utc().unix() + (16 * 60 * 60);
    }
  });
  return {
    ...state,
    advertisements: [...advertisements],
  };
}

const initialState = {
  advertisementsLoading: false,
  advertisementsError: null,
  advertisements: null,
};

export default function AdvertisementsReducers(state = initialState, action) {
  switch (action.type) {
    case FETCH_CURRENT_ADVERTISEMENTS:
      return fetchCurrentAdvertisements(state);
    case FETCH_CURRENT_ADVERTISEMENTS_SUCCESS:
      return fetchCurrentAdvertisementsSuccess(state, action);
    case FETCH_CURRENT_ADVERTISEMENTS_FAILURE:
      return fetchCurrentAdvertisementsFailure(state, action);

    case DISMISS_ADVERTISEMENT:
      return dismissAdvertisement(state, action);

    default:
      return state;
  }
}
