import { createSelector } from 'reselect';

const integrationServicesLoadingSelector = (state) => state.integration.integrationServicesLoading;
const integrationServicesErrorSelector = (state) => state.integration.integrationServicesError;
const integrationServicesDataSelector = (state) => state.integration.integrationServicesData;

export const getIntegrationServicesLoading = createSelector(
  [integrationServicesLoadingSelector],
  (state) => state,
);

export const getIntegrationServicesError = createSelector(
  [integrationServicesErrorSelector],
  (state) => state,
);

export const getTalkJsIntegration = createSelector(
  [integrationServicesDataSelector],
  (integrationServicesData) => {
    if (integrationServicesData && integrationServicesData.talkJsService) {
      return {
        appId: integrationServicesData.talkJsService.applicationId,
        userId: integrationServicesData.talkJsService.user.id,
        signature: integrationServicesData.talkJsService.user.signature,
      };
    }
    return null;
  },
);

export const canUseGoogleMaps = createSelector(
  [integrationServicesDataSelector],
  (integrationServicesData) => {
    if (integrationServicesData && integrationServicesData.googleService) {
      return true;
    }
    return false;
  },
);

export const getGoogleMapsUrl = createSelector(
  [integrationServicesDataSelector],
  (integrationServicesData) => {
    if (integrationServicesData && integrationServicesData.googleService) {
      return `https://maps.googleapis.com/maps/api/js?key=${integrationServicesData.googleService.apiKey}&v=3.exp&libraries=geometry,drawing,places`;
    }
    return null;
  },
);

export const getActiveCampaignAccountNumber = createSelector(
  [integrationServicesDataSelector],
  (integrationServicesData) => (
    (
      integrationServicesData
      && integrationServicesData.activeCampaignService
      && integrationServicesData.activeCampaignService.accountNumber
    ) || null
  ),
);

export const canUseMapbox = createSelector(
  [integrationServicesDataSelector],
  (integrationServicesData) => {
    if (integrationServicesData && integrationServicesData.mapboxService) {
      return true;
    }
    return false;
  },
);

export const getMapboxAccessToken = createSelector(
  [integrationServicesDataSelector],
  (integrationServicesData) => {
    if (integrationServicesData && integrationServicesData.mapboxService) {
      return integrationServicesData.mapboxService.accessToken;
    }
    return null;
  },
);

export const getIntercomIntegration = createSelector(
  [integrationServicesDataSelector],
  (integrationServicesData) => {
    if (integrationServicesData && integrationServicesData.intercomService) {
      return {
        app_id: integrationServicesData.intercomService.applicationId, // eslint-disable-line
        user: {
          user_id: integrationServicesData.intercomService.intercomUser.id, // eslint-disable-line
          name: integrationServicesData.intercomService.intercomUser.name,
          email: integrationServicesData.intercomService.intercomUser.email,
        },
      };
    }
    return null;
  },
);

export const getUsersCountry = createSelector(
  [integrationServicesDataSelector],
  (integrationServicesData) => {
    if (integrationServicesData) {
      return integrationServicesData.countryCode;
    }
    return null;
  },
);
