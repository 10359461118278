import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import { history } from 'utility/history';
import { isUserAuthenticated } from 'utility/utility';

const PrivateRoute = ({ component: Component, ...passThrough }) => {
  if (isUserAuthenticated()) {
    return <Route component={Component} {...passThrough} />;
  }

  history.push(
    `/neo/Status/Login?redirectUrl=${encodeURIComponent(
      window.location.pathname + window.location.search
    )}`
  );
  return null;
};

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired
};

export default PrivateRoute;
