import { graphQLQuery } from './api';

export function createAssetApi(isPublic, filename, language) {
  const mutationString = `
  mutation createAsset($public: Boolean, $filename: String, $language: String,) {
    createAsset(public: $public, filename: $filename, language: $language) {
        assetId
        uri
        method
        expires
        headers { name value }
    }
  }`;
  const variables = { public: isPublic, filename, language };
  return graphQLQuery(mutationString, variables);
}

export function confirmAssetUploadApi(assetId, eTag) {
  const mutationString = `
  mutation confirmAssetUpload($assetId: ID!, $eTag: String!) {
    confirmAssetUpload(assetId: $assetId, eTag: $eTag)
  }`;
  const variables = { assetId, eTag };
  return graphQLQuery(mutationString, variables);
}
