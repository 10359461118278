import {
  FETCH_AGENT_PROFILE_DETAILS,
  FETCH_AGENT_PROFILE_DETAILS_SUCCESS,
  FETCH_AGENT_PROFILE_DETAILS_FAILURE,
  FETCH_AGENT_PROJECT_VIEWS_LOCATION_BY_TIME_STEP,
  FETCH_AGENT_PROJECT_VIEWS_LOCATION_BY_TIME_STEP_SUCCESS,
  FETCH_AGENT_PROJECT_VIEWS_LOCATION_BY_TIME_STEP_FAILURE,
} from './AgentProfileActions';

function fetchAgentProfileDetails(state) {
  return {
    ...state,
    agentProfileDetailsLoading: true,
    agentProfileDetailsError: null,
  };
}

function fetchAgentProfileDetailsSuccess(state, action) {
  return {
    ...state,
    agentProfileDetailsLoading: false,
    agentProfile: action.agentProfile,
  };
}

function fetchAgentProfileDetailsFailure(state, action) {
  return {
    ...state,
    agentProfileDetailsLoading: false,
    agentProfileDetailsError: action.error,
  };
}

function fetchAgentProjectViewsLocationByTimeStep(state) {
  return {
    ...state,
    agentProjectViewsLoading: true,
    agentProjectViewsError: null,
  };
}

function fetchAgentProjectViewsLocationByTimeStepSuccess(state, action) {
  return {
    ...state,
    agentProjectViewsLoading: false,
    agentProjectViews: action.agentProjectViews,
  };
}

function fetchAgentProjectViewsLocationByTimeStepFailure(state, action) {
  return {
    ...state,
    agentProjectViewsLoading: false,
    agentProjectViewsError: action.error,
  };
}

const initialState = {
  agentProfile: null,
  agentProfileDetailsLoading: false,
  agentProfileDetailsError: null,
  agentProjectViews: null,
  agentProjectViewsLoading: false,
  agentProjectViewsError: null,
};

export default function AgentProfileReducers(state = initialState, action) {
  switch (action.type) {
    case FETCH_AGENT_PROFILE_DETAILS:
      return fetchAgentProfileDetails(state, action);
    case FETCH_AGENT_PROFILE_DETAILS_SUCCESS:
      return fetchAgentProfileDetailsSuccess(state, action);
    case FETCH_AGENT_PROFILE_DETAILS_FAILURE:
      return fetchAgentProfileDetailsFailure(state, action);
    case FETCH_AGENT_PROJECT_VIEWS_LOCATION_BY_TIME_STEP:
      return fetchAgentProjectViewsLocationByTimeStep(state, action);
    case FETCH_AGENT_PROJECT_VIEWS_LOCATION_BY_TIME_STEP_SUCCESS:
      return fetchAgentProjectViewsLocationByTimeStepSuccess(state, action);
    case FETCH_AGENT_PROJECT_VIEWS_LOCATION_BY_TIME_STEP_FAILURE:
      return fetchAgentProjectViewsLocationByTimeStepFailure(state, action);
    default:
      return state;
  }
}
