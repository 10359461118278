import { graphQLQuery } from './api';

export function getIntegrationServicesApi(useAuthenticationToken = true) {
  const queryString = `
  query {
    integrationServices {
      googleService { apiKey }
      intercomService { applicationId intercomUser { id name email } }
      mapboxService { accessToken }
      talkJsService { applicationId user { id signature } }
      countryCode
      activeCampaignService { accountNumber }
    }
  }`;
  const variables = {
  };
  return graphQLQuery(queryString, variables, { useAuthenticationToken, errorHandler: null });
}
