export const SHOW_PUBLIC_PROJECT_ENQUIRY_DIALOG = 'SHOW_PUBLIC_PROJECT_ENQUIRY_DIALOG';
export const HIDE_PUBLIC_PROJECT_ENQUIRY_DIALOG = 'HIDE_PUBLIC_PROJECT_ENQUIRY_DIALOG';

export function showPublicProjectEnquiryDialog(projectId, projectName) {
  return {
    type: SHOW_PUBLIC_PROJECT_ENQUIRY_DIALOG,
    projectId,
    projectName
  };
}

export function hidePublicProjectEnquiryDialog() {
  return {
    type: HIDE_PUBLIC_PROJECT_ENQUIRY_DIALOG
  };
}
