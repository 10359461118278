import React from 'react';
import PropTypes from 'prop-types';

import InputField from 'components/InputFields/InputField/InputField';

function TextInputField({
  label,
  labelDescription,
  meta,
  input,
  type,
  placeholder,
  inputRef,
}) {
  const { touched, error } = meta;

  return (
    <InputField
      test-attr="component-text-input-field"
      label={label}
      labelDescription={labelDescription}
      meta={meta}>
      <input
        test-attr="text-input"
        ref={inputRef}
        {...input}
        type={type}
        className={(touched && error ? 'redInputBorder' : '') + ' form-control'}
        placeholder={placeholder} />
    </InputField>
  );
}

TextInputField.defaultProps = {
  labelDescription: null,
  inputRef: null,
};

TextInputField.propTypes = {
  label: PropTypes.string.isRequired,
  labelDescription: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    error: PropTypes.string,
  }).isRequired,
  input: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  inputRef: PropTypes.oneOfType([
    PropTypes.shape({ current: PropTypes.instanceOf(HTMLInputElement) }),
    PropTypes.func
  ]),
};

export default TextInputField;
