import { useSelector, useDispatch } from 'react-redux';

import {
  getMeasurementUnits,
  isPresentationMode as isPresentationModeSelector,
} from 'components/LoggedInUser/LoggedInUserSelectors';

import {
  setMeasurementUnits as setMeasurementUnitsAction
} from 'components/LoggedInUser/LoggedInUserActions';

export const MEASUREMENT_UNITS = {
  METRIC: 'METRIC',
  IMPERIAL: 'IMPERIAL'
};

function useDisplaySettings() {
  const isPresentationMode = useSelector(isPresentationModeSelector);
  const measurementUnits = useSelector(getMeasurementUnits);

  const dispatch = useDispatch();
  const setMeasurementUnits = (unitsType) => dispatch(setMeasurementUnitsAction(unitsType));

  return {
    isPresentationMode,
    measurementUnits,
    setMeasurementUnits,
  };
}

export default useDisplaySettings;
