import { graphQLQuery } from './api';

export function loadEnvironmentApi() {
  const queryString = `
  query environment {
    environment {
      portalName
      companyWebsiteUri
      homepageType
      homepageHeading
      disclaimer
      mainBrandColour
      secondaryBrandColour
      mainBrandColourDark
      secondaryBrandColourDark
      logoMainImage { id mimeType url }
      logoWhiteImage { id mimeType url }
      loginBackgroundImage { id mimeType url }
      homepageBackgroundImage { id mimeType url }
      faviconImage { id mimeType url }
      webclipImage { id mimeType url }
      hasExpandedHeader
      socialMedia { facebookUsername, twitterUsername, linkedInCompanyName }
      internetContentProviderLicenseNumber
      links { text link }
      contactGroups { name countryCode order contacts { type value order } }
      advertisement { heading content actions {
        type text order
        ... on EnvironmentAdvertisementLinkAction { link } 
        ... on EnvironmentAdvertisementSearchAction {
          filter {
            bedroomsRange { minValue maxValue }
            bathroomsRange { minValue maxValue }
            carparksRange { minValue maxValue }
            priceRange { minValue maxValue }
            pricePerMetreSquaredRange { minValue maxValue }
            currencyCode
          }
          query { searchItemId searchTerm searchType }
        }
      } }
    }
  }`;
  const variables = {
  };

  return graphQLQuery(queryString, variables);
}
