import { loadEnvironmentApi } from 'services/environment.api';

export const FETCH_ENVIRONMENT_SETTINGS = 'FETCH_ENVIRONMENT_SETTINGS';
export const FETCH_ENVIRONMENT_SETTINGS_SUCCESS = 'FETCH_ENVIRONMENT_SETTINGS_SUCCESS';
export const FETCH_ENVIRONMENT_SETTINGS_FAILURE = 'FETCH_ENVIRONMENT_SETTINGS_FAILURE';

export function fetchEnvironment() {
  return {
    type: FETCH_ENVIRONMENT_SETTINGS,
  };
}

export function fetchEnvironmentSuccess(environment) {
  return {
    type: FETCH_ENVIRONMENT_SETTINGS_SUCCESS,
    environment,
  };
}

export function fetchEnvironmentFailure(error) {
  return {
    type: FETCH_ENVIRONMENT_SETTINGS_FAILURE,
    error,
  };
}

export function fetchEnvironmentThunk() {
  return (dispatch) => {
    dispatch(fetchEnvironment());
    loadEnvironmentApi()
      .then((result) => {
        if (result.error) {
          dispatch(fetchEnvironmentFailure(new Error(result.error)));
        } else {
          dispatch(fetchEnvironmentSuccess(result.data.environment));
        }
      }, (error) => {
        dispatch(fetchEnvironmentFailure(error));
      });
  };
}
