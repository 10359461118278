/* eslint-disable camelcase */
import { useCookies } from 'react-cookie';

const ACCEPT_COOKIES = 'accept_cookies';

function useIsAcceptingCookies() {
  const [cookies, setCookie] = useCookies([ACCEPT_COOKIES]);
  const { accept_cookies } = cookies;

  const isAcceptingCookiesSet = accept_cookies !== undefined;
  const isAcceptingCookies = isAcceptingCookiesSet && accept_cookies === '1';

  const thirtyDaysInMilliseconds = 1000 * 60 * 60 * 24 * 30;
  const expiration = new Date(new Date().getTime() + thirtyDaysInMilliseconds);
  const setIsAcceptingCookies = (isAccepting) => {
    setCookie(ACCEPT_COOKIES, isAccepting ? '1' : '0', { path: '/', expires: expiration });
  };

  return { isAcceptingCookies, isAcceptingCookiesSet, setIsAcceptingCookies };
}

export default useIsAcceptingCookies;
