export const UPDATE_AUTOCOMPLETE_QUERY = 'UPDATE_AUTOCOMPLETE_QUERY';

export const SELECT_AUTOCOMPLETE_RESULT = 'SELECT_AUTOCOMPLETE_RESULT';
export const CLEAR_AUTOCOMPLETE_RESULT = 'CLEAR_AUTOCOMPLETE_RESULT';

function updateAutocompleteQuery(state, action) {
  return {
    ...state,
    partialQuery: action.partialQuery,
  };
}

function selectAutocompleteResult(state, action) {
  return {
    ...state,
    autocompleteSelection: action.selection,
  };
}

function clearAutocompleteResult(state) {
  return {
    ...state,
    autocompleteSelection: null,
  };
}

function autocompleteReducer(state, action) {
  switch (action.type) {
    case UPDATE_AUTOCOMPLETE_QUERY:
      return updateAutocompleteQuery(state, action);

    case SELECT_AUTOCOMPLETE_RESULT:
      return selectAutocompleteResult(state, action);
    case CLEAR_AUTOCOMPLETE_RESULT:
      return clearAutocompleteResult(state);

    default:
      return state;
  }
}

const initialState = {
  autocomplete: {
    partialQuery: '',
    autocompleteSelection: null,
  },
};

export default function SearchPageReducers(state = initialState, action) {
  return {
    autocomplete: autocompleteReducer(state.autocomplete, action),
  };
}
