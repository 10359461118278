import { isValidColourFormat } from './validation';

export const investoristPortalName = 'Investorist';

export function getPortalName(environment) {
  const defaultExternalPortalName = 'Your portal name here';
  return environment ? environment.portalName || defaultExternalPortalName : investoristPortalName;
}

export function isNonWhitespaceText(portalName) {
  return portalName && portalName.trim() !== '';
}

export function isImageCleared(environmentMutations, environment, imagePropertyName) {
  return !environmentMutations[imagePropertyName] && environment && environment[imagePropertyName];
}

export function isValidColour(colour) {
  return colour !== null
    && colour !== ''
    && isValidColourFormat(colour, 'Hexadecimal3', 'Hexadecimal6');
}

export function areEnvironmentMutationsValid(environmentMutations, environment) {
  return isNonWhitespaceText(environmentMutations.portalName)
    && !isImageCleared(environmentMutations, environment, 'logoMainImage')
    && !isImageCleared(environmentMutations, environment, 'loginBackgroundImage')
    && isValidColour(environmentMutations.mainBrandColour)
    && isValidColour(environmentMutations.secondaryBrandColour);
}

export function getNonWhitespaceText(text) {
  return text && text.trim() !== '' ? text : null;
}
