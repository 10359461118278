let persistor = null;

export function initialiseCache(persistorobject) {
  persistor = persistorobject;
}

export function clearCache() {
  if (persistor) {
    persistor.purge();
  }
}
