import colours from 'components/App/InvestoristColours.scss';
import { TAB } from 'components/PortalSettings/portalSettingsTabs';

import {
  SET_ENVIRONMENT_MUTATIONS,
  FETCH_DISTRIBUTED_PROJECT_STATISTICS,
  FETCH_DISTRIBUTED_PROJECT_STATISTICS_SUCCESS,
  FETCH_DISTRIBUTED_PROJECT_STATISTICS_FAILURE,
  SET_VALIDATE_ENVIRONMENT_MUTATIONS,
  SET_SELECTED_TAB,
} from './PortalSettingsActions';

function setValidateEnvironmentMutations(state, action) {
  return {
    ...state,
    validateEnvironmentMutations: action.validate
  };
}

function setSelectedTab(state, action) {
  return {
    ...state,
    selectedTab: action.tab
  };
}

function setEnvironmentMutations(state, action) {
  return {
    ...state,
    environmentMutations: {
      ...state.environmentMutations,
      ...action.environmentMutations,
    },
  };
}

function fetchDistributedProjectStatistics(state) {
  return {
    ...state,
    projectDistributionStatisticsLoading: true,
    projectDistributionStatisticsError: false,
  };
}

function fetchDistributedProjectStatisticsSuccess(state, action) {
  return {
    ...state,
    projectDistributionStatisticsLoading: false,
    projectDistributionStatistics: action.projectDistributionStatistics,
  };
}

function fetchDistributedProjectStatisticsError(state, action) {
  return {
    ...state,
    projectDistributionStatisticsLoading: false,
    projectDistributionStatisticsError: action.error,
  };
}

const initialState = {
  portalSettings: null,
  portalSettingsLoading: false,
  portalSettingsError: null,
  projectDistributionStatistics: null,
  environmentMutations: {
    homepageType: 'SEARCH_PAGE',
    homepageHeading: null,
    disclaimer: null,
    portalName: null,
    mainBrandColour: colours.primary,
    secondaryBrandColour: colours.primaryAccent,
    logoMainImage: null,
    logoWhiteImage: null,
    faviconImage: null,
    webclipImage: null,
    loginBackgroundImage: null,
    homepageBackgroundImage: null,
    hasExpandedHeader: false,
  },
  validateEnvironmentMutations: false,
  selectedTab: TAB.GENERAL,
};

export default function PortalSettingsReducers(state = initialState, action) {
  switch (action.type) {
    case SET_ENVIRONMENT_MUTATIONS:
      return setEnvironmentMutations(state, action);
    case FETCH_DISTRIBUTED_PROJECT_STATISTICS:
      return fetchDistributedProjectStatistics(state);
    case FETCH_DISTRIBUTED_PROJECT_STATISTICS_SUCCESS:
      return fetchDistributedProjectStatisticsSuccess(state, action);
    case FETCH_DISTRIBUTED_PROJECT_STATISTICS_FAILURE:
      return fetchDistributedProjectStatisticsError(state, action);
    case SET_VALIDATE_ENVIRONMENT_MUTATIONS:
      return setValidateEnvironmentMutations(state, action);
    case SET_SELECTED_TAB:
      return setSelectedTab(state, action);
    default:
      return state;
  }
}
