import { graphQLQuery } from './api';

export function publicProjectEnquiryApi(
  firstName,
  lastName,
  email,
  countryCode,
  phone,
  companyName,
  projectId,
  message
) {
  const mutationString = `
    mutation (
      $firstName: String!,
      $lastName: String!,
      $email: String!,
      $countryCode: String!
      $phone: String!,
      $companyName: String!
      $projectId: ID!,
      $message: String!
    ) {
      publicProjectEnquiry (
        firstName: $firstName,
        lastName: $lastName,
        email: $email,
        countryCode: $countryCode,
        phone: $phone,
        companyName: $companyName,
        projectId: $projectId,
        message: $message
      )
    }`;

  const variables = {
    firstName,
    lastName,
    email,
    countryCode,
    phone,
    companyName,
    projectId,
    message
  };

  return graphQLQuery(mutationString, variables);
}
