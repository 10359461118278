import fetch from 'isomorphic-fetch';

export function postRequest(url) {
  return fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((response) => {
    if (response.status >= 200 && response.status < 300) {
      return Promise.resolve(response.json());
    }
    const error = new Error(response.statusText || response.status);
    return Promise.reject(error);
  });
}
