import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Nav, NavDropdown, Button } from 'react-bootstrap';

import useTranslate from 'hooks/useTranslate';
import useLoggedInUser from 'hooks/useLoggedInUser';
import useBrochure from 'hooks/useBrochure';
import useSignup from 'hooks/useSignup';
import Icon, { ICONS } from 'components/Icon/Icon';
import { PERMISSION } from 'utility/permissions';
import colours from 'components/App/InvestoristColours.scss';

import NavLink from './NavLink';

const renderPublicNavigationBar = (className, translate, showSignupDialog) => (
  <div className={className}>
    <Nav>
      <NavLink text={translate('header.navigationBar.search')} icon={ICONS.search} to="/neo/Search" />
    </Nav>
    <Button
      className="button-as-nav-link"
      onClick={() => { showSignupDialog('signupForFree', 'AGENT'); }}>
      <div className="icon-text-content-wrapper">
        <Icon icon={ICONS.contactUser} size={15} />
        <span>{translate('header.navigationBar.signUpForFree')}</span>
      </div>
    </Button>
  </div>
);

const getContactsUrl = (isUserASeller) => `/legacy/${isUserASeller ? 'Developer' : 'NetworkPartner'}/Contacts`;

const renderAuthenticatedNavigationBar = (
  className, translate, isUserASeller, canSeeContactsPage, portalManagementMenu
) => (
  <div className={className}>
    <Nav>
      {isUserASeller ? (
        <>
          <NavLink text={translate('header.navigationBar.dashboard')} icon={ICONS.dashboard} to="/neo/Dashboard" />
          <NavLink text={translate('header.navigationBar.myProjects')} icon={ICONS.myProjects} to="/legacy/Project/ProjectSummary" />
          <NavLink text={translate('header.navigationBar.activity')} icon={ICONS.activity} to="/legacy/Developer/Activity" />
        </>
      ) : (
        <>
          <NavLink text={translate('header.navigationBar.search')} icon={ICONS.search} to="/neo/Search" />
          <NavLink text={translate('header.navigationBar.favourites')} icon={ICONS.followFavourite} to="/legacy/Favourites/Index" />
          <NavLink text={translate('header.navigationBar.activity')} icon={ICONS.activity} to="/legacy/NetworkPartner/Activity" />
        </>
      )}
      {canSeeContactsPage && (
        <NavLink text={translate('header.navigationBar.contacts')} icon={ICONS.contactUser} to={getContactsUrl(isUserASeller)} />
      )}
      {portalManagementMenu}
    </Nav>
  </div>
);

const renderPortalManagementMenu = (
  className, translate, canCreatePortals, isOpenPortalManagementMenu, setIsOpenPortalManagementMenu
) => (
  <Nav className={classNames(className, 'portal-management')}>
    <NavDropdown
      id="portal-management-dropdown"
      open={isOpenPortalManagementMenu}
      onToggle={(isOpen) => { setIsOpenPortalManagementMenu(isOpen); }}
      title={(
        <span>
          <svg width="15" height="15" viewBox="0 0 22 22" className="icon">
            <path fill={colours.primaryDark} d={ICONS.portalManagement} />
          </svg>
          <span>{translate('header.navigationBar.portalManagement')}</span>
        </span>
      )}>
      <NavLink
        text={translate('header.portalManagementMenu.portalUsers')}
        to="/legacy/PortalManagement/UserManagement"
        onClick={() => setIsOpenPortalManagementMenu(false)} />
      <NavLink
        text={translate('header.portalManagementMenu.featureProjects')}
        to="/legacy/Project/FeatureProjects"
        onClick={() => setIsOpenPortalManagementMenu(false)} />
      <NavLink
        text={translate('header.portalManagementMenu.portalSettings')}
        to="/neo/PortalSettings"
        onClick={() => setIsOpenPortalManagementMenu(false)} />
      {canCreatePortals && (
        <NavLink
          text={translate('header.portalManagementMenu.createPortal')}
          to="/neo/CreatePortal"
          onClick={() => setIsOpenPortalManagementMenu(false)} />
      )}
    </NavDropdown>
  </Nav>
);

function MainNavigationBar({ className }) {
  const translate = useTranslate();
  const [isOpenPortalManagementMenu, setIsOpenPortalManagementMenu] = useState(false);
  const { isBrochurePage } = useBrochure();
  const {
    isLoggedInUser,
    isUserASeller,
    loggedInUserLoading,
    hasPermission
  } = useLoggedInUser();
  const showSignupDialog = useSignup();

  const computedClassName = classNames('main-navigation-bar', className);
  if (loggedInUserLoading || isBrochurePage) {
    return <div className={computedClassName} />;
  }

  const isPublic = !isLoggedInUser;
  if (isPublic) {
    return renderPublicNavigationBar(computedClassName, translate, showSignupDialog);
  }

  const canManagePortals = hasPermission(PERMISSION.CAN_MANAGE_PORTALS);
  const canCreatePortals = hasPermission(PERMISSION.CAN_CREATE_PORTALS);
  const portalManagementMenu = canManagePortals
    ? renderPortalManagementMenu('hidden-xs', translate, canCreatePortals, isOpenPortalManagementMenu, setIsOpenPortalManagementMenu)
    : null;

  const canSeeContactsPage = hasPermission(PERMISSION.CAN_SEE_CONTACTS_PAGE);
  return renderAuthenticatedNavigationBar(
    computedClassName, translate, isUserASeller, canSeeContactsPage, portalManagementMenu
  );
}

MainNavigationBar.defaultProps = {
  className: null,
};

MainNavigationBar.propTypes = {
  className: PropTypes.string,
};

export default MainNavigationBar;
