import {
  FETCH_ENVIRONMENT_SETTINGS,
  FETCH_ENVIRONMENT_SETTINGS_SUCCESS,
  FETCH_ENVIRONMENT_SETTINGS_FAILURE,
} from './EnvironmentActions';

function fetchEnvironment(state) {
  return {
    ...state,
    environmentLoading: true,
    environmentError: null,
  };
}

function fetchEnvironmentSuccess(state, action) {
  return {
    ...state,
    environmentLoading: false,
    environment: action.environment,
  };
}

function fetchEnvironmentFailure(state, action) {
  return {
    ...state,
    environmentLoading: false,
    environmentError: action.error,
  };
}

const initialState = {
  environment: null,
  environmentLoading: false,
  environmentError: null,
};

export default function EnvironmentReducers(state = initialState, action) {
  switch (action.type) {
    case FETCH_ENVIRONMENT_SETTINGS:
      return fetchEnvironment(state);
    case FETCH_ENVIRONMENT_SETTINGS_SUCCESS:
      return fetchEnvironmentSuccess(state, action);
    case FETCH_ENVIRONMENT_SETTINGS_FAILURE:
      return fetchEnvironmentFailure(state, action);

    default:
      return state;
  }
}
