import { useLocation, useParams } from 'react-router-dom';

function useRedirectUrl() {
  const { pathname, search } = useLocation();
  const { redirectUrl } = useParams();

  return redirectUrl || (pathname + search);
}

export default useRedirectUrl;
