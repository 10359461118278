import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';

import { clearCache } from 'utility/cache';
import { isOnBrochurePage } from 'utility/utility';
import Icon, { ICONS } from 'components/Icon/Icon';
import loadFailIcon from 'assets/icons/load-fail.svg';
import colours from 'components/App/InvestoristColours.scss';

import './Error.scss';

function Error({
  history,
  connectionError,
  fullPage,
}) {
  const isBrochurePage = isOnBrochurePage();

  if (connectionError) {
    return (
      <div className={fullPage ? 'error tile pageError' : 'error tile'}>
        <Icon icon={ICONS.failure} colour={colours.colourGreyMid} size={70} />
        <h2>
          <Translate id="statusPage.connection.heading" />
        </h2>
        <p>
          <Translate id="error.subMessage" />
        </p>
        <Button
          className="whiteButton"
          onClick={() => {
            clearCache();
            window.location.reload();
          }}>
          <Translate id="common.reloadPage" />
        </Button>
        {
          !isBrochurePage
          && (
            <Button className="whiteButton" onClick={() => history.push('/neo/')}>
              <Translate id="common.goToHomepage" />
            </Button>
          )
        }
      </div>
    );
  }

  return (
    <div className={fullPage ? 'error tile pageError' : 'error tile'}>
      <img src={loadFailIcon} alt="Fail Icon" />
      <h2>
        <Translate id="error.mainMessage" />
      </h2>
      <p>
        <Translate id="error.subMessage" />
      </p>
      <Button
        className="whiteButton"
        onClick={() => {
          clearCache();
          window.location.reload();
        }}>
        <Translate id="common.reloadPage" />
      </Button>
      {
        !isBrochurePage
        && (
          <Button className="whiteButton" onClick={() => history.push('/neo/')}>
            <Translate id="common.goToHomepage" />
          </Button>
        )
      }
    </div>
  );
}

Error.defaultProps = {
  connectionError: false,
  fullPage: false,
};

Error.propTypes = {
  history: PropTypes.object.isRequired,
  connectionError: PropTypes.bool,
  fullPage: PropTypes.bool,
};

export default withRouter(Error);
