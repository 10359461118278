import {
  SHOW_MESSAGE_POPUP,
  HIDE_MESSAGE_POPUP,
} from './MessagePopupActions';

function showMessagePopup(state) {
  return {
    ...state,
    messagePopupVisible: true,
  };
}

function hideMessagePopup(state) {
  return {
    ...state,
    messagePopupVisible: false,
  };
}

const initialState = {
  messagePopupVisible: false,
};

export default function MessagePopupReducers(state = initialState, action) {
  switch (action.type) {
    case SHOW_MESSAGE_POPUP:
      return showMessagePopup(state);
    case HIDE_MESSAGE_POPUP:
      return hideMessagePopup(state);

    default:
      return state;
  }
}
