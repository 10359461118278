import {
  REQUEST_INTEGRATION_SERVICES,
  REQUEST_INTEGRATION_SERVICES_SUCCESS,
  REQUEST_INTEGRATION_SERVICES_FAILURE,
} from './IntegrationActions';

function requestIntegrationServices(state) {
  return {
    ...state,
    integrationServicesLoading: true,
    integrationServicesError: null,
    integrationServicesData: null,
  };
}

function requestIntegrationServicesSuccess(state, action) {
  return {
    ...state,
    integrationServicesLoading: false,
    integrationServicesData: action.integrationData,
  };
}

function requestIntegrationServicesFailure(state, action) {
  return {
    ...state,
    integrationServicesLoading: false,
    integrationServicesError: action.error,
  };
}

const initialState = {
  integrationServicesLoading: false,
  integrationServicesError: null,
  integrationServicesData: null,
};

export default function IntegrationReducers(state = initialState, action) {
  switch (action.type) {
    case REQUEST_INTEGRATION_SERVICES:
      return requestIntegrationServices(state);
    case REQUEST_INTEGRATION_SERVICES_SUCCESS:
      return requestIntegrationServicesSuccess(state, action);
    case REQUEST_INTEGRATION_SERVICES_FAILURE:
      return requestIntegrationServicesFailure(state, action);
    default:
      return state;
  }
}
