import {
  CREATE_ASSET,
  CREATE_ASSET_SUCCESS,
  CREATE_ASSET_FAILURE,
  SAVE_ASSET_UPLOADING_COUNT,
} from './AssetActions';

function createAsset(state) {
  return {
    ...state,
    createAssetLoading: true,
    createAssetError: null,
  };
}

function createAssetSuccess(state, action) {
  return {
    ...state,
    createAssetLoading: false,
    createAssetResult: action.createAssetResult,
  };
}

function createAssetFailure(state, action) {
  return {
    ...state,
    createAssetLoading: false,
    createAssetError: action.error,
  };
}

function saveAssetUploadingCount(state, action) {
  return {
    ...state,
    assetUploadingCount: action.assetUploadingCount,
  };
}

const initialState = {
  createAssetResult: null,
  assetUploadingCount: 0,
};

export default function AssetReducers(state = initialState, action) {
  switch (action.type) {
    case CREATE_ASSET:
      return createAsset(state);
    case CREATE_ASSET_SUCCESS:
      return createAssetSuccess(state, action);
    case CREATE_ASSET_FAILURE:
      return createAssetFailure(state, action);
    case SAVE_ASSET_UPLOADING_COUNT:
      return saveAssetUploadingCount(state, action);
    default:
      return state;
  }
}
