import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Row, Col, Form } from 'react-bootstrap';
import { translate } from 'utility/utility';
import { required, length, email } from 'redux-form-validators';
import 'react-phone-number-input/style.css';

import TextInputField from 'components/InputFields/TextInputField/TextInputField';
import PhoneInputField from 'components/InputFields/PhoneInputField/PhoneInputField';

const SignupForm = ({
  handleSubmit,
  customerType,
  usersCountry,
  change,
}) => (
  <Form className="bumpbottom" onSubmit={handleSubmit}>
    <Row>
      <Col xs={6}>
        <Field
          name="firstName"
          type="text"
          component={TextInputField}
          label={translate('common.firstName') + '*'}
          placeholder={translate('common.firstName')}
          validate={[required({ msg: translate('common.firstNameRequired') })]} />
      </Col>
      <Col xs={6}>
        <Field
          name="lastName"
          type="text"
          component={TextInputField}
          label={translate('common.lastName') + '*'}
          placeholder={translate('common.lastName')}
          validate={[required({ msg: translate('common.lastNameRequired') })]} />
      </Col>
    </Row>
    <Field
      name="phone"
      component={PhoneInputField}
      usersCountry={usersCountry}
      change={change}
      label={translate('common.phoneNumber') + '*'}
      placeholder={translate('common.phoneNumber')}
      validate={[required({ msg: translate('common.phoneNumberRequired') }), length({ max: 254 })]} />
    <Field
      name="countryCode"
      component="input"
      type="hidden" />
    <Field
      name="email"
      component={TextInputField}
      type="email"
      label={(customerType === 'BUYER' ? translate('common.email') : translate('common.companyEmail')) + '*'}
      labelDesc={customerType !== 'BUYER' ? translate('common.companyEmailDesc') : null}
      placeholder={customerType === 'BUYER' ? translate('common.email') : translate('common.companyEmail')}
      validate={[required({ msg: translate('common.emailRequired') }), email({ msg: translate('common.invalidEmailAddress') }), length({ max: 254 })]} />
    {
      customerType !== 'BUYER'
      && (
        <Field
          name="companyName"
          component={TextInputField}
          type="text"
          label={translate('common.companyName') + '*'}
          placeholder={translate('common.companyName')}
          validate={[required({ msg: translate('common.companyNameRequired') }), length({ max: 250 })]} />
      )
    }
  </Form>
);

SignupForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  customerType: PropTypes.string.isRequired,
  usersCountry: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
};

export default reduxForm({ form: 'signupForm' })(SignupForm);
