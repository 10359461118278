import { createSelector } from 'reselect';

const buildBrochureLoadingSelector = (state) => state.brochure.buildBrochureLoading;
const buildBrochureErrorSelector = (state) => state.brochure.buildBrochureError;
const brochureDetailsLoadingSelector = (state) => state.brochure.brochureDetailsLoading;
const brochureDetailsErrorSelector = (state) => state.brochure.brochureDetailsError;
const projectDetailsLoadingSelector = (state) => state.brochure.projectDetailsLoading;
const projectDetailsErrorSelector = (state) => state.brochure.projectDetailsError;
const projectOverviewLoadingSelector = (state) => state.brochure.projectOverviewLoading;
const projectOverviewErrorSelector = (state) => state.brochure.projectOverviewError;
const projectImagesLoadingSelector = (state) => state.brochure.projectImagesLoading;
const projectImagesErrorSelector = (state) => state.brochure.projectImagesError;
const stockListLoadingSelector = (state) => state.brochure.stockListLoading;
const stockListErrorSelector = (state) => state.brochure.stockListError;
const builtBrochureIdSelector = (state) => state.brochure.builtBrochureId;
const currentBrochureIdSelector = (state) => state.brochure.currentBrochureId;
const brochurePortalSettingsSelector = (state) => state.brochure.portalSettings;

const brochureCacheSelector = (state) => state.cache.brochures;
const projectsCacheSelector = (state) => state.cache.projects;
const stocksCacheSelector = (state) => state.cache.stocks;
const organisationsCacheSelector = (state) => state.cache.organisations;
const usersCacheSelector = (state) => state.cache.users;

export const getBuildBrochureLoading = createSelector(
  [buildBrochureLoadingSelector],
  (state) => state,
);

export const getBuildBrochureError = createSelector(
  [buildBrochureErrorSelector],
  (state) => state,
);

export const getBrochureDetailsLoading = createSelector(
  [brochureDetailsLoadingSelector],
  (state) => state,
);

export const getBrochureDetailsError = createSelector(
  [brochureDetailsErrorSelector],
  (state) => state,
);

export const getProjectDetailsLoading = createSelector(
  [projectDetailsLoadingSelector],
  (state) => state,
);

export const getProjectDetailsError = createSelector(
  [projectDetailsErrorSelector],
  (state) => state,
);

export const getProjectOverviewLoading = createSelector(
  [projectOverviewLoadingSelector],
  (state) => state,
);

export const getProjectOverviewError = createSelector(
  [projectOverviewErrorSelector],
  (state) => state,
);

export const getProjectImagesLoading = createSelector(
  [projectImagesLoadingSelector],
  (state) => state,
);

export const getProjectImagesError = createSelector(
  [projectImagesErrorSelector],
  (state) => state,
);

export const getStockListLoading = createSelector(
  [stockListLoadingSelector],
  (state) => state,
);

export const getStockListError = createSelector(
  [stockListErrorSelector],
  (state) => state,
);

export const getBuiltBrochureId = createSelector(
  [builtBrochureIdSelector],
  (state) => state,
);

export const getCurrentBrochureId = createSelector(
  [currentBrochureIdSelector],
  (state) => state,
);

export const getBrochurePortalSettings = createSelector(
  [brochurePortalSettingsSelector],
  (state) => state,
);

export const getBrochure = createSelector(
  [
    currentBrochureIdSelector,
    brochureCacheSelector,
    projectsCacheSelector,
    stocksCacheSelector,
    organisationsCacheSelector,
    usersCacheSelector,
  ],
  (
    currentBrochureId,
    brochuresCache,
    projectsCache,
    stocksCache,
    organisationsCache,
    usersCache,
  ) => {
    let brochure = null;

    if (brochuresCache[currentBrochureId]) {
      brochure = { ...brochuresCache[currentBrochureId] };

      if (brochure) {
        if (brochure.projects) {
          const projectList = [];
          brochure.projects.forEach((projectId) => {
            const project = projectsCache[projectId];
            if (project) {
              if (project.seller) {
                project.seller = organisationsCache[project.seller];
              }
              projectList.push(project);
            }
          });
          brochure.projects = projectList;
        }
        if (brochure.stocks) {
          const stockList = [];
          brochure.stocks.forEach((stockId) => {
            stockList.push(stocksCache[stockId]);
          });
          brochure.stocks = stockList;
        }
        if (brochure.owner && usersCache[brochure.owner]) {
          brochure.owner = usersCache[brochure.owner];
        }
      }
    }

    return brochure;
  },
);

export const getBrochureOwnerOrganisation = createSelector(
  [
    currentBrochureIdSelector,
    brochureCacheSelector,
    usersCacheSelector,
  ],
  (
    currentBrochureId,
    brochuresCache,
    usersCache,
  ) => {
    const brochure = brochuresCache[currentBrochureId];
    let ownerOrganisation = null;

    if (brochure && brochure.owner) {
      const owner = usersCache[brochure.owner];
      if (owner && owner.organisation) {
        ownerOrganisation = owner.organisation;
      }
    }

    return ownerOrganisation;
  },
);
