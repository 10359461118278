import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';

import Icon, { ICONS } from 'components/Icon/Icon';

import './LimitedAccessBanner.scss';

const LimitedAccessBanner = ({ loggedInUser }) => {
  if (
    loggedInUser
    && loggedInUser.subscription
    && loggedInUser.subscription.type === 'PENDING'
  ) {
    return (
      <div className="limitedAccessBannerWrap">
        <div className="limitedAccessBanner">
          <Icon icon={ICONS.exclamation} colour="#FFF" size={16} />
          <p>
            <Translate id="limitedAccessBanner.text" />
          </p>
        </div>
      </div>
    );
  }
  return null;
};

LimitedAccessBanner.defaultProps = {
  loggedInUser: null
};

LimitedAccessBanner.propTypes = {
  loggedInUser: PropTypes.object
};

export default LimitedAccessBanner;
