import React from 'react';
import { showSignInNotificationPopup, showSigninViaEmailForm } from 'components/SignInNotificationPopup/SigninNotificationPopupActions';
import { useDispatch } from 'react-redux';
import { Translate } from 'react-localize-redux';
import { Button } from 'react-bootstrap';

export default function SigninNotificationButton() {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(showSignInNotificationPopup());
    dispatch(showSigninViaEmailForm(false));
  };
  return (
  <Button
    className="loginButton"
    onClick={() => handleClick()}>
              <Translate id="common.login" />
  </Button>
  );
}
