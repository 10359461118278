import React from 'react';
import Favicon from 'react-favicon';

import useEnvironment from 'hooks/useEnvironment';

export default function EnvironmentFavicon() {
  const { environment } = useEnvironment();
  const { faviconImage } = environment || { };
  return faviconImage ? <Favicon url={faviconImage.url} /> : null;
}
