import axios from 'axios';

import { signupApi, convertPendingUserApi, createPendingUserLogoApi } from 'services/signup.api';
import { showAlertPopup } from 'components/AlertPopup/AlertPopupActions';
import { externalUrlPath } from 'utility/redirect';

export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';
export const CONVERT_PENDING_USER_SUCCESS = 'CONVERT_PENDING_USER_SUCCESS';
export const CONVERT_PENDING_USER_FAILURE = 'CONVERT_PENDING_USER_FAILURE';
export const CREATE_PENDING_USER_LOGO = 'CREATE_PENDING_USER';
export const CREATE_PENDING_USER_LOGO_SUCCESS = 'CREATE_PENDING_USER_LOGO_SUCCESS';
export const CREATE_PENDING_USER_LOGO_FAILURE = 'CREATE_PENDING_USER_LOGO_FAILURE';
export const SHOW_LOADER = 'SHOW_LOADER';

export function signupSuccess() {
  return {
    type: SIGNUP_SUCCESS,
  };
}

export function signupFailure() {
  return {
    type: SIGNUP_FAILURE,
  };
}

export function convertPendingUserSuccess() {
  return {
    type: CONVERT_PENDING_USER_SUCCESS,
  };
}

export function convertPendingUserFailure() {
  return {
    type: CONVERT_PENDING_USER_FAILURE,
  };
}

export function createPendingUserLogo() {
  return {
    type: CREATE_PENDING_USER_LOGO,
  };
}

export function createPendingUserLogoSuccess(createPendingUserLogoResult) {
  return {
    type: CREATE_PENDING_USER_LOGO_SUCCESS,
    createPendingUserLogoResult,
  };
}

export function createPendingUserLogoFailure(error) {
  return {
    type: CREATE_PENDING_USER_LOGO_FAILURE,
    error,
  };
}

export function showLoader() {
  return {
    type: SHOW_LOADER,
  };
}

export function createPendingUserLogoThunk(email, confirmationCode, file, language) {
  return (dispatch) => {
    dispatch(createPendingUserLogo());
    createPendingUserLogoApi(email, confirmationCode, file.name, language).then((result) => {
      if (result.error) {
        dispatch(createPendingUserLogoFailure(new Error(result.error)));
      } else {
        let contentType;
        let contentLanguage;
        let contentDisposition;
        for (let i = 0; i < result.data.createPendingUserLogo.headers.length; i += 1) {
          switch (result.data.createPendingUserLogo.headers[i].name) {
            case 'Content-Type':
              contentType = result.data.createPendingUserLogo.headers[i].value;
              break;
            case 'Content-Language':
              contentLanguage = result.data.createPendingUserLogo.headers[i].value;
              break;
            case 'Content-Disposition':
              contentDisposition = result.data.createPendingUserLogo.headers[i].value;
              break;
            default:
          }
        }
        const options = {
          headers: {
            'Content-Type': contentType,
            'Content-Language': contentLanguage,
            'Content-Disposition': contentDisposition,
          },
        };
        axios.put(result.data.createPendingUserLogo.uri, file, options)
          .then((uploadResult) => {
            const createPendingUserLogoResult = {
              assetId: result.data.createPendingUserLogo.assetId,
              eTag: uploadResult.headers.etag,
            };
            dispatch(createPendingUserLogoSuccess(createPendingUserLogoResult));
          })
          .catch((error) => {
            dispatch(createPendingUserLogoFailure(error));
          });
      }
    }, (error) => {
      dispatch(createPendingUserLogoFailure(error));
    });
  };
}


export function signupThunk(
  firstName,
  lastName,
  email,
  countryCode,
  phone,
  companyName,
) {
  return (dispatch) => {
    signupApi(
      firstName,
      lastName,
      email,
      countryCode,
      phone,
      companyName,
    )
      .then((result) => {
        if (result.error) {
          dispatch(signupFailure(new Error(result.error)));
          dispatch(showAlertPopup('signup-request-failure'));
        } else {
          dispatch(signupSuccess());
          dispatch(showAlertPopup('signup-request-success'));
        }
      }, (error) => {
        dispatch(signupFailure(error));
        dispatch(showAlertPopup('signup-request-failure'));
      });
  };
}

export function convertPendingUserThunk(
  email,
  confirmationCode,
  password,
  companyWebsiteUri,
  companyLogoId,
  companyLogoETag,
  countryCode,
  level1AdministrativeAreaId,
  region,
  suburb
) {
  return (dispatch) => {
    convertPendingUserApi(
      email,
      confirmationCode,
      password,
      companyWebsiteUri,
      companyLogoId,
      companyLogoETag,
      countryCode,
      level1AdministrativeAreaId,
      region,
      suburb
    )
      .then((result) => {
        if (result.error) {
          dispatch(convertPendingUserFailure(new Error(result.error)));
        } else if (result.data.convertPendingUser === false) {
          dispatch(showAlertPopup('convert-pending-user-failure'));
        } else {
          dispatch(convertPendingUserSuccess());
          dispatch(showLoader());
          window.location.href = externalUrlPath('/Home/Login');
        }
      }, (error) => {
        dispatch(convertPendingUserFailure(error));
        dispatch(showAlertPopup('convert-pending-user-failure'));
      });
  };
}
