import {
  BUILD_BROCHURE,
  BUILD_BROCHURE_RESET,
  BUILD_BROCHURE_SUCCESS,
  BUILD_BROCHURE_FAILURE,

  FETCH_BROCHURE_DETAILS,
  FETCH_BROCHURE_DETAILS_SUCCESS,
  FETCH_BROCHURE_DETAILS_FAILURE,

  FETCH_BROCHURE_PROJECT_DETAILS,
  FETCH_BROCHURE_PROJECT_DETAILS_SUCCESS,
  FETCH_BROCHURE_PROJECT_DETAILS_FAILURE,

  FETCH_BROCHURE_PROJECT_OVERVIEW,
  FETCH_BROCHURE_PROJECT_OVERVIEW_SUCCESS,
  FETCH_BROCHURE_PROJECT_OVERVIEW_FAILURE,

  FETCH_BROCHURE_PROJECT_IMAGES,
  FETCH_BROCHURE_PROJECT_IMAGES_SUCCESS,
  FETCH_BROCHURE_PROJECT_IMAGES_FAILURE,

  FETCH_BROCHURE_STOCK_LIST,
  FETCH_BROCHURE_STOCK_LIST_SUCCESS,
  FETCH_BROCHURE_STOCK_LIST_FAILURE,
} from './BrochureActions';

function buildBrochure(state) {
  return {
    ...state,
    buildBrochureLoading: true,
    buildBrochureError: null,
  };
}

function buildBrochureReset(state) {
  return {
    ...state,
    buildBrochureLoading: false,
    buildBrochureError: false,
    builtBrochureId: null
  };
}

function buildBrochureSuccess(state, action) {
  return {
    ...state,
    buildBrochureLoading: false,
    builtBrochureId: action.brochureId,
  };
}

function buildBrochureFailure(state, action) {
  return {
    ...state,
    buildBrochureLoading: false,
    buildBrochureError: action.error,
  };
}

function fetchBrochureDetails(state, action) {
  return {
    ...state,
    brochureDetailsLoading: true,
    brochureDetailsError: null,
    currentBrochureId: action.brochureId,
  };
}

function fetchBrochureDetailsSuccess(state, action) {
  return {
    ...state,
    brochureDetailsLoading: false,
    portalSettings: action.brochuredetails.environment,
  };
}

function fetchBrochureDetailsFailure(state, action) {
  return {
    ...state,
    brochureDetailsLoading: false,
    brochureDetailsError: action.error,
  };
}

function fetchBrochureProjectDetails(state, action) {
  return {
    ...state,
    projectDetailsLoading: true,
    projectDetailsError: null,
    currentBrochureId: action.brochureId,
  };
}

function fetchBrochureProjectDetailsSuccess(state) {
  return {
    ...state,
    projectDetailsLoading: false,
  };
}

function fetchBrochureProjectDetailsFailure(state, action) {
  return {
    ...state,
    projectDetailsLoading: false,
    projectDetailsError: action.error,
  };
}

function fetchBrochureProjectOverview(state, action) {
  return {
    ...state,
    projectOverviewLoading: true,
    projectOverviewError: null,
    currentBrochureId: action.brochureId,
  };
}

function fetchBrochureProjectOverviewSuccess(state) {
  return {
    ...state,
    projectOverviewLoading: false,
  };
}

function fetchBrochureProjectOverviewFailure(state, action) {
  return {
    ...state,
    projectOverviewLoading: false,
    projectOverviewError: action.error,
  };
}

function fetchBrochureProjectImages(state, action) {
  return {
    ...state,
    projectImagesLoading: true,
    projectImagesError: null,
    currentBrochureId: action.brochureId,
  };
}

function fetchBrochureProjectImagesSuccess(state) {
  return {
    ...state,
    projectImagesLoading: false,
  };
}

function fetchBrochureProjectImagesFailure(state, action) {
  return {
    ...state,
    projectImagesLoading: false,
    projectImagesError: action.error,
  };
}

function fetchBrochureStockList(state, action) {
  return {
    ...state,
    stockListLoading: true,
    stockListError: null,
    currentBrochureId: action.brochureId,
  };
}

function fetchBrochureStockListSuccess(state) {
  return {
    ...state,
    stockListLoading: false,
  };
}

function fetchBrochureStockListFailure(state, action) {
  return {
    ...state,
    stockListLoading: false,
    stockListError: action.error,
  };
}

const initialState = {
  buildBrochureLoading: false,
  buildBrochureError: null,
  brochureDetailsLoading: false,
  brochureDetailsError: null,
  projectDetailsLoading: false,
  projectDetailsError: null,
  projectOverviewLoading: false,
  projectOverviewError: null,
  projectImagesLoading: false,
  projectImagesError: null,
  stockListLoading: false,
  stockListError: null,

  builtBrochureId: null,
  currentBrochureId: null,
};

export default function BrochureReducers(state = initialState, action) {
  switch (action.type) {
    case BUILD_BROCHURE:
      return buildBrochure(state);
    case BUILD_BROCHURE_RESET:
      return buildBrochureReset(state);
    case BUILD_BROCHURE_SUCCESS:
      return buildBrochureSuccess(state, action);
    case BUILD_BROCHURE_FAILURE:
      return buildBrochureFailure(state, action);

    case FETCH_BROCHURE_DETAILS:
      return fetchBrochureDetails(state, action);
    case FETCH_BROCHURE_DETAILS_SUCCESS:
      return fetchBrochureDetailsSuccess(state, action);
    case FETCH_BROCHURE_DETAILS_FAILURE:
      return fetchBrochureDetailsFailure(state, action);

    case FETCH_BROCHURE_PROJECT_DETAILS:
      return fetchBrochureProjectDetails(state, action);
    case FETCH_BROCHURE_PROJECT_DETAILS_SUCCESS:
      return fetchBrochureProjectDetailsSuccess(state);
    case FETCH_BROCHURE_PROJECT_DETAILS_FAILURE:
      return fetchBrochureProjectDetailsFailure(state, action);

    case FETCH_BROCHURE_PROJECT_OVERVIEW:
      return fetchBrochureProjectOverview(state, action);
    case FETCH_BROCHURE_PROJECT_OVERVIEW_SUCCESS:
      return fetchBrochureProjectOverviewSuccess(state);
    case FETCH_BROCHURE_PROJECT_OVERVIEW_FAILURE:
      return fetchBrochureProjectOverviewFailure(state, action);

    case FETCH_BROCHURE_PROJECT_IMAGES:
      return fetchBrochureProjectImages(state, action);
    case FETCH_BROCHURE_PROJECT_IMAGES_SUCCESS:
      return fetchBrochureProjectImagesSuccess(state);
    case FETCH_BROCHURE_PROJECT_IMAGES_FAILURE:
      return fetchBrochureProjectImagesFailure(state, action);

    case FETCH_BROCHURE_STOCK_LIST:
      return fetchBrochureStockList(state, action);
    case FETCH_BROCHURE_STOCK_LIST_SUCCESS:
      return fetchBrochureStockListSuccess(state);
    case FETCH_BROCHURE_STOCK_LIST_FAILURE:
      return fetchBrochureStockListFailure(state, action);

    default:
      return state;
  }
}
