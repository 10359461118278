let configuration;

export function configure() {
  if (configuration) {
    return configuration;
  }
  configuration = fetch('/neo/config.json')
    .then((response) => response.json());
  return configuration;
}
