import {
  SHOW_SIGN_UP_DIALOG,
  HIDE_SIGN_UP_DIALOG,
} from './SignupDialogActions';

function showSignupDialog(state, action) {
  return {
    ...state,
    tag: action.tag,
    visible: true,
    customerType: action.customerType,
    canClose: action.canClose,
  };
}

function hideSignupDialog(state, action) {
  return {
    ...state,
    tag: action.tag,
    visible: false,
  };
}

const initialState = {
  visible: false,
  tag: '',
  customerType: 'AGENT',
  canClose: true,
};

export default function SignupDialogReducers(state = initialState, action) {
  switch (action.type) {
    case SHOW_SIGN_UP_DIALOG:
      return showSignupDialog(state, action);
    case HIDE_SIGN_UP_DIALOG:
      return hideSignupDialog(state, action);

    default:
      return state;
  }
}
