import { graphQLQuery } from './api';

export function loadCountriesApi() {
  const queryString = `
  query countries {
    countries {
      id
      name
      code
      level1AdministrativeAreas { id abbreviation name country {id name} }
    }
  }`;
  const variables = {
  };
  return graphQLQuery(queryString, variables);
}
