import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Translate, getTranslate } from 'react-localize-redux';
import {
  Modal,
  Button,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';

import { hideSignupDialog as hideSignupDialogAction } from 'components/SignupDialog/SignupDialogActions';
import {
  getSignupDialogTag, getSignupDialogVisible, getCustomerType, getCanCloseDialog
} from 'components/SignupDialog/SignupDialogSelectors';
import { signupThunk } from 'components/Signup/SignupActions';
import { publicCallbackRequestThunk } from 'components/Public/PublicActions';
import AlertPopup from 'components/AlertPopup/AlertPopup';
import { ICONS } from 'components/Icon/Icon';
import { getUsersCountry } from 'components/Integration/IntegrationSelectors';
import colours from 'components/App/InvestoristColours.scss';

import SignupForm from './components/SignupForm';

import './SignupDialog.scss';

class SignupDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
      customerEmail: '',
      recaptchaValue: null,
      recaptchaError: false,
    };
    this.onHide = this.onHide.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  onHide() {
    const { canCloseDialog, hideSignupDialog } = this.props;
    if (canCloseDialog) {
      hideSignupDialog();
    }
  }

  onSubmit(values) {
    const {
      customerType,
      hideSignupDialog,
      publicCallbackRequest,
      signup,
      successCallback
    } = this.props;

    if (customerType === 'AGENT') {
      this.setState({ customerEmail: values.email });
      signup(
        values.firstName,
        values.lastName,
        values.email,
        values.countryCode,
        values.phone,
        values.companyName,
      );
    } else {
      const customerName = values.firstName + ' ' + values.lastName;
      const currentUrl = window.location.pathname;
      const projectId = currentUrl.toLowerCase().includes('/neo/project/') ? currentUrl.split('/').pop() : null;
      publicCallbackRequest(
        customerType,
        customerName,
        values.email,
        values.phone,
        values.companyName,
        projectId,
        successCallback,
      );
    }
    hideSignupDialog();
  }

  handleSubmit() {
    const { recaptchaValue } = this.state;
    if (recaptchaValue) {
      this.signupFormRef.submit();
    } else {
      this.setState({ recaptchaError: true });
    }
  }

  render() {
    const {
      signupDialogTag,
      signupDialogVisible,
      canCloseDialog,
      customerType,
      usersCountry,
      translate
    } = this.props;

    const { customerEmail, disabled, recaptchaError } = this.state;

    let headingText = 'getInTouch';

    if (signupDialogTag === 'signupForFree') {
      headingText = 'signupForFree';
    }

    return (
      <div>
        <Modal
          className="signupDialog"
          show={signupDialogVisible}
          backdrop="static"
          onHide={this.onHide}>
          <Modal.Header closeButton={canCloseDialog} />
          <Modal.Body>
            <div className="text-center">
              <h1 className="text-center">
                <Translate id={'signupDialog.heading.' + headingText} />
              </h1>
              <h3 className="bumpTop mainBrandColour">
                <Translate id={'signupDialog.subHeading.' + customerType} />
              </h3>
              <p className="bumpTopHalf">
                <Translate id={'signupDialog.description.' + customerType} />
              </p>
              <h3 className="bumpBottomHalf bumpTop text-left">
                <Translate id="signupDialog.formHeading" />
              </h3>
              <div className="bumpTop">
                <SignupForm
                  ref={(node) => { this.signupFormRef = node; }}
                  onSubmit={this.onSubmit}
                  customerType={customerType}
                  usersCountry={usersCountry} />
                <div className="recaptchaSection bumpTop">
                  <ReCAPTCHA
                    sitekey="6Lc_tfcmAAAAADr_qEObpsqswXwF2HQt1UOeTl7Q"
                    onChange={(event) => {
                      this.setState({ recaptchaValue: event });
                    }} />
                  <p className=" bumpTopHalf error-message" hidden={!recaptchaError}><Translate id="common.recaptchaError" /></p>
                </div>
              </div>
              <Button
                className="sectionHeading btn-lg"
                disabled={disabled}
                onClick={() => { this.handleSubmit(); }}>
                <Translate id="signupDialog.buttonText" />
              </Button>
            </div>
          </Modal.Body>
        </Modal>
        {
          customerType === 'AGENT'
          && (
            <AlertPopup
              tag="signup-request-success"
              heading={translate('signupDialog.successPopup.agent.heading')}
              content={[
                {
                  key: 'customerEmail',
                  value: translate(
                    'signupDialog.successPopup.agent.content1',
                    { customerEmail })
                },
                {
                  key: 'supportEmail',
                  value: translate('signupDialog.successPopup.agent.content2')
                }
              ]}
              icon={{
                data: ICONS.sent,
                size: 50,
                colour: colours.primaryAccent,
              }} />
          )
        }
        {
          customerType !== 'AGENT'
          && (
            <AlertPopup
              tag="public-callback-request-success"
              heading={translate('signupDialog.successPopup.heading')}
              content={translate('signupDialog.successPopup.heading')}
              icon={{
                data: ICONS.sent,
                size: 50,
                colour: colours.primaryAccent,
              }}
              button={{
                text: translate('signupDialog.successPopup.button'),
                className: 'success',
                func: () => { window.location.href = 'https://investorist.com/'; },
              }} />
          )
        }
        <AlertPopup
          tag={customerType === 'AGENT' ? 'signup-request-failure' : 'public-callback-request-failure'}
          heading={translate('signupDialog.failurePopupHeading')}
          icon={{
            data: ICONS.failure,
            size: 50,
            colour: colours.alternate,
          }} />
      </div>
    );
  }
}

SignupDialog.defaultProps = {
  signupDialogTag: null,
  successCallback: null,
  usersCountry: 'AU',
};

SignupDialog.propTypes = {
  hideSignupDialog: PropTypes.func.isRequired,
  signupDialogVisible: PropTypes.bool.isRequired,
  signupDialogTag: PropTypes.string,
  signup: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  customerType: PropTypes.string.isRequired,
  canCloseDialog: PropTypes.bool.isRequired,
  usersCountry: PropTypes.string,
  publicCallbackRequest: PropTypes.func.isRequired,
  successCallback: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    signupDialogTag: getSignupDialogTag(state),
    signupDialogVisible: getSignupDialogVisible(state),
    customerType: getCustomerType(state),
    canCloseDialog: getCanCloseDialog(state),
    usersCountry: getUsersCountry(state),
    translate: getTranslate(state.localize),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    hideSignupDialog: () => dispatch(hideSignupDialogAction()),
    signup:
      (firstName, lastName, email, countryCode, phone, companyName) => dispatch(signupThunk(
        firstName,
        lastName,
        email,
        countryCode,
        phone,
        companyName,
      )),
    publicCallbackRequest:
      (customerType, name, email, phone, company, projectId, successCallback) => dispatch(
        publicCallbackRequestThunk(
          customerType,
          name,
          email,
          phone,
          company,
          projectId,
          successCallback,
        )
      ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignupDialog);


