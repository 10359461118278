import {
  getCurrentLanguageCode,
  getCurrentPageLoadId,
  getCurrentReferrerUrl,
} from 'utility/utility';
import getAuthenticationToken from './getAuthenticationToken';

const getRequestHeaders = () => ({
  Language: getCurrentLanguageCode(),
  PageLoadId: getCurrentPageLoadId(),
  AuthenticationToken: getAuthenticationToken(),
  PageReferrer: getCurrentReferrerUrl()
});

export default getRequestHeaders;
