export const SHOW_MESSAGE_POPUP = 'SHOW_MESSAGE_POPUP';
export const HIDE_MESSAGE_POPUP = 'HIDE_MESSAGE_POPUP';

export function showMessagePopup() {
  return {
    type: SHOW_MESSAGE_POPUP,
  };
}

export function hideMessagePopup() {
  return {
    type: HIDE_MESSAGE_POPUP,
  };
}
