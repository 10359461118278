import { createSlice } from '@reduxjs/toolkit';

const initialState = { isVisible: false };

const acceptCookiesPopupSlice = createSlice({
  name: 'acceptCookiesPopup',
  initialState,
  reducers: {
    showAcceptCookiesPopup(state) {
      state.isVisible = true;
    },
    hideAcceptCookiesPopup(state) {
      state.isVisible = false;
    }
  }
});

export const {
  showAcceptCookiesPopup,
  hideAcceptCookiesPopup
} = acceptCookiesPopupSlice.actions;

export default acceptCookiesPopupSlice.reducer;

export const selectIsVisible = (state) => state.acceptCookiesPopup.isVisible;
