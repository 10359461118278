import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Translate } from 'react-localize-redux';
import { Modal, Button } from 'react-bootstrap';

import useLocalStorage from 'hooks/useLocalStorage';
import { changeOnboardingPage } from 'components/LoggedInUser/LoggedInUserActions';
import Icon, { ICONS } from 'components/Icon/Icon';

const OnboardingModal = ({
  onboardingSlides,
  localStorageKey,
  onChangeOnboardingPage
}) => {
  const [currentPage, setCurrentPage] = useLocalStorage(localStorageKey, 0);
  const maxOnboardingPages = onboardingSlides.length;
  const seenAllOnboardingPages = currentPage <= maxOnboardingPages;
  const [openDialog, setOpenDialog] = useState(seenAllOnboardingPages);

  if (currentPage > maxOnboardingPages) {
    setCurrentPage(maxOnboardingPages);
  } else if (currentPage === maxOnboardingPages) {
    return null;
  } else if (currentPage < 0) {
    setCurrentPage(0);
  }

  const onHide = () => {
    setCurrentPage(maxOnboardingPages);
    setOpenDialog(false);
    onChangeOnboardingPage(maxOnboardingPages);
  };

  const onNextPage = () => {
    if (currentPage >= maxOnboardingPages) {
      onHide();
    } else {
      setCurrentPage(currentPage + 1);
      onChangeOnboardingPage(currentPage + 1);
    }
  };

  const onPreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
      onChangeOnboardingPage(currentPage - 1);
    }
  };

  return (
    <Modal
      show={openDialog}
      onHide={onHide}
      keyboard={false}
      backdrop="static"
      className={'dialogWindow onboardingPage' + currentPage}>
      <Modal.Header>
        {maxOnboardingPages !== 1 && (
          <Button className="skip whiteButton" onClick={onHide}>
            <Translate id="onboarding.button.skip" />
          </Button>
        )}
      </Modal.Header>
      <Modal.Body>{onboardingSlides[currentPage]}</Modal.Body>
      <Modal.Footer>
        {currentPage === 0 && (
          <Button className="explore" onClick={onNextPage}>
            <Icon icon={ICONS.explore} size={18} />
            <Translate id="onboarding.button.explore" />
          </Button>
        )}
        {currentPage !== 0 && currentPage !== maxOnboardingPages - 1 && (
          <Button className="previous" onClick={onPreviousPage}>
            &lt;&nbsp;
            <Translate id="onboarding.button.prev" />
          </Button>
        )}
        {currentPage !== 0 && currentPage < maxOnboardingPages - 1 && (
          <Button className="next" onClick={onNextPage}>
            <Translate id="onboarding.button.next" />
            &nbsp;&gt;
          </Button>
        )}
        {currentPage === maxOnboardingPages - 1 && maxOnboardingPages !== 1 && (
          <Button className="getStarted" onClick={onNextPage}>
            <Translate id="onboarding.button.getStarted" />
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

OnboardingModal.propTypes = {
  onboardingSlides: PropTypes.arrayOf(Object).isRequired,
  localStorageKey: PropTypes.string.isRequired,
  onChangeOnboardingPage: PropTypes.func.isRequired
};

function mapDispatchToProps(dispatch) {
  return {
    onChangeOnboardingPage: (pageNumber) => dispatch(changeOnboardingPage(pageNumber))
  };
}

export default connect(
  null,
  mapDispatchToProps
)(OnboardingModal);
