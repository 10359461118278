import { publicCallbackRequestApi } from 'services/public.api';
import { showAlertPopup } from 'components/AlertPopup/AlertPopupActions';

export const PUBLIC_CALLBACK_ENQUIRY_SUCCESS = 'PUBLIC_CALLBACK_ENQUIRY_SUCCESS';
export const PUBLIC_CALLBACK_ENQUIRY_FAILURE = 'PUBLIC_CALLBACK_ENQUIRY_FAILURE';

export function publicCallbackRequestSuccess() {
  return {
    type: PUBLIC_CALLBACK_ENQUIRY_SUCCESS,
  };
}

export function publicCallbackRequestFailure() {
  return {
    type: PUBLIC_CALLBACK_ENQUIRY_FAILURE,
  };
}

export function publicCallbackRequestThunk(
  customerType,
  name,
  email,
  phone,
  company,
  projectId,
  successCallback,
) {
  return (dispatch) => {
    publicCallbackRequestApi(
      customerType,
      name,
      email,
      phone,
      company,
      projectId,
    )
      .then((result) => {
        if (result.error) {
          dispatch(publicCallbackRequestFailure(new Error(result.error)));
          dispatch(showAlertPopup('public-callback-request-failure'));
        } else {
          dispatch(publicCallbackRequestSuccess());
          if (successCallback) {
            successCallback();
          } else {
            dispatch(showAlertPopup('public-callback-request-success'));
          }
        }
      }, (error) => {
        dispatch(publicCallbackRequestFailure(error));
        dispatch(showAlertPopup('public-callback-request-failure'));
      });
  };
}
