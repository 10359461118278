export const SET_DASHBOARD_TIME_RANGE_FILTER = 'SET_DASHBOARD_TIME_RANGE_FILTER';
export const SET_AGENT_PROFILE_TIME_RANGE_FILTER = 'SET_AGENT_PROFILE_TIME_RANGE_FILTER';

export function setDashboardTimeRangeFilter(timeRangeFilter) {
  return {
    type: SET_DASHBOARD_TIME_RANGE_FILTER,
    timeRangeFilter,
  };
}

export function setAgentProfileTimeRangeFilter(timeRangeFilter) {
  return {
    type: SET_AGENT_PROFILE_TIME_RANGE_FILTER,
    timeRangeFilter,
  };
}
