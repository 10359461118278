import React from 'react';
import { render, hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { LocalizeProvider } from 'react-localize-redux';
import moment from 'moment';
import { PersistGate } from 'redux-persist/integration/react';
import { AppContainer } from 'react-hot-loader';
import { HelmetProvider } from 'react-helmet-async';

import { initialiseCache } from 'utility/cache';
import {
  setMeasurementUnits,
  setPresentationMode,
  setTrialPopUpShownDate,
} from 'components/LoggedInUser/LoggedInUserActions';
import App from 'components/App/App';
import Loader from 'components/Loader/Loader';
import configureStore from 'reducers/reducers';
import Localized from 'locale/Localized';
import { configure } from 'services/configuration';

import 'regenerator-runtime/runtime';
import 'core-js/stable';


configure();

configureStore().then(({ store, persistor }) => {
  const measurementUnits = localStorage.getItem('measurementUnits');
  if (measurementUnits === 'IMPERIAL') {
    store.dispatch(setMeasurementUnits(measurementUnits));
  }

  const trialPopUpShownDate = localStorage.getItem('trialPopUpShownDate');
  if (trialPopUpShownDate === moment().format('YYYY-MM-DD')) {
    store.dispatch(setTrialPopUpShownDate(trialPopUpShownDate));
  }

  store.dispatch(setPresentationMode(localStorage.getItem('presentationMode') === 'true'));

  initialiseCache(persistor);

  function renderComponent(Component) {
    render(
      <HelmetProvider>
        <AppContainer>
          <Provider store={store}>
            <LocalizeProvider store={store}>
              <PersistGate loading={<Loader page />} persistor={persistor}>
                <Localized store={store}>
                  <Component />
                </Localized>
              </PersistGate>
            </LocalizeProvider>
          </Provider>
        </AppContainer>
      </HelmetProvider>,
      document.getElementById('root'),
    );
  }

  const rootElement = document.getElementById('root');
  if (rootElement.hasChildNodes()) {
    hydrate(App);
  } else {
    renderComponent(App);
  }

  // Webpack Hot Module Replacement API
  if (module.hot) {
    module.hot.accept('./components/App/App', () => { renderComponent(App); });
  }
});
