import { getActiveLanguage } from 'react-localize-redux';

import { getIntegrationServicesApi } from 'services/integration.api';
import { initialiseMessaging } from 'utility/messaging';
import { initialiseIntercom } from 'utility/intercom';
import {
  getTalkJsIntegration,
  getIntercomIntegration,
} from 'components/Integration/IntegrationSelectors';
import HttpResponseError from '../../services/HttpResponseError';

export const REQUEST_INTEGRATION_SERVICES = 'REQUEST_INTEGRATION_SERVICES';
export const REQUEST_INTEGRATION_SERVICES_SUCCESS = 'REQUEST_INTEGRATION_SERVICES_SUCCESS';
export const REQUEST_INTEGRATION_SERVICES_FAILURE = 'REQUEST_INTEGRATION_SERVICES_FAILURE';

// Integration actions
function requestIntegrationServices() {
  return {
    type: REQUEST_INTEGRATION_SERVICES,
  };
}

function requestIntegrationServicesSuccess(integrationData) {
  return {
    type: REQUEST_INTEGRATION_SERVICES_SUCCESS,
    integrationData,
  };
}

function requestIntegrationServicesFailure(error) {
  return {
    type: REQUEST_INTEGRATION_SERVICES_FAILURE,
    error,
  };
}

// Integration thunks
export function requestIntegrationServicesThunk(useAuthenticationToken = true) {
  return (dispatch, getState) => {
    dispatch(requestIntegrationServices());
    getIntegrationServicesApi(useAuthenticationToken)
      .catch((error) => {
        if (error instanceof HttpResponseError) {
          return error.response.json();
        }
        throw error;
      })
      .then((result) => {
        if (result.errors) {
          throw new Error(result.Errors);
        }
        return result;
      })
      .then((result) => {
        dispatch(requestIntegrationServicesSuccess(result.data.integrationServices));
        initialiseMessaging(getTalkJsIntegration(getState()));
        initialiseIntercom(
          getIntercomIntegration(getState()),
          getActiveLanguage(getState().localize).code,
        );
      })
      .catch((error) => {
        dispatch(requestIntegrationServicesFailure(error));
      });
  };
}
