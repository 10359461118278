import { createAssetApi, confirmAssetUploadApi } from 'services/asset.api';

import axios from 'axios';

export const CREATE_ASSET = 'CREATE_ASSET';
export const CREATE_ASSET_SUCCESS = 'CREATE_ASSET_SUCCESS';
export const CREATE_ASSET_FAILURE = 'CREATE_ASSET_FAILURE';
export const CONFIRM_ASSET_UPLOAD_SUCCESS = 'CONFIRM_ASSET_UPLOAD_SUCCESS';
export const CONFIRM_ASSET_UPLOAD_FAILURE = 'CONFIRM_ASSET_UPLOAD_FAILURE';
export const SAVE_ASSET_UPLOADING_COUNT = 'SAVE_ASSET_UPLOADING_COUNT';

export function createAsset() {
  return {
    type: CREATE_ASSET,
  };
}

export function createAssetSuccess(createAssetResult) {
  return {
    type: CREATE_ASSET_SUCCESS,
    createAssetResult,
  };
}

export function createAssetFailure(error) {
  return {
    type: CREATE_ASSET_FAILURE,
    error,
  };
}

export function confirmAssetUploadSuccess(confirmAssetUploadResult) {
  return {
    type: CONFIRM_ASSET_UPLOAD_SUCCESS,
    confirmAssetUploadResult,
  };
}

export function confirmAssetUploadFailure(error) {
  return {
    type: CONFIRM_ASSET_UPLOAD_FAILURE,
    error,
  };
}

export function saveAssetUploadingCount(assetUploadingCount) {
  return {
    type: SAVE_ASSET_UPLOADING_COUNT,
    assetUploadingCount,
  };
}

function getHeaderOptions(asset, desiredHeaders) {
  const { headers } = asset;
  const options = { headers: { } };
  const desiredHeadersSet = new Set(desiredHeaders);

  for (let i = 0; i < headers.length; i += 1) {
    const { name, value } = headers[i];
    if (desiredHeadersSet.has(name)) {
      options.headers[name] = value;
    }
  }

  return options;
}

export function createAssetThunk(
  isPublic,
  file,
  language,
  assetType,
  onUploadConfirmedThunk
) {
  return (dispatch, getState) => {
    const getAssetUploadingCount = () => getState().asset.assetUploadingCount; // eslint-disable-line
    const incrementAssetUploadingCount = (count) => dispatch(saveAssetUploadingCount(count + 1));
    const decrementAssetUploadingCount = (count) => dispatch(saveAssetUploadingCount(count - 1));

    dispatch(createAsset());

    incrementAssetUploadingCount(getAssetUploadingCount());

    createAssetApi(isPublic, file.name, language).then((result) => {
      if (result.error) {
        decrementAssetUploadingCount(getAssetUploadingCount());

        dispatch(createAssetFailure(new Error(result.error)));

        return;
      }

      const asset = result.data.createAsset;

      const { assetId, uri } = asset;

      dispatch(createAssetSuccess(asset));

      const desiredHeaders = ['Content-Type', 'Content-Language', 'Content-Disposition'];
      const options = getHeaderOptions(asset, desiredHeaders);
      axios.put(uri, file, options)
        .then((uploadResult) => {
          confirmAssetUploadApi(assetId, uploadResult.headers.etag)
            .then((confirmAssetUploadResult) => {
              const { error, data } = confirmAssetUploadResult;

              if (error) {
                decrementAssetUploadingCount(getAssetUploadingCount());

                dispatch(confirmAssetUploadFailure(new Error(error)));

                return;
              }

              const { confirmAssetUpload } = data;

              dispatch(confirmAssetUploadSuccess(confirmAssetUpload));

              if (confirmAssetUpload === true) {
                dispatch(onUploadConfirmedThunk(assetType, assetId));

                decrementAssetUploadingCount(getAssetUploadingCount());
              }
            }, (error) => {
              decrementAssetUploadingCount(getAssetUploadingCount());

              dispatch(confirmAssetUploadFailure(error));
            });
        })
        .catch((error) => {
          decrementAssetUploadingCount(getAssetUploadingCount());

          console.log('ERROR ' + JSON.stringify(error)); // eslint-disable-line
        });
    }, (error) => {
      decrementAssetUploadingCount(getAssetUploadingCount());

      dispatch(createAssetFailure(error));
    });
  };
}
