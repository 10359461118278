import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { renderToStaticMarkup } from 'react-dom/server';
import { withLocalize } from 'react-localize-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import { configure } from 'services/configuration';
import { supportedLanguages } from 'utility/constants';
import { initialiseUtility } from 'utility/utility';
import { getUsersCountry } from 'components/Integration/IntegrationSelectors';

import globalTranslations from './all.json';

const defaultLocale = 'en';

const locales = supportedLanguages.map((language) => language.code);

// fallback from specific locale (eg 'en-us') to general locale (eg 'en')
// then either a message for dev purposes or English as a failsafe for prod
function getFlattenTranslation(displayMissingTranslations) {
  return displayMissingTranslations
    ? ((translations, _, locale) => translations[locale] || translations[locale.split('-')[0]] || translations[defaultLocale] || '')
    : ((translations, path, locale) => translations[locale] || translations[locale.split('-')[0]] || '\u{274C}' + locale + ':' + path + '\u{274C}');
}

const flattenTranslations = (translations, path, flattenTranslation) => {
  const output = {};

  Object.entries(translations).forEach(([key, value]) => {
    if (typeof value === 'object') {
      output[key] = flattenTranslations(value, (path ? path + '.' : '') + key, flattenTranslation);
    }
  });
  if (Object.keys(output).length) return output;

  return locales.map((locale) => flattenTranslation(translations, path, locale));
};

function getLocaleFromCountryCode(countryCode) {
  if (!countryCode) {
    return null;
  }

  switch (countryCode.toUpperCase()) {
    case 'CN':
      return 'zh-cn';

    case 'US':
      return 'en-us';

    default:
      return null;
  }
}

function Localized({
  children,
  initialize,
  setActiveLanguage,
  store,
  translate
}) {
  const usersCountry = useSelector(getUsersCountry);

  const changeLanguage = (language) => {
    setActiveLanguage(language);
    if (moment.locales().findIndex((locale) => locale === language) === -1) {
      moment.locale(language);
    }
  };

  const [translationsPrepared, setTranslationsPrepared] = useState(() => {
    (async () => {
      const config = await configure();
      const flattenedTranslations = flattenTranslations(
        globalTranslations,
        null,
        getFlattenTranslation(config.localization.displayMissingTranslations)
      );

      initialize({
        languages: supportedLanguages,
        translation: flattenedTranslations,
        options: {
          renderToStaticMarkup,
          renderInnerHtml: true,
          defaultLocale
        }
      });

      const storedLanguage = localStorage.getItem('language') || 'en';
      changeLanguage(storedLanguage);

      setTranslationsPrepared(true);
    })();

    return false;
  });

  useEffect(() => {
    const storedLanguage = localStorage.getItem('language') || 'en';
    if (storedLanguage) {
      return;
    }

    const usersLocale = getLocaleFromCountryCode(usersCountry);
    if (usersLocale) {
      changeLanguage(usersLocale);
    }
  }, [usersCountry]);

  if (!translationsPrepared) {
    return null;
  }

  initialiseUtility((text, data = null) => translate(text, data), store);

  return (
    <>
      {children}
    </>
  );
}

Localized.propTypes = {
  children: PropTypes.object.isRequired,
  initialize: PropTypes.func.isRequired,
  store: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
  setActiveLanguage: PropTypes.func.isRequired,
};

export default withLocalize(Localized);
