export const SHOW_ALERT_POPUP = 'SHOW_ALERT_POPUP';
export const HIDE_ALERT_POPUP = 'HIDE_ALERT_POPUP';

export function showAlertPopup(tag, customData) {
  return {
    type: SHOW_ALERT_POPUP,
    tag,
    customData
  };
}

export function hideAlertPopup(tag) {
  return {
    type: HIDE_ALERT_POPUP,
    tag
  };
}
