import { graphQLQuery } from './api';

export function getCurrentAdvertisementsApi() {
  const queryString = `
  query {
    currentAdvertisements {
      advertisements {
        id
        image { id url }
        link
        type
        startAt
        endAt
      }
    }
  }`;
  const variables = {
  };
  return graphQLQuery(queryString, variables);
}
