import { loadCountriesApi } from 'services/countries.api';

export const FETCH_COUNTRIES = 'FETCH_COUNTRIES';
export const FETCH_COUNTRIES_SUCCESS = 'FETCH_COUNTRIES_SUCCESS';
export const FETCH_COUNTRIES_FAILURE = 'FETCH_COUNTRIES_FAILURE';

export function fetchCountries() {
  return {
    type: FETCH_COUNTRIES
  };
}

export function fetchCountriesSuccess(countries) {
  return {
    type: FETCH_COUNTRIES_SUCCESS,
    countries
  };
}

export function fetchCountriesFailure(error) {
  return {
    type: FETCH_COUNTRIES_FAILURE,
    error
  };
}

export function fetchCountriesThunk() {
  return (dispatch) => {
    dispatch(fetchCountries());
    loadCountriesApi()
      .then((result) => {
        if (result.error) {
          dispatch(fetchCountriesFailure(new Error(result.error)));
        } else {
          dispatch(fetchCountriesSuccess(result.data.countries));
        }
      }, (error) => {
        dispatch(fetchCountriesFailure(error));
      });
  };
}
