import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

import englishFlag from 'assets/images/uk-flag.png';
import chineseFlag from 'assets/images/chinese-flag.png';
import spanishFlag from 'assets/images/spanish-flag.png';
import usaFlag from 'assets/images/usa-flag.png';
import indonesianFlag from 'assets/images/indonesian-flag.png';

const flags = {
  en: englishFlag,
  'zh-cn': chineseFlag,
  'es-es': spanishFlag,
  'en-us': usaFlag,
  'id-id': indonesianFlag,
};

function LanguageButton({ language, onLanguageChange }) {
  return (
    <Button
      active={language.active}
      className="language-button"
      onClick={() => {
        localStorage.setItem('language', language.code);
        onLanguageChange(language.code);
      }}>
      <img className="languageFlag" src={flags[language.code]} alt={language.name} />
      &nbsp;
      {language.name}
    </Button>
  );
}

LanguageButton.propTypes = {
  language: PropTypes.object.isRequired,
  onLanguageChange: PropTypes.func.isRequired,
};

export default LanguageButton;
