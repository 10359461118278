import { getCurrentAdvertisementsApi } from 'services/advertisements.api';

export const FETCH_CURRENT_ADVERTISEMENTS = 'FETCH_CURRENT_ADVERTISEMENTS';
export const FETCH_CURRENT_ADVERTISEMENTS_SUCCESS = 'FETCH_CURRENT_ADVERTISEMENTS_SUCCESS';
export const FETCH_CURRENT_ADVERTISEMENTS_FAILURE = 'FETCH_CURRENT_ADVERTISEMENTS_FAILURE';

export const DISMISS_ADVERTISEMENT = 'DISMISS_ADVERTISEMENT';

export function fetchCurrentAdvertisements() {
  return {
    type: FETCH_CURRENT_ADVERTISEMENTS,
  };
}

export function fetchCurrentAdvertisementsSuccess(currentAdvertisements) {
  return {
    type: FETCH_CURRENT_ADVERTISEMENTS_SUCCESS,
    currentAdvertisements,
  };
}

export function fetchCurrentAdvertisementsFailure(error) {
  return {
    type: FETCH_CURRENT_ADVERTISEMENTS_FAILURE,
    error,
  };
}

export function dismissAdvertisement(advertisementType) {
  return {
    type: DISMISS_ADVERTISEMENT,
    advertisementType,
  };
}

export function fetchCurrentAdvertisementsThunk() {
  return (dispatch) => {
    dispatch(fetchCurrentAdvertisements());
    getCurrentAdvertisementsApi()
      .then((result) => {
        if (result.error) {
          dispatch(fetchCurrentAdvertisementsFailure(new Error(result.error)));
        } else {
          dispatch(fetchCurrentAdvertisementsSuccess(result.data.currentAdvertisements));
        }
      }, (error) => {
        dispatch(fetchCurrentAdvertisementsFailure(error));
      });
  };
}
