import { v4 as uuidv4 } from 'uuid';

import {
  CREATE_PAGE_LOAD_ID,
  SET_REFERRER_URL,
} from './AppActions';

function createPageLoadId(state) {
  return {
    ...state,
    pageLoadId: uuidv4(),
  };
}

function setReferrerUrl(state, action) {
  return {
    ...state,
    referrerUrl: action.url,
  };
}

const initialState = {
  pageLoadId: null,
  referrerUrl: null,
};

export default function AppReducers(state = initialState, action) {
  switch (action.type) {
    case CREATE_PAGE_LOAD_ID:
      return createPageLoadId(state);

    case SET_REFERRER_URL:
      return setReferrerUrl(state, action);

    default:
      return state;
  }
}
