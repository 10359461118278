import React from 'react';
import PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title';

import useEnvironment from 'hooks/useEnvironment';

export default function Title({ children }) {
  const { portalName } = useEnvironment();
  return <DocumentTitle title={portalName}>{children}</DocumentTitle>;
}

Title.propTypes = {
  children: PropTypes.any.isRequired,
};
