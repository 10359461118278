import React from 'react';
import PropTypes from 'prop-types';
import Loader from 'components/Loader/Loader';
import Error from 'components/Error/Error';

const ComponentLoader = ({ pastDelay, timedOut, error }) => {
  if (pastDelay || timedOut) {
    return <Loader config={{ page: true }} />;
  } if (error) {
    return <Error />;
  }
  return null;
};

ComponentLoader.defaultProps = {
  pastDelay: false,
  timedOut: false,
  error: null
};

ComponentLoader.propTypes = {
  pastDelay: PropTypes.bool,
  timedOut: PropTypes.bool,
  error: PropTypes.instanceOf(TypeError)
};

export default ComponentLoader;
