import { recordPageLoadApi } from 'services/pageLoad.api';

export const CREATE_PAGE_LOAD_ID = 'CREATE_PAGE_LOAD_ID';
export const RECORD_PAGE_LOAD_SUCCESS = 'RECORD_PAGE_LOAD_SUCCESS';
export const RECORD_PAGE_LOAD_FAILURE = 'RECORD_PAGE_LOAD_FAILURE';

export const SET_REFERRER_URL = 'SET_REFERRER_URL';

// App actions
export function createPageLoadId() {
  return {
    type: CREATE_PAGE_LOAD_ID,
  };
}

export function recordPageLoadSuccess() {
  return {
    type: RECORD_PAGE_LOAD_SUCCESS,
  };
}

export function recordPageLoadFailure(error) {
  return {
    type: RECORD_PAGE_LOAD_FAILURE,
    error,
  };
}

export function setReferrerUrl(url) {
  return {
    type: SET_REFERRER_URL,
    url,
  };
}

export function recordPageLoadThunk() {
  return (dispatch) => {
    recordPageLoadApi()
      .then((result) => {
        if (result.error) {
          dispatch(recordPageLoadFailure(new Error(result.error)));
        } else {
          dispatch(recordPageLoadSuccess());
        }
      }, (error) => {
        dispatch(recordPageLoadFailure(error));
      });
  };
}
