// Implements a random number generator that can be seeded
// http://indiegamr.com/generate-repeatable-random-numbers-in-js/

let seed = 0;

// Takes any integer
export function setSeed(value) {
  seed = value;
}

export function random(min = 0, max = 1) {
  seed = ((seed * 9301) + 49297) % 233280;
  const rand = seed / 233280;

  return min + (rand * (max - min));
}

// https://bost.ocks.org/mike/shuffle/
export function shuffleArray(array) {
  let currentIndex = array.length;
  let temporaryValue = 0;
  let randomIndex = 0;

  // While there remain elements to shuffle…
  while (currentIndex) {
    currentIndex -= 1;
    // Pick a remaining element…
    randomIndex = Math.floor(random() * (currentIndex + 1));

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}
