import {
  SHOW_FILTERS,
  HIDE_FILTERS,

  SAVE_FILTERS,
  CLEAR_FILTERS,

  CLEAR_PROPERTY_TYPE_FILTER,
  CLEAR_PRICE_RANGE_FILTER,
  CLEAR_PRICE_PER_AREA_RANGE_FILTER,
  CLEAR_ROOMS_AND_SPACES_FILTER,
} from './SearchFiltersActions';

export const initialState = {
  filters: {
    propertyType: ['ALL'],
    priceRange: [0, 2000000],
    pricePerAreaRange: [0, 20000],
    pricePerSquareFeetRange: [0, 2000],
    bedrooms: ['ANY'],
    bathrooms: ['ANY'],
    carparks: ['ANY'],
  },
  activeFilterPopup: null,
};

function showFilters(state, action) {
  return {
    ...state,
    activeFilterPopup: action.filter,
  };
}

function hideFilters(state) {
  return {
    ...state,
    activeFilterPopup: null,
  };
}

function saveFilters(state, action) {
  return {
    ...state,
    filters: {
      ...state.filters,
      ...action.filters,
    },
  };
}

function clearFilters(state) {
  return {
    ...state,
    filters: initialState.filters,
  };
}

function clearPropertyTypeFilter(state) {
  return {
    ...state,
    filters: {
      ...state.filters,
      propertyType: initialState.filters.propertyType,
    },
  };
}

function clearPriceRangeFilter(state) {
  return {
    ...state,
    filters: {
      ...state.filters,
      priceRange: initialState.filters.priceRange,
    },
  };
}

function clearPricePerAreaRangeFilter(state) {
  return {
    ...state,
    filters: {
      ...state.filters,
      pricePerAreaRange: initialState.filters.pricePerAreaRange,
      pricePerSquareFeetRange: initialState.filters.pricePerSquareFeetRange,
    },
  };
}

function clearRoomsAndSpacesFilter(state) {
  return {
    ...state,
    filters: {
      ...state.filters,
      bedrooms: initialState.filters.bedrooms,
      bathrooms: initialState.filters.bathrooms,
      carparks: initialState.filters.carparks,
    },
  };
}

export default function SearchFilterReducers(state = initialState, action) {
  switch (action.type) {
    case SHOW_FILTERS:
      return showFilters(state, action);
    case HIDE_FILTERS:
      return hideFilters(state);

    case SAVE_FILTERS:
      return saveFilters(state, action);
    case CLEAR_FILTERS:
      return clearFilters(state);

    case CLEAR_PROPERTY_TYPE_FILTER:
      return clearPropertyTypeFilter(state);
    case CLEAR_PRICE_RANGE_FILTER:
      return clearPriceRangeFilter(state);
    case CLEAR_PRICE_PER_AREA_RANGE_FILTER:
      return clearPricePerAreaRangeFilter(state);
    case CLEAR_ROOMS_AND_SPACES_FILTER:
      return clearRoomsAndSpacesFilter(state);

    default:
      return state;
  }
}
