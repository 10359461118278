export const REDACTION_REASON = {
  UNRESTRICTED: 'UNRESTRICTED',
  AUTHORIZATION_REQUIRED: 'AUTHORIZATION_REQUIRED',
  REGISTRATION_OF_INTEREST_REQUIRED: 'REGISTRATION_OF_INTEREST_REQUIRED',
  PROJECT_IS_SUSPENDED: 'PROJECT_IS_SUSPENDED',
  PROJECT_IS_PROSPECTIVE: 'PROJECT_IS_PROSPECTIVE',
  AGENT_SUBORDINATE: 'AGENT_SUBORDINATE',
  REGISTRATION_OF_INTEREST_PENDING: 'REGISTRATION_OF_INTEREST_PENDING',
  TRIAL_EXPIRED: 'TRIAL_EXPIRED',
  BROCHURE_REQUEST: 'BROCHURE_REQUEST',
  USER_APPROVAL_IS_PENDING: 'USER_APPROVAL_IS_PENDING',
  UNAUTHENTICATED: 'UNAUTHENTICATED',
  PRIVATE: 'PRIVATE',
  ARCHIVED: 'ARCHIVED',
};
