import { graphQLQuery } from './api';

export function recordPageLoadApi() {
  const mutationString = `
    mutation {
      recordPageLoad
    }`;

  const variables = { };

  return graphQLQuery(mutationString, variables);
}
