import { graphQLQuery } from './api';

export function autocompleteGeneralApi(partialSearchTerm) {
  const queryString = `
  query ($partialSearchTerm: String!) {
    autocompleteSearchTerm(partialSearchTerm: $partialSearchTerm) {
      searchTerm
      searchType
      searchItemId
    }
  }`;
  const variables = {
    partialSearchTerm,
  };

  return graphQLQuery(queryString, variables);
}

export function autocompleteLocationApi(partialSearchTerm) {
  const queryString = `
  query ($partialSearchTerm: String!) {
    autocompleteLocation(partialSearchTerm: $partialSearchTerm) {
      searchTerm
      searchType
      searchItemId
    }
  }`;
  const variables = {
    partialSearchTerm,
  };

  return graphQLQuery(queryString, variables);
}

export function searchQueryViewportApi(searchQuery, searchFilter) {
  const queryString = `
  query ($searchQuery: SearchQueryInput!, $searchFilter: SearchFilterInput) {
    searchProjects(searchQuery: $searchQuery, searchFilter: $searchFilter) {
      viewport {
        northeast { latitude longitude }
        southwest { latitude longitude }
      }
      country { code }
      stateOrRegion { abbreviation }
      projectResults {
        item {
          id
          ... on ProjectDistribution {
            redactions { reason redactions { path type } }
          }
          name
          logo { id url }
          address { fullDescription }
          geocoordinates { latitude longitude }
          seller { id name logo { id url } recentActiveDate }
          images { id url }
          metadata {
            lastModifiedDate
          }
        }
      }
    }
  }`;
  const variables = {
    searchQuery,
    searchFilter,
  };

  return graphQLQuery(queryString, variables);
}

export function searchQueryViewportStockStatisticsApi(searchQuery, searchFilter) {
  const queryString = `
  query ($searchQuery: SearchQueryInput!, $searchFilter: SearchFilterInput) {
    searchProjects(searchQuery: $searchQuery, searchFilter: $searchFilter) {
      viewport {
        northeast { latitude longitude }
        southwest { latitude longitude }
      }
      country { code }
      stateOrRegion { abbreviation }
      projectResults {
        item {
          id
          ... on ProjectDistribution {
            redactions { reason redactions { path type } }
          }
          geocoordinates { latitude longitude }
          stockCount
          stockStatistics {
            count
            stockTypes
            priceRange { minValue maxValue type }
            bedroomsRange { minValue maxValue }
            bathroomsRange { minValue maxValue }
            carparksRange { minValue maxValue }
            agentCommission {
              fixedValue { minValue maxValue type }
              percentValue { minValue maxValue }
            }
          }
        }
      }
    }
  }`;
  const variables = {
    searchQuery,
    searchFilter,
  };

  return graphQLQuery(queryString, variables);
}

export function projectSearchApi(searchQuery, searchFilter) {
  const queryString = `
  query ($searchQuery: SearchQueryInput!, $searchFilter: SearchFilterInput) {
    searchProjects(searchQuery: $searchQuery, searchFilter: $searchFilter) {
      viewport {
        northeast { latitude longitude }
        southwest { latitude longitude }
      }
      country { code }
      stateOrRegion { abbreviation }
      projectResults {
        item {
          id
          ... on ProjectDistribution {
            redactions { reason redactions { path type } }
          }
          name
          logo { id url }
          address { fullDescription }
          geocoordinates { latitude longitude }
          seller { id name logo { id url } recentActiveDate }
          images { id url }
          metadata {
            lastModifiedDate
          }
        }
      }
    }
  }`;
  const variables = {
    searchQuery,
    searchFilter,
  };

  return graphQLQuery(queryString, variables);
}

export function projectSearchStockStatisticsApi(searchQuery, searchFilter) {
  const queryString = `
  query ($searchQuery: SearchQueryInput!, $searchFilter: SearchFilterInput) {
    searchProjects(searchQuery: $searchQuery, searchFilter: $searchFilter) {
      viewport {
        northeast { latitude longitude }
        southwest { latitude longitude }
      }
      country { code }
      stateOrRegion { abbreviation }
      projectResults {
        item {
          id
          ... on ProjectDistribution {
            redactions { reason redactions { path type } }
          }
          geocoordinates { latitude longitude }
          stockCount
          stockStatistics {
            count
            stockTypes
            priceRange { minValue maxValue type }
            bedroomsRange { minValue maxValue }
            bathroomsRange { minValue maxValue }
            carparksRange { minValue maxValue }
            agentCommission {
              fixedValue { minValue maxValue type }
              percentValue { minValue maxValue }
            }
          }
        }
      }
    }
  }`;
  const variables = {
    searchQuery,
    searchFilter,
  };

  return graphQLQuery(queryString, variables);
}

export function locationSearchApi(location) {
  const queryString = `
  query ($path: String!) {
    publicProjectDistributionsByRegion(path: $path) {
      viewport {
        northeast { latitude longitude }
        southwest { latitude longitude }
      }
      region { id }
      projectResults {
        item {
          id
          ... on ProjectDistribution {
            redactions { reason redactions { path type } }
          }
          name
          logo { id url }
          address { fullDescription }
          geocoordinates { latitude longitude }
          seller { id name logo { id url } recentActiveDate }
          images { id url }
          metadata {
            lastModifiedDate
          }
        }
      }
    }
  }`;
  const variables = {
    path: location,
  };

  return graphQLQuery(queryString, variables);
}

export function locationSearchStockStatisticsApi(location) {
  const queryString = `
  query ($path: String!) {
    publicProjectDistributionsByRegion(path: $path) {
      viewport {
        northeast { latitude longitude }
        southwest { latitude longitude }
      }
      region { id }
      projectResults {
        item {
          id
          ... on ProjectDistribution {
            redactions { reason redactions { path type } }
          }
          geocoordinates { latitude longitude }
          stockCount
          stockStatistics {
            count
            stockTypes
            priceRange { minValue maxValue type }
            bedroomsRange { minValue maxValue }
            bathroomsRange { minValue maxValue }
            carparksRange { minValue maxValue }
            agentCommission {
              fixedValue { minValue maxValue type }
              percentValue { minValue maxValue }
            }
          }
        }
      }
    }
  }`;
  const variables = {
    path: location,
  };

  return graphQLQuery(queryString, variables);
}

export function viewportSearchApi(
  viewport,
  transform = null,
  excludedViewport = null,
  searchFilter = null,
) {
  const queryString = `
  query ($viewport: ViewportInput!, $transform: ViewportTransformInput, $excludedViewport: ViewportInput, $searchFilter: SearchFilterInput) {
    searchProjectsByViewport(viewport: $viewport, transform: $transform, excludedViewport: $excludedViewport, searchFilter: $searchFilter) {
      viewport {
        northeast { latitude longitude }
        southwest { latitude longitude }
      }
      country { code }
      stateOrRegion { abbreviation }
      projectResults {
        item {
          id
          ... on ProjectDistribution {
            redactions { reason redactions { path type } }
          }
          name
          logo { id url }
          address { fullDescription }
          geocoordinates { latitude longitude }
          seller { id name logo { id url } recentActiveDate }
          images { id url }
          metadata {
            lastModifiedDate
          }
        }
      }
    }
  }`;
  const variables = {
    viewport,
    transform,
    excludedViewport,
    searchFilter,
  };

  return graphQLQuery(queryString, variables);
}

export function viewportSearchStockStatisticsApi(
  viewport,
  transform = null,
  excludedViewport = null,
  searchFilter = null,
) {
  const queryString = `
  query ($viewport: ViewportInput!, $transform: ViewportTransformInput, $excludedViewport: ViewportInput, $searchFilter: SearchFilterInput) {
    searchProjectsByViewport(viewport: $viewport, transform: $transform, excludedViewport: $excludedViewport, searchFilter: $searchFilter) {
      viewport {
        northeast { latitude longitude }
        southwest { latitude longitude }
      }
      country { code }
      stateOrRegion { abbreviation }
      projectResults {
        item {
          id
          ... on ProjectDistribution {
            redactions { reason redactions { path type } }
          }
          geocoordinates { latitude longitude }
          stockCount
          stockStatistics {
            count
            stockTypes
            priceRange { minValue maxValue type }
            bedroomsRange { minValue maxValue }
            bathroomsRange { minValue maxValue }
            carparksRange { minValue maxValue }
            agentCommission {
              fixedValue { minValue maxValue type }
              percentValue { minValue maxValue }
            }
          }
        }
      }
    }
  }`;
  const variables = {
    viewport,
    transform,
    excludedViewport,
    searchFilter,
  };

  return graphQLQuery(queryString, variables);
}

export function allProjectsApi() {
  const queryString = `
  query {
    allProjects {
      viewport {
        northeast { latitude longitude }
        southwest { latitude longitude }
      }
      country { code }
      stateOrRegion { abbreviation }
      projectResults {
        item {
          id
          ... on ProjectDistribution {
            redactions { reason redactions { path type } }
          }
          name
          logo { id url }
          address { fullDescription }
          geocoordinates { latitude longitude }
          seller { id name logo { id url } recentActiveDate }
          images { id url }
          metadata {
            lastModifiedDate
          }
        }
      }
    }
  }`;
  const variables = {
  };

  return graphQLQuery(queryString, variables);
}

export function allProjectsStockStatisticsApi() {
  const queryString = `
  query {
    allProjects {
      viewport {
        northeast { latitude longitude }
        southwest { latitude longitude }
      }
      country { code }
      stateOrRegion { abbreviation }
      projectResults {
        item {
          id
          ... on ProjectDistribution {
            redactions { reason redactions { path type } }
          }
          geocoordinates { latitude longitude }
          stockCount
          stockStatistics {
            count
            stockTypes
            priceRange { minValue maxValue type }
            bedroomsRange { minValue maxValue }
            bathroomsRange { minValue maxValue }
            carparksRange { minValue maxValue }
            agentCommission {
              fixedValue { minValue maxValue type }
              percentValue { minValue maxValue }
            }
          }
        }
      }
    }
  }`;
  const variables = {
  };

  return graphQLQuery(queryString, variables);
}
