export const PERMISSION = {
  CAN_BUILD_BROCHURE: 'CAN_BUILD_BROCHURE',
  CAN_DO_NON_PUBLIC_ENQUIRY_ON_PROJECT: 'CAN_DO_NON_PUBLIC_ENQUIRY_ON_PROJECT',
  CAN_DO_NON_PUBLIC_ENQUIRY_ON_STOCK: 'CAN_DO_NON_PUBLIC_ENQUIRY_ON_STOCK',
  CAN_FOLLOW: 'CAN_FOLLOW',
  CAN_MANAGE_PORTALS: 'PORTAL_MANAGEMENT',
  CAN_CREATE_PORTALS: 'PORTAL_CREATION',
  CAN_RECORD_PROJECT_VIEW: 'CAN_RECORD_PROJECT_VIEW',
  CAN_RECORD_STOCK_VIEW: 'CAN_RECORD_STOCK_VIEW',
  CAN_RESERVE: 'CAN_RESERVE',
  CAN_SEARCH_BY_SELLER: 'CAN_SEARCH_BY_SELLER',
  CAN_SEE_CONTACTS_PAGE: 'CAN_SEE_CONTACTS_PAGE',
  CAN_SEE_ONBOARDING: 'CAN_SEE_ONBOARDING',
  CAN_SEE_TERMS_AND_CONDITIONS: 'CAN_SEE_TERMS_AND_CONDITIONS',
  CAN_SHARE_PROJECT_URL: 'CAN_SHARE_PROJECT_URL',
  CAN_SHARE_STOCK_URL: 'CAN_SHARE_STOCK_URL',
  CAN_ADMINISTER_OWN_PROJECT_VIDEOS: 'CAN_ADMINISTER_OWN_PROJECT_VIDEOS',
  CAN_ADMINISTER_ALL_PROJECT_VIDEOS: 'CAN_ADMINISTER_ALL_PROJECT_VIDEOS',
};

export const hasPermission = (loggedInUser, permission) => Boolean(loggedInUser
   && loggedInUser.permissions.includes(permission));

export const hasPermissions = (loggedInUser, permissions) => Boolean(loggedInUser
  && permissions.every((p) => loggedInUser.permissions.indexOf(p) !== -1));
