import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';
import Icon, { ICONS } from 'components/Icon/Icon';
import { Translate } from 'react-localize-redux';
import { isMobile } from 'react-device-detect';
import { sendTrialUpgradeRequestThunk, resetTrialPopUpShownDate as resetTrialPopUpShownDateAction } from 'components/LoggedInUser/LoggedInUserActions';
import {
  getTrialUpgradeResult,
  getTrialUpgradeRequestLoading,
  getTrialPopUpShownDate,
} from 'components/LoggedInUser/LoggedInUserSelectors';
import '../Trial.scss';

class TrialExpiredBanner extends Component {
  static getDerivedStateFromProps({
    resetTrialPopUpShownDate, trialPopUpShownDate, trialUpgradeResult
  }) {
    if (trialUpgradeResult && trialPopUpShownDate !== '') {
      resetTrialPopUpShownDate();
    }

    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      disableButton: false,
    };
    this.onClickTrialUpgrade = this.onClickTrialUpgrade.bind(this);
  }

  onClickTrialUpgrade() {
    const {
      loggedInUser,
      resetTrialPopUpShownDate,
      sendTrialUpgrageRequest,
      trialUpgradeRequestLoading
    } = this.props;

    if (loggedInUser.subscription.upgradeRequested) {
      resetTrialPopUpShownDate();
    } else {
      this.setState({
        disableButton: trialUpgradeRequestLoading,
      });
      sendTrialUpgrageRequest();
    }
  }

  render() {
    const {
      loggedInUser,
      trialUpgradeResult
    } = this.props;

    const { disableButton } = this.state;

    if (
      loggedInUser
      && loggedInUser.subscription
      && loggedInUser.subscription.type === 'EXPIRED_TRIAL'
      && !(trialUpgradeResult && trialUpgradeResult.succeeded)
    ) {
      return (
        <div className="trialExpiredBannerWrap">
          <div className="trialExpiredBanner">
            <Row>
              {
                !isMobile
                && (
                  <Col sm={1} className="bannerIcon">
                    <Icon icon={ICONS.exclamation} colour="#FFF" size={25} />
                  </Col>
                )
              }
              <Col xs={8} className="bannerText">
                <p>
                  <Translate id="trial.bannerText" />
                </p>
              </Col>
              <Col xs={4} sm={3} className="noPaddingLeft bannerBtnWrapper">
                <Button
                  className="bannerBtn"
                  disabled={disableButton}
                  onClick={() => { this.onClickTrialUpgrade(); }}>
                    <Translate id="trial.buttonLabel" />
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      );
    }
    return null;
  }
}

TrialExpiredBanner.defaultProps = {
  trialUpgradeResult: null,
  loggedInUser: null,
};

TrialExpiredBanner.propTypes = {
  loggedInUser: PropTypes.object,
  trialUpgradeResult: PropTypes.object,
  sendTrialUpgrageRequest: PropTypes.func.isRequired,
  trialUpgradeRequestLoading: PropTypes.bool.isRequired,
  resetTrialPopUpShownDate: PropTypes.func.isRequired,
  trialPopUpShownDate: PropTypes.string.isRequired, // eslint-disable-line
};

function mapStateToProps(state) {
  return {
    trialUpgradeResult: getTrialUpgradeResult(state),
    trialUpgradeRequestLoading: getTrialUpgradeRequestLoading(state),
    trialPopUpShownDate: getTrialPopUpShownDate(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sendTrialUpgrageRequest: () => dispatch(sendTrialUpgradeRequestThunk()),
    resetTrialPopUpShownDate: () => dispatch(resetTrialPopUpShownDateAction()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TrialExpiredBanner);
