import NoConnectionError from 'services/NoConnectionError';
import TimeoutError from 'services/TimeoutError';

import {
  searchQueryViewportApi,
  searchQueryViewportStockStatisticsApi,
  projectSearchApi,
  projectSearchStockStatisticsApi,
  viewportSearchApi,
  viewportSearchStockStatisticsApi,
  locationSearchApi,
  locationSearchStockStatisticsApi,
  allProjectsApi,
  allProjectsStockStatisticsApi,
} from 'services/search.api';
import { cacheSearchResults } from 'cache/CacheActions';
import { history } from 'utility/history';
import { loadFeaturedProjectsThunk } from 'components/ProjectPage/ProjectActions';
import { getSearchQuery, getSearchFilters } from 'components/Search/SearchSelectors';

export const SUBMIT_SEARCH = 'SUBMIT_SEARCH';

export const SUBMIT_SEARCH_QUERY_VIEWPORT = 'SUBMIT_SEARCH_QUERY_VIEWPORT';
export const SEARCH_QUERY_VIEWPORT_RESULTS = 'SEARCH_QUERY_VIEWPORT_RESULTS';
export const SEARCH_QUERY_VIEWPORT_FAILURE = 'SEARCH_QUERY_VIEWPORT_FAILURE';

export const SUBMIT_PROJECT_SEARCH = 'SUBMIT_PROJECT_SEARCH';
export const PROJECT_SEARCH_RESULTS = 'PROJECT_SEARCH_RESULTS';
export const PROJECT_SEARCH_FAILURE = 'PROJECT_SEARCH_FAILURE';

export const SUBMIT_VIEWPORT_SEARCH = 'SUBMIT_VIEWPORT_SEARCH';
export const VIEWPORT_SEARCH_RESULTS = 'VIEWPORT_SEARCH_RESULTS';
export const VIEWPORT_SEARCH_FAILURE = 'VIEWPORT_SEARCH_FAILURE';

export const SUBMIT_LOCATION_SEARCH = 'SUBMIT_LOCATION_SEARCH';
export const LOCATION_SEARCH_RESULTS = 'LOCATION_SEARCH_RESULTS';
export const LOCATION_SEARCH_FAILURE = 'LOCATION_SEARCH_FAILURE';

export const FETCH_ALL_PROJECTS = 'FETCH_ALL_PROJECTS';
export const FETCH_ALL_PROJECTS_SUCCESS = 'FETCH_ALL_PROJECTS_SUCCESS';
export const FETCH_ALL_PROJECTS_FAILURE = 'FETCH_ALL_PROJECTS_FAILURE';

export const SET_CURRENT_MAP_VIEWPORT = 'SET_CURRENT_MAP_VIEWPORT';

export const CLEAR_SEARCH_DATA = 'CLEAR_SEARCH_DATA';

export const START_HOVER_SEARCH_RESULT = 'START_HOVER_SEARCH_RESULT';
export const END_HOVER_SEARCH_RESULT = 'END_HOVER_SEARCH_RESULT';

// Search actions
export function submitSearch(searchQuery, searchFilters) {
  return {
    type: SUBMIT_SEARCH,
    searchQuery,
    searchFilters
  };
}

export function submitSearchQueryViewport() {
  return {
    type: SUBMIT_SEARCH_QUERY_VIEWPORT
  };
}

export function searchQueryViewportResults(results) {
  return {
    type: SEARCH_QUERY_VIEWPORT_RESULTS,
    results,
  };
}

export function searchQueryViewportFailure(error) {
  return {
    type: SEARCH_QUERY_VIEWPORT_FAILURE,
    error,
  };
}

export function submitProjectSearch() {
  return {
    type: SUBMIT_PROJECT_SEARCH
  };
}

export function projectSearchResults(results) {
  return {
    type: PROJECT_SEARCH_RESULTS,
    results,
  };
}

export function projectSearchFailure(error) {
  return {
    type: PROJECT_SEARCH_FAILURE,
    error,
  };
}

export function submitViewportSearch(viewport, excludedViewport) {
  return {
    type: SUBMIT_VIEWPORT_SEARCH,
    viewport,
    excludedViewport,
  };
}

export function viewportSearchResults(results) {
  return {
    type: VIEWPORT_SEARCH_RESULTS,
    results,
  };
}

export function viewportSearchFailure(error) {
  return {
    type: VIEWPORT_SEARCH_FAILURE,
    error,
  };
}

export function submitLocationSearch(location) {
  return {
    type: SUBMIT_LOCATION_SEARCH,
    location,
  };
}

export function locationSearchResults(results) {
  return {
    type: LOCATION_SEARCH_RESULTS,
    results,
  };
}

export function locationSearchFailure(error) {
  return {
    type: LOCATION_SEARCH_FAILURE,
    error,
  };
}

export function fetchAllProjects() {
  return {
    type: FETCH_ALL_PROJECTS,
  };
}

export function fetchAllProjectsSuccess(results) {
  return {
    type: FETCH_ALL_PROJECTS_SUCCESS,
    results,
  };
}

export function fetchAllProjectsFailure(error) {
  return {
    type: FETCH_ALL_PROJECTS,
    error,
  };
}

export function setCurrentMapViewport(viewport) {
  return {
    type: SET_CURRENT_MAP_VIEWPORT,
    viewport,
  };
}

export function clearSearchData() {
  return {
    type: CLEAR_SEARCH_DATA,
  };
}

export function startHoverSearchResult(id) {
  return {
    type: START_HOVER_SEARCH_RESULT,
    id,
  };
}

export function endHoverSearchResult() {
  return {
    type: END_HOVER_SEARCH_RESULT,
  };
}

// Thunks
export function searchQueryViewportThunk() {
  return (dispatch, getState) => {
    const state = getState();
    const searchQuery = getSearchQuery(state);
    const searchFilters = getSearchFilters(state);

    dispatch(loadFeaturedProjectsThunk()); // update the featured project data
    dispatch(submitSearchQueryViewport());
    searchQueryViewportApi(searchQuery, searchFilters)
      .then((result) => {
        if (result.error) {
          dispatch(searchQueryViewportFailure(new Error(result.error)));
        } else {
          dispatch(cacheSearchResults(result.data.searchProjects.projectResults));
          dispatch(searchQueryViewportResults(result.data.searchProjects));
        }
      }, (error) => {
        if (error instanceof TimeoutError || error instanceof NoConnectionError) {
          if (history.location.pathname !== '/neo/Status/ConnectionIssue') {
            history.push('/neo/Status/ConnectionIssue');
          }
        } else {
          dispatch(searchQueryViewportFailure(error));
        }
      });
    searchQueryViewportStockStatisticsApi(searchQuery, searchFilters)
      .then((result) => {
        if (result.error) {
          dispatch(searchQueryViewportFailure(new Error(result.error)));
        } else {
          dispatch(cacheSearchResults(result.data.searchProjects.projectResults));
        }
      }, (error) => {
        dispatch(searchQueryViewportFailure(error));
      });
  };
}

export function projectSearchThunk() {
  return (dispatch, getState) => {
    const state = getState();
    const searchQuery = getSearchQuery(state);
    const searchFilters = getSearchFilters(state);

    dispatch(loadFeaturedProjectsThunk());
    dispatch(submitProjectSearch());
    projectSearchApi(searchQuery, searchFilters)
      .then((result) => {
        if (result.error) {
          dispatch(projectSearchFailure(new Error(result.error)));
        } else {
          dispatch(cacheSearchResults(result.data.searchProjects.projectResults));
          dispatch(projectSearchResults(result.data.searchProjects));
        }
      }, (error) => {
        if (error instanceof TimeoutError || error instanceof NoConnectionError) {
          if (history.location.pathname !== '/neo/Status/ConnectionIssue') {
            history.push('/neo/Status/ConnectionIssue');
          }
        } else {
          dispatch(projectSearchFailure(error));
        }
      });
    projectSearchStockStatisticsApi(searchQuery, searchFilters)
      .then((result) => {
        if (result.error) {
          dispatch(projectSearchFailure(new Error(result.error)));
        } else {
          dispatch(cacheSearchResults(result.data.searchProjects.projectResults));
        }
      }, (error) => {
        dispatch(projectSearchFailure(error));
      });
  };
}

export function viewportSearchThunk(viewport, excludedViewport) {
  return (dispatch, getState) => {
    const searchFilters = getSearchFilters(getState());
    dispatch(submitViewportSearch(viewport, excludedViewport));
    viewportSearchApi(viewport, null, excludedViewport, searchFilters)
      .then((result) => {
        if (result.error) {
          dispatch(viewportSearchFailure(new Error(result.error)));
        } else {
          dispatch(cacheSearchResults(result.data.searchProjectsByViewport.projectResults));
          dispatch(viewportSearchResults(result.data.searchProjectsByViewport));
        }
      }, (error) => {
        if (error instanceof TimeoutError || error instanceof NoConnectionError) {
          if (history.location.pathname !== '/neo/Status/ConnectionIssue') {
            history.push('/neo/Status/ConnectionIssue');
          }
        } else {
          dispatch(viewportSearchFailure(error));
        }
      });
    viewportSearchStockStatisticsApi(viewport, null, excludedViewport, searchFilters)
      .then((result) => {
        if (result.error) {
          dispatch(viewportSearchFailure(new Error(result.error)));
        } else {
          dispatch(cacheSearchResults(result.data.searchProjectsByViewport.projectResults));
        }
      }, (error) => {
        dispatch(viewportSearchFailure(error));
      });
  };
}

export function locationSearchThunk(location) {
  return (dispatch) => {
    dispatch(submitLocationSearch(location));
    locationSearchApi(location)
      .then((result) => {
        if (result.error) {
          dispatch(locationSearchFailure(new Error(result.error)));
        } else {
          dispatch(cacheSearchResults(
            result.data.publicProjectDistributionsByRegion.projectResults));
          dispatch(locationSearchResults(result.data.publicProjectDistributionsByRegion));
        }
      }, (error) => {
        if (error instanceof TimeoutError || error instanceof NoConnectionError) {
          if (history.location.pathname !== '/neo/Status/ConnectionIssue') {
            history.push('/neo/Status/ConnectionIssue');
          }
        } else {
          dispatch(locationSearchFailure(error));
        }
      });
    locationSearchStockStatisticsApi(location)
      .then((result) => {
        if (result.error) {
          dispatch(locationSearchFailure(new Error(result.error)));
        } else {
          dispatch(cacheSearchResults(
            result.data.publicProjectDistributionsByRegion.projectResults));
        }
      }, (error) => {
        dispatch(locationSearchFailure(error));
      });
  };
}

export function fetchAllProjectsThunk() {
  return (dispatch) => {
    dispatch(fetchAllProjects());
    allProjectsApi()
      .then((result) => {
        if (result.error) {
          dispatch(fetchAllProjectsFailure(result.error));
        } else {
          dispatch(cacheSearchResults(result.data.allProjects.projectResults));
          dispatch(fetchAllProjectsSuccess(result.data.allProjects));
        }
      }, (error) => {
        if (error instanceof TimeoutError || error instanceof NoConnectionError) {
          if (history.location.pathname !== '/neo/Status/ConnectionIssue') {
            history.push('/neo/Status/ConnectionIssue');
          }
        } else {
          dispatch(fetchAllProjectsFailure(error));
        }
      });
    allProjectsStockStatisticsApi()
      .then((result) => {
        if (result.error) {
          dispatch(fetchAllProjectsFailure(result.error));
        } else {
          dispatch(cacheSearchResults(result.data.allProjects.projectResults));
        }
      }, (error) => {
        dispatch(fetchAllProjectsFailure(error));
      });
  };
}
