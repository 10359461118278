import { SET_DASHBOARD_TIME_RANGE_FILTER, SET_AGENT_PROFILE_TIME_RANGE_FILTER } from './UserSettingsActions';

function setDashboardTimeRangeFilter(state, action) {
  return {
    ...state,
    dashboardTimeRangeFilter: action.timeRangeFilter,
  };
}

function setAgentProfileTimeRangeFilter(state, action) {
  return {
    ...state,
    agentProfileTimeRangeFilter: action.timeRangeFilter,
  };
}

const initialState = {
  dashboardTimeRangeFilter: {
    stepType: 'DAY',
    stepsBack: 30,
  },
  agentProfileTimeRangeFilter: {
    stepType: 'DAY',
    stepsBack: 30,
  },
};

export default function UserSettingsReducers(state = initialState, action) {
  switch (action.type) {
    case SET_DASHBOARD_TIME_RANGE_FILTER:
      return setDashboardTimeRangeFilter(state, action);
    case SET_AGENT_PROFILE_TIME_RANGE_FILTER:
      return setAgentProfileTimeRangeFilter(state, action);

    default:
      return state;
  }
}
