import ApiError from 'services/ApiError';
import HttpResponseError from 'services/HttpResponseError';
import NoConnectionError from 'services/NoConnectionError';
import TimeoutError from 'services/TimeoutError';
import {
  loadProjectDetailsApi,
  loadProjectRedactionsApi,
  loadProjectImagesApi,
  loadProjectSellerApi,
  loadProjectDocumentsListApi,
  loadProjectStockListApi,
  loadProjectVideosApi,
  sendRegistrationOfInterestRequestApi,
  loadFeaturedProjectsApi,
  loadRecentlyUpdatedProjectsApi,
  loadProjectOverviewApi,
} from 'services/project.api';

import {
  cacheProject,
  cacheFeaturedProjects,
  cacheRecentlyUpdatedProjects
} from 'cache/CacheActions';

import { translate } from 'utility/utility';
import { showAlertPopup } from 'components/AlertPopup/AlertPopupActions';
import { history } from 'utility/history';

export const REQUEST_REGISTRATION_OF_INTEREST_SUCCESS = 'REQUEST_REGISTRATION_OF_INTEREST_SUCCESS';
export const REQUEST_REGISTRATION_OF_INTEREST_FAILURE = 'REQUEST_REGISTRATION_OF_INTEREST_FAILURE';

export const FETCH_FEATURED_PROJECTS = 'FETCH_FEATURED_PROJECTS';
export const FETCH_FEATURED_PROJECTS_SUCCESS = 'FETCH_FEATURED_PROJECTS_SUCCESS';
export const FETCH_FEATURED_PROJECTS_FAILURE = 'FETCH_FEATURED_PROJECTS_FAILURE';

export const FETCH_RECENTLY_UPDATED_PROJECTS = 'FETCH_RECENTLY_UPDATED_PROJECTS';
export const FETCH_RECENTLY_UPDATED_PROJECTS_SUCCESS = 'FETCH_RECENTLY_UPDATED_PROJECTS_SUCCESS';
export const FETCH_RECENTLY_UPDATED_PROJECTS_FAILURE = 'FETCH_RECENTLY_UPDATED_PROJECTS_FAILURE';

// Fetch project details actions
export function fetchProjectDetails(projectId) {
  return cacheProject({ id: projectId, loadingDetails: true, detailsError: null });
}

export function fetchProjectDetailsSuccess(projectId) {
  return cacheProject({ id: projectId, loadingDetails: false });
}

export function fetchProjectDetailsFailure(projectId, error) {
  return cacheProject({ id: projectId, loadingDetails: false, detailsError: error });
}

// Fetch project images actions
export function fetchProjectImages(projectId) {
  return cacheProject({ id: projectId, loadingImages: true, imagesError: null });
}

export function fetchProjectImagesSuccess(projectId) {
  return cacheProject({ id: projectId, loadingImages: false });
}

export function fetchProjectImagesFailure(projectId, error) {
  return cacheProject({ id: projectId, loadingImages: false, imagesError: error });
}

// Fetch project seller actions
export function fetchProjectSeller(projectId) {
  return cacheProject({ id: projectId, loadingSeller: true, sellerError: null });
}

export function fetchProjectSellerSuccess(projectId) {
  return cacheProject({ id: projectId, loadingSeller: false });
}

export function fetchProjectSellerFailure(projectId, error) {
  return cacheProject({ id: projectId, loadingSeller: false, sellerError: error });
}

// Fetch project documents list actions
export function fetchProjectDocumentsList(projectId) {
  return cacheProject({ id: projectId, loadingDocuments: true, documentsError: null });
}

export function fetchProjectDocumentsListSuccess(projectId) {
  return cacheProject({ id: projectId, loadingDocuments: false });
}

export function fetchProjectDocumentsListFailure(projectId, error) {
  return cacheProject({ id: projectId, loadingDocuments: false, documentsError: error });
}

// Fetch project stock list actions
export function fetchProjectStockList(projectId) {
  return cacheProject({ id: projectId, loadingStocks: true, stocksError: null });
}

export function fetchProjectStockListSuccess(projectId) {
  return cacheProject({ id: projectId, loadingStocks: false });
}

export function fetchProjectStockListFailure(projectId, error) {
  return cacheProject({ id: projectId, loadingStocks: false, stocksError: error });
}

// Fetch project videos actions
export function fetchProjectVideos(projectId) {
  return cacheProject({ id: projectId, loadingVideos: true, videosError: null });
}

export function fetchProjectVideosSuccess(projectId) {
  return cacheProject({ id: projectId, loadingVideos: false });
}

export function fetchProjectVideosFailure(projectId, error) {
  return cacheProject({ id: projectId, loadingVideos: false, videosError: error });
}

export function requestRegistrationOfInterestSuccess() {
  return {
    type: REQUEST_REGISTRATION_OF_INTEREST_SUCCESS,
  };
}

export function requestRegistrationOfInterestFailure() {
  return {
    type: REQUEST_REGISTRATION_OF_INTEREST_FAILURE,
  };
}

export function fetchFeaturedProjects() {
  return {
    type: FETCH_FEATURED_PROJECTS,
  };
}

export function fetchFeaturedProjectsSuccess() {
  return {
    type: FETCH_FEATURED_PROJECTS_SUCCESS,
  };
}

export function fetchFeaturedProjectsFailure(error) {
  return {
    type: FETCH_FEATURED_PROJECTS_FAILURE,
    error,
  };
}

export function fetchRecentlyUpdatedProjects() {
  return {
    type: FETCH_RECENTLY_UPDATED_PROJECTS,
  };
}

export function fetchRecentlyUpdatedProjectsSuccess(results) {
  return {
    type: FETCH_RECENTLY_UPDATED_PROJECTS_SUCCESS,
    results,
  };
}

export function fetchRecentlyUpdatedProjectsFailure(error) {
  return {
    type: FETCH_RECENTLY_UPDATED_PROJECTS_FAILURE,
    error,
  };
}

export function fetchProjectOverview(projectId) {
  return cacheProject({ id: projectId, loadingOverview: true, overviewError: null });
}

export function fetchProjectOverviewSuccess(projectId) {
  return cacheProject({ id: projectId, loadingOverview: false });
}

export function fetchProjectOverviewFailure(error, projectId) {
  return cacheProject({ id: projectId, loadingOverview: false, overviewError: error });
}

// Thunks
export function fetchProjectDetailsThunk(projectId) {
  return (dispatch) => {
    dispatch(fetchProjectDetails(projectId));
    return loadProjectDetailsApi(projectId)
      .catch((error) => {
        if (error instanceof HttpResponseError) {
          return error.response.json();
        }
        throw error;
      })
      .then((result) => {
        if (result.errors) {
          throw new ApiError(result.errors);
        }
        return result;
      })
      .then((result) => {
        const { project } = result.data;
        dispatch(cacheProject(project));
        dispatch(fetchProjectDetailsSuccess(projectId));
        return project;
      })
      .catch((error) => {
        if (error instanceof TimeoutError || error instanceof NoConnectionError) {
          if (history.location.pathname !== '/neo/Status/ConnectionIssue') {
            history.push('/neo/Status/ConnectionIssue');
          }
        } else {
          dispatch(fetchProjectDetailsFailure(projectId, error));
          if (error instanceof ApiError) {
            throw error;
          }
        }
      });
  };
}

export function fetchProjectImagesThunk(projectId) {
  return (dispatch) => {
    dispatch(fetchProjectImages(projectId));
    return loadProjectImagesApi(projectId)
      .catch((error) => {
        if (error instanceof HttpResponseError) {
          return error.response.json();
        }
        throw error;
      })
      .then((result) => {
        if (result.errors) {
          throw new ApiError(result.errors);
        }
        return result;
      })
      .then((result) => {
        const { project } = result.data;
        dispatch(cacheProject(project));
        dispatch(fetchProjectImagesSuccess(projectId));
        return project;
      })
      .catch((error) => {
        if (error instanceof TimeoutError || error instanceof NoConnectionError) {
          if (history.location.pathname !== '/neo/Status/ConnectionIssue') {
            history.push('/neo/Status/ConnectionIssue');
          }
        } else {
          dispatch(fetchProjectImagesFailure(projectId, error));
          if (error instanceof ApiError) {
            throw error;
          }
        }
      });
  };
}

export function fetchProjectSellerThunk(projectId) {
  return (dispatch) => {
    dispatch(fetchProjectSeller(projectId));
    return loadProjectSellerApi(projectId)
      .catch((error) => {
        if (error instanceof HttpResponseError) {
          return error.response.json();
        }
        throw error;
      })
      .then((result) => {
        if (result.errors) {
          throw new ApiError(result.errors);
        }
        return result;
      })
      .then((result) => {
        const { project } = result.data;
        dispatch(cacheProject(project));
        dispatch(fetchProjectSellerSuccess(projectId));
        return project;
      })
      .catch((error) => {
        if (error instanceof TimeoutError || error instanceof NoConnectionError) {
          if (history.location.pathname !== '/neo/Status/ConnectionIssue') {
            history.push('/neo/Status/ConnectionIssue');
          }
        } else {
          dispatch(fetchProjectSellerFailure(projectId, error));
          if (error instanceof ApiError) {
            throw error;
          }
        }
      });
  };
}

export function fetchProjectDocumentsThunk(projectId) {
  return (dispatch) => {
    dispatch(fetchProjectDocumentsList(projectId));
    return loadProjectDocumentsListApi(projectId)
      .catch((error) => {
        if (error instanceof HttpResponseError) {
          return error.response.json();
        }
        throw error;
      })
      .then((result) => {
        if (result.errors) {
          throw new ApiError(result.errors);
        }
        return result;
      })
      .then((result) => {
        const { project } = result.data;
        dispatch(cacheProject(project));
        dispatch(fetchProjectDocumentsListSuccess(projectId));
        return project;
      })
      .catch((error) => {
        if (error instanceof TimeoutError || error instanceof NoConnectionError) {
          if (history.location.pathname !== '/neo/Status/ConnectionIssue') {
            history.push('/neo/Status/ConnectionIssue');
          }
        } else {
          dispatch(fetchProjectDocumentsListFailure(projectId, error));
          if (error instanceof ApiError) {
            throw error;
          }
        }
      });
  };
}

export function fetchProjectStockListThunk(projectId) {
  return (dispatch) => {
    dispatch(fetchProjectStockList(projectId));
    loadProjectStockListApi(projectId)
      .catch((error) => {
        if (error instanceof HttpResponseError) {
          return error.response.json();
        }
        throw error;
      })
      .then((result) => {
        if (result.errors) {
          throw new Error(result.errors);
        }
        return result;
      })
      .then((result) => {
        dispatch(cacheProject(result.data.project));
        dispatch(fetchProjectStockListSuccess(projectId));
      })
      .catch((error) => {
        if (error instanceof TimeoutError || error instanceof NoConnectionError) {
          if (history.location.pathname !== '/neo/Status/ConnectionIssue') {
            history.push('/neo/Status/ConnectionIssue');
          }
        } else {
          dispatch(fetchProjectStockListFailure(projectId, error));
        }
      });
  };
}

export function fetchProjectVideosThunk(projectId) {
  return (dispatch) => {
    dispatch(fetchProjectVideos(projectId));
    return loadProjectVideosApi(projectId)
      .catch((error) => {
        if (error instanceof HttpResponseError) {
          return error.response.json();
        }
        throw error;
      })
      .then((result) => {
        if (result.errors) {
          throw new Error(result.errors);
        }
        return result;
      })
      .then((result) => {
        dispatch(cacheProject(result.data.project));
        dispatch(fetchProjectVideosSuccess(projectId));
      })
      .catch((error) => {
        dispatch(fetchProjectVideosFailure(projectId, error));
        throw error;
      });
  };
}

export function requestRegistrationOfInterestThunk(projectId, message) {
  return (dispatch) => {
    if (projectId) {
      sendRegistrationOfInterestRequestApi(projectId, message)
        .then((result) => {
          if (result.error) {
            dispatch(requestRegistrationOfInterestFailure(new Error(result.error)));
            dispatch(showAlertPopup('roi-failure'));
          } else if (!result.data.sendRegistrationOfInterestRequest) {
            dispatch(requestRegistrationOfInterestFailure(new Error(translate('common.registrationFailed'))));
            dispatch(showAlertPopup('roi-failure'));
          } else {
            dispatch(requestRegistrationOfInterestSuccess());
            dispatch(showAlertPopup('roi-success'));
            // Update the project redactions
            loadProjectRedactionsApi(projectId)
              .then((redactionsResult) => {
                if (!redactionsResult.error) {
                  dispatch(cacheProject(redactionsResult.data.project));
                }
              });
          }
        }, (error) => {
          dispatch(requestRegistrationOfInterestFailure(error));
          dispatch(showAlertPopup('roi-failure'));
        });
    }
  };
}

export function loadFeaturedProjectsThunk() {
  return (dispatch) => {
    dispatch(fetchFeaturedProjects());
    loadFeaturedProjectsApi()
      .catch((error) => {
        if (error instanceof HttpResponseError) {
          return error.response.json();
        }
        throw error;
      })
      .then((result) => {
        if (result.errors) {
          throw new Error(result.errors);
        }
        return result;
      })
      .then((result) => {
        dispatch(fetchFeaturedProjectsSuccess());
        dispatch(cacheFeaturedProjects(result.data.featuredProjects));
      }, (error) => {
        dispatch(fetchFeaturedProjectsFailure(error));
      })
      .catch((error) => {
        dispatch(fetchFeaturedProjectsFailure(error));
      });
  };
}

export function loadRecentlyUpdatedProjectsThunk(take) {
  return (dispatch) => {
    dispatch(fetchRecentlyUpdatedProjects());
    loadRecentlyUpdatedProjectsApi(take)
      .catch((error) => {
        if (error instanceof HttpResponseError) {
          return error.response.json();
        }
        throw error;
      })
      .then((result) => {
        if (result.errors) {
          throw new Error(result.errors);
        }
        return result;
      })
      .then((result) => {
        dispatch(fetchRecentlyUpdatedProjectsSuccess(result.data.projectsMostRecentlyUpdated));
        dispatch(cacheRecentlyUpdatedProjects(result.data.projectsMostRecentlyUpdated.projectResults)); // eslint-disable-line
      })
      .catch((error) => {
        dispatch(fetchRecentlyUpdatedProjectsFailure(error));
      });
  };
}

export function fetchProjectOverviewThunk(projectId) {
  return (dispatch) => {
    dispatch(fetchProjectOverview(projectId));
    return loadProjectOverviewApi(projectId)
      .catch((error) => {
        if (error instanceof HttpResponseError) {
          return error.response.json();
        }
        throw error;
      })
      .then((result) => {
        if (result.errors) {
          throw new ApiError(result.errors);
        }
        return result;
      })
      .then((result) => {
        const { project } = result.data;
        dispatch(cacheProject(project));
        dispatch(fetchProjectOverviewSuccess(projectId));
        return project;
      })
      .catch((error) => {
        if (error instanceof TimeoutError || error instanceof NoConnectionError) {
          if (history.location.pathname !== '/neo/Status/ConnectionIssue') {
            history.push('/neo/Status/ConnectionIssue');
          }
        } else {
          dispatch(fetchProjectOverviewFailure(projectId, error));
          if (error instanceof ApiError) {
            throw error;
          }
        }
      });
  };
}
