import React from 'react';
import { Route } from 'react-router-dom';

const RefreshRoute = ({ ...passThrough }) => (
  <Route
    component={({ history, location, match }) => {
      history.replace({
        ...location,
        pathname: location.pathname.substring(match.path.length)
      });
      return null;
    }}
    {...passThrough} />
);

export default RefreshRoute;
