import React from 'react';
import { Translate } from 'react-localize-redux';
import { Row } from 'react-bootstrap';

import overviewImage from 'assets/images/ob-overview.png';
import dashboardImage from 'assets/images/seller-ob-dashboard.png';
import taskImage from 'assets/images/seller-ob-task.png';

import OnboardingModal from '../OnboardingModal/OnboardingModal';

const SellerOnboarding = () => {
  const renderPage1 = () => (
    <div>
      <div className="imageSection">
        <img
          src={overviewImage}
          alt="Onboarding overview"
          width="100%" />
      </div>
      <div className="textSection">
        <Row className="text-center">
          <h1>
            <Translate id="onboarding.seller.page1.heading" />
          </h1>
        </Row>
        <Row className="text-center">
          <p>
            <Translate id="onboarding.seller.page1.message" />
          </p>
        </Row>
      </div>
    </div>
  );

  const renderPage2 = () => (
    <div>
      <div className="imageSection">
        <img
          src={taskImage}
          alt="Onboarding project"
          width="100%" />
      </div>
      <div className="textSection">
        <Row className="text-center">
          <h1>
            <Translate id="onboarding.seller.page2.heading" />
          </h1>
        </Row>
        <Row className="text-center">
          <p>
            <Translate id="onboarding.seller.page2.message" />
          </p>
        </Row>
      </div>
    </div>
  );

  const renderPage3 = () => (
    <div>
      <div className="imageSection">
        <img
          src={dashboardImage}
          alt="Onboarding messaging"
          width="100%" />
      </div>
      <div className="textSection">
        <Row className="text-center">
          <h1>
            <Translate id="onboarding.seller.page3.heading" />
          </h1>
        </Row>
        <Row className="text-center">
          <p>
            <Translate id="onboarding.seller.page3.message" />
          </p>
        </Row>
      </div>
    </div>
  );

  const onboardingSlides = [
    renderPage1(),
    renderPage2(),
    renderPage3(),
  ];

  return (
    <OnboardingModal
      onboardingSlides={onboardingSlides}
      localStorageKey="lastOnboardingPageSeller" />
  );
};

export default SellerOnboarding;
