export const CACHE_PROJECT = 'CACHE_PROJECT';
export const CACHE_STOCK = 'CACHE_STOCK';
export const CACHE_SEARCH_RESULTS = 'CACHE_SEARCH_RESULTS';
export const CACHE_FEATURED_PROJECTS = 'CACHE_FEATURED_PROJECTS';
export const CACHE_RECENTLY_UPDATED_PROJECTS = 'CACHE_RECENTLY_UPDATED_PROJECTS';
export const CACHE_BROCHURE = 'CACHE_BROCHURE';
export const CACHE_PROJECT_EVENT_STATISTICS = 'CACHE_PROJECT_EVENT_STATISTICS';
export const CACHE_PROJECT_VIDEO_REFERENCE = 'CACHE_PROJECT_VIDEO_REFERENCE';
export const CACHE_VIDEO = 'CACHE_VIDEO';
export const CACHE_VIDEOS = 'CACHE_VIDEOS';
export const PURGE_PROJECT_VIDEO_REFERENCE = 'PURGE_PROJECT_VIDEO_REFERENCE';
export const PURGE_VIDEO = 'PURGE_VIDEO';

export function cacheProject(project) {
  return {
    type: CACHE_PROJECT,
    project,
  };
}

export function cacheStock(stock) {
  return {
    type: CACHE_STOCK,
    stock,
  };
}

export function cacheSearchResults(results) {
  return {
    type: CACHE_SEARCH_RESULTS,
    results,
  };
}

export function cacheFeaturedProjects(featured) {
  return {
    type: CACHE_FEATURED_PROJECTS,
    featured,
  };
}

export function cacheRecentlyUpdatedProjects(recentlyUpdated) {
  return {
    type: CACHE_RECENTLY_UPDATED_PROJECTS,
    recentlyUpdated,
  };
}

export function cacheBrochure(brochure) {
  return {
    type: CACHE_BROCHURE,
    brochure,
  };
}

export function cacheProjectEventStatistics(projectEventStatisticResults) {
  return {
    type: CACHE_PROJECT_EVENT_STATISTICS,
    projectEventStatisticResults,
  };
}

export function cacheProjectVideoReference(projectId, videoId) {
  return {
    type: CACHE_PROJECT_VIDEO_REFERENCE,
    projectId,
    videoId
  };
}

export function cacheVideo(video, projectId) {
  return {
    type: CACHE_VIDEO,
    video,
    projectId
  };
}

export function cacheVideos(videos) {
  return {
    type: CACHE_VIDEOS,
    videos
  };
}

export function purgeVideo(videoId) {
  return {
    type: PURGE_VIDEO,
    videoId
  };
}

export function purgeProjectVideoReference(projectId, videoId) {
  return {
    type: PURGE_PROJECT_VIDEO_REFERENCE,
    projectId,
    videoId
  };
}
