import {
  INITIATE_CHAT,
  CHAT_FAILED,
  UPDATE_CONVERSATIONS_COUNT,
  RESEND_CHAT,
  UPDATE_LEGACY_CONVERSATIONS_COUNT,
} from './MessagingActions';

function initiateChat(state, action) {
  return {
    ...state,
    chatId: action.id,
    chatMessage: action.message,
  };
}

function resendChat(state) {
  return {
    ...state,
  };
}

function chatFailed(state, action) {
  return {
    ...state,
    chatError: action.error,
  };
}

function updateConversationsCount(state, action) {
  return {
    ...state,
    unreadConversationsCount: action.count,
  };
}

function updateLegacyConversationsCount(state, action) {
  return {
    ...state,
    unreadLegacyConversationsCount: action.count,
  };
}

const initialState = {
  chatId: '',
  chatMessage: '',
  chatError: '',
  unreadConversationsCount: 0,
  unreadLegacyConversationsCount: 0,
};

export default function MessagingReducers(state = initialState, action) {
  switch (action.type) {
    case INITIATE_CHAT:
      return initiateChat(state, action);
    case RESEND_CHAT:
      return resendChat(state);
    case CHAT_FAILED:
      return chatFailed(state, action);

    case UPDATE_CONVERSATIONS_COUNT:
      return updateConversationsCount(state, action);
    case UPDATE_LEGACY_CONVERSATIONS_COUNT:
      return updateLegacyConversationsCount(state, action);

    default:
      return state;
  }
}
