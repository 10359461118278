import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Button } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';

import Icon, { ICONS } from 'components/Icon/Icon';
import { setConnectionRetryTime as setConnectionRetryTimeAction } from 'components/LoggedInUser/LoggedInUserActions';
import {
  isConnectionDown,
  getConnectionRetryTime,
  getConnectionDownTime,
} from 'components/LoggedInUser/LoggedInUserSelectors';
import { history } from 'utility/history';

import './ConnectionIssueBanner.scss';

class ConnectionIssueBanner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timer: null,
      displayBanner: null,
    };

    this.retryConnection = this.retryConnection.bind(this);
  }

  // eslint-disable-next-line
  componentDidUpdate(prevProps, prevState) {
    const { connectionIsDown } = this.props;
    const { timer } = this.state;

    if (!timer && connectionIsDown) {
      this.state.timer = setInterval(this.retryConnection, 1000);
    }
  }

  componentWillUnmount() {
    const { timer } = this.state;

    if (timer) {
      clearInterval(timer);
    }
  }

  retryConnection() {
    const {
      connectionDownTime,
      connectionIsDown,
      connectionRetryTime,
      setConnectionRetryTime
    } = this.props;
    const { displayBanner, timer } = this.state;

    if (connectionIsDown && history.location.pathname !== '/neo/Status/ConnectionIssue') {
      this.setState({ displayBanner: true });
    } else {
      this.setState({ displayBanner: false });
    }

    if (connectionIsDown && Date.now() - connectionDownTime >= connectionRetryTime * 1000) {
      setConnectionRetryTime(connectionRetryTime * 2 < 300
        ? connectionRetryTime * 2 : 300);
      if (displayBanner) {
        history.push(`/neo/Refresh${history.location.pathname}`);
      } else {
        history.goBack();
      }
    } else if (timer
      && !connectionIsDown && Date.now() - connectionDownTime >= connectionRetryTime * 1000) {
      clearInterval(timer);
      this.setState({ timer: null });
    }
  }

  render() {
    const {
      connectionIsDown,
      connectionRetryTime,
      setConnectionRetryTime
    } = this.props;
    const { displayBanner } = this.state;

    if (connectionIsDown && displayBanner) {
      return (
        <Row className="banner">
          <Icon icon={ICONS.failure} colour="#FFF" size={35} />
          <p>
            <Translate
              id="connectionIssueBanner.content"
              data={{ seconds: connectionRetryTime }} />
          </p>
          <Button onClick={() => {
            setConnectionRetryTime(connectionRetryTime);
            history.push(`/neo/Refresh${history.location.pathname}`);
          }}>
            <Translate id="connectionIssueBanner.button" />
          </Button>
        </Row>
      );
    }
    return null;
  }
}

ConnectionIssueBanner.propTypes = {
  connectionIsDown: PropTypes.bool.isRequired,
  connectionRetryTime: PropTypes.number.isRequired,
  connectionDownTime: PropTypes.number.isRequired,
  setConnectionRetryTime: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    connectionIsDown: isConnectionDown(state),
    connectionRetryTime: getConnectionRetryTime(state),
    connectionDownTime: getConnectionDownTime(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setConnectionRetryTime: (seconds) => dispatch(setConnectionRetryTimeAction(seconds)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConnectionIssueBanner);
