import { useSelector, useDispatch } from 'react-redux';

import {
  getUnreadConversationsCount,
  getUnreadLegacyConversationsCount
} from 'components/Messaging/MessagingSelectors';

import { getLegacyUnreadConversationsCountThunk } from 'components/Messaging/MessagingActions';

function useMessaging() {
  const unreadConversationsCount = useSelector(getUnreadConversationsCount);
  const unreadLegacyConversationsCount = useSelector(getUnreadLegacyConversationsCount);

  const dispatch = useDispatch();
  const fetchUnreadConversationsCounts = () => dispatch(getLegacyUnreadConversationsCountThunk());

  return {
    unreadConversationsCount,
    unreadLegacyConversationsCount,
    totalUnreadConversationsCount: unreadConversationsCount + unreadLegacyConversationsCount,
    fetchUnreadConversationsCounts,
  };
}

export default useMessaging;
