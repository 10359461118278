import { createSelector } from 'reselect';

const popupAlertTagSelector = (state) => state.alertPopup.tag;
const alertPopupCustomDataSelector = (state) => state.alertPopup.customData;

export const getAlertPopupTag = createSelector(
  [popupAlertTagSelector],
  (state) => state,
);

export const getAlertPopupCustomData = createSelector(
  [alertPopupCustomDataSelector],
  (state) => state,
);
