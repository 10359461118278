import {
  CREATE_PENDING_USER_LOGO,
  CREATE_PENDING_USER_LOGO_SUCCESS,
  CREATE_PENDING_USER_LOGO_FAILURE,
  SHOW_LOADER,
} from './SignupActions';

export function createPendingUserLogo(state) {
  return {
    ...state,
    pendingUserLogoUploading: true,
  };
}

export function createPendingUserLogoSuccess(state, action) {
  return {
    ...state,
    pendingUserLogoUploading: false,
    createPendingUserLogoResult: {
      ...state.createPendingUserLogoResult,
      ...action.createPendingUserLogoResult,
    },
  };
}

export function createPendingUserLogoFailure(state, action) {
  return {
    ...state,
    pendingUserLogoUploading: false,
    advertisementsError: action.error,
  };
}

export function showLoader(state) {
  return {
    ...state,
    showLoader: true,
  };
}

const initialState = {
  createPendingUserLogoResult: {
    assetId: null,
    eTag: null,
  },
  pendingUserLogoUploading: false,
  showLoader: false,
};

export default function SignupReducers(state = initialState, action) {
  switch (action.type) {
    case CREATE_PENDING_USER_LOGO:
      return createPendingUserLogo(state);
    case CREATE_PENDING_USER_LOGO_SUCCESS:
      return createPendingUserLogoSuccess(state, action);
    case CREATE_PENDING_USER_LOGO_FAILURE:
      return createPendingUserLogoFailure(state, action);
    case SHOW_LOADER:
      return showLoader(state);
    default:
      return state;
  }
}
