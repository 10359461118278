export const SHOW_SIGN_UP_DIALOG = 'SHOW_SIGN_UP_DIALOG';
export const HIDE_SIGN_UP_DIALOG = 'HIDE_SIGN_UP_DIALOG';

export function showSignupDialog(tag, customerType, canClose = true) {
  return {
    type: SHOW_SIGN_UP_DIALOG,
    tag,
    customerType,
    canClose,
  };
}

export function hideSignupDialog(tag, customerType) {
  return {
    type: HIDE_SIGN_UP_DIALOG,
    tag,
    customerType,
  };
}
