import { customerCallbackRequestApi } from 'services/customer.api';

export const CUSTOMER_CALLBACK_ENQUIRY_SUCCESS = 'CUSTOMER_CALLBACK_ENQUIRY_SUCCESS';
export const CUSTOMER_CALLBACK_ENQUIRY_FAILURE = 'CUSTOMER_CALLBACK_ENQUIRY_FAILURE';

export function customerCallbackRequestSuccess() {
  return {
    type: CUSTOMER_CALLBACK_ENQUIRY_SUCCESS,
  };
}

export function customerCallbackRequestFailure() {
  return {
    type: CUSTOMER_CALLBACK_ENQUIRY_FAILURE,
  };
}

export function customerCallbackRequestThunk(
  callbackType,
  onSuccess = null,
  onFailure = null,
  projectId = null
) {
  return (dispatch) => {
    customerCallbackRequestApi(callbackType, projectId)
      .then((result) => {
        if (result.error) {
          dispatch(customerCallbackRequestFailure(new Error(result.error)));
          if (onFailure) onFailure({ projectId });
          return;
        }

        if (result.data.customerCallbackRequest === false) {
          if (onFailure) onFailure({ projectId });
          return;
        }

        dispatch(customerCallbackRequestSuccess());
        if (onSuccess) onSuccess();
      })
      .catch((error) => {
        dispatch(customerCallbackRequestFailure(error));
        if (onFailure) onFailure({ projectId });
      });
  };
}
