import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

import useTranslate from 'hooks/useTranslate';
import useDisplaySettings from 'hooks/useDisplaySettings';

function MeasurementButton({ measurementUnitsType }) {
  const translate = useTranslate();
  const { measurementUnits: currentMeasurementUnits, setMeasurementUnits } = useDisplaySettings();
  const isActive = currentMeasurementUnits === measurementUnitsType;

  return (
    <Button
      active={isActive}
      className="measurement-button"
      onClick={() => setMeasurementUnits(measurementUnitsType)}>
      {translate(`measurementUnits.${measurementUnitsType}`)}
    </Button>
  );
}

MeasurementButton.propTypes = {
  measurementUnitsType: PropTypes.string.isRequired
};

export default MeasurementButton;
