import { useSelector } from 'react-redux';
import { hasPermission as hasPermissionUtility } from 'utility/permissions';

import {
  getLoggedInUser,
  getLoggedInUserLoading,
  getLoggedInUserError,
  isUserASeller,
} from 'components/LoggedInUser/LoggedInUserSelectors';

function useLoggedInUser() {
  const loggedInUser = useSelector(getLoggedInUser);
  const isLoggedInUser = useSelector((state) => (getLoggedInUser(state) !== null));
  const isSeller = useSelector((state) => isUserASeller(state));
  const isLoggedInUserLoading = useSelector(getLoggedInUserLoading);
  const loggedInUserError = useSelector(getLoggedInUserError);
  const hasPermission = (permission) => hasPermissionUtility(loggedInUser, permission);

  return {
    loggedInUser,
    isLoggedInUser,

    // NOTE: We should prefer uses of permissions, this should only be used when
    // it is essential for legacy compatibility.
    isUserASeller: isSeller,

    isLoggedInUserLoading,
    loggedInUserError,
    hasPermission,
  };
}

export default useLoggedInUser;
