// update cacheVersionNumber when making backwards incompatible changes to the cache
export const cacheVersionNumber = 4;

export const minimumSearchResults = 6;

export const cookieType = {
  userId: 'userId',
  userType: 'userType',
  agentId: 'agentId',
  sellerId: 'sellerId',
};

export const maxAgentOnboardingPages = 5;
export const maxSellerOnboardingPages = 3;

export const filterType = {
  all: 'ALL',
  propertyType: 'PROPERTY_TYPE',
  price: 'PRICE',
  pricePerArea: 'PRICE_PER_AREA',
  roomsAndSpaces: 'ROOMS_AND_SPACES',
};

export const maximumNumberOfProjectsMapView = 50;
export const maximumNumberOfProjectsBrandedList = 20;

export const assets = {
  branding: {
    isPublic: true,
    language: 'en',
  },
  companyLogo: {
    isPublic: true,
    language: 'en',
  },
};

export const supportedLanguages = [
  { name: 'English', code: 'en' },
  { name: 'English (US)', code: 'en-us' },
  { name: '中文', code: 'zh-cn' },
  { name: 'Español', code: 'es-es' },
  { name: 'Indonesia', code: 'id-id' },
];
