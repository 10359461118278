import { graphQLQuery } from './api';

export function signupApi(
  firstName,
  lastName,
  email,
  countryCode,
  phone,
  companyName,
) {
  const mutationString = `
  mutation ($firstName: String!, $lastName: String, $email: String!, $countryCode: String!, $phone: String!, $companyName: String!) {
    signup (firstName: $firstName, lastName: $lastName, countryCode:$countryCode, email: $email, phone: $phone, companyName: $companyName)
  }`;
  const variables = {
    firstName,
    lastName,
    email,
    countryCode,
    phone,
    companyName,
  };
  return graphQLQuery(mutationString, variables);
}

export function createPendingUserLogoApi(email, confirmationCode, filename, language) {
  const mutationString = `
  mutation createPendingUserLogo($email: String!, $confirmationCode: String!, $filename: String, $language: String,) {
    createPendingUserLogo(email: $email, confirmationCode: $confirmationCode, filename: $filename, language: $language) {
        assetId
        uri
        method
        expires
        headers { name value }
    }
  }`;
  const variables = {
    email,
    confirmationCode,
    filename,
    language
  };
  return graphQLQuery(mutationString, variables);
}

export function convertPendingUserApi(
  email,
  confirmationCode,
  password,
  companyWebsiteUri,
  companyLogoId,
  companyLogoETag,
  countryCode,
  level1AdministrativeAreaId,
  region,
  suburb
) {
  const mutationString = `
  mutation ($email: String!, $confirmationCode: String!, $password: String!, $companyWebsiteUri: Uri!, $companyLogoId: ID, $companyLogoETag: String, $countryCode: String, $level1AdministrativeAreaId: ID, $region: String, $suburb: String!) {
    convertPendingUser (email: $email, confirmationCode: $confirmationCode, password: $password, companyWebsiteUri: $companyWebsiteUri, companyLogoId: $companyLogoId, companyLogoETag: $companyLogoETag, countryCode: $countryCode, level1AdministrativeAreaId: $level1AdministrativeAreaId, region: $region, suburb: $suburb)
  }`;
  const variables = {
    email,
    confirmationCode,
    password,
    companyWebsiteUri,
    companyLogoId,
    companyLogoETag,
    countryCode,
    level1AdministrativeAreaId,
    region,
    suburb
  };
  return graphQLQuery(mutationString, variables);
}
