import React from 'react';
import PropTypes from 'prop-types';

import InputField from 'components/InputFields/InputField/InputField';

function TextAreaField(props) {
  const {
    label,
    labelDescription,
    meta,
    input,
    placeholder,
    inputRef,
  } = props;

  const { touched, error } = meta;

  return (
    <InputField
      test-attr="component-text-area-field"
      label={label}
      labelDescription={labelDescription}
      meta={meta}>
      <textarea
        test-attr="text-area"
        ref={inputRef}
        rows={8}
        {...input}
        className={(touched && error ? 'redInputBorder' : '') + ' form-control'}
        placeholder={placeholder} />
    </InputField>
  );
}

TextAreaField.defaultProps = {
  labelDescription: null,
  placeholder: null,
  inputRef: null,
};

TextAreaField.propTypes = {
  label: PropTypes.string.isRequired,
  labelDescription: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    error: PropTypes.string,
  }).isRequired,
  input: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  inputRef: PropTypes.oneOfType([
    PropTypes.shape({ current: PropTypes.instanceOf(HTMLTextAreaElement) }),
    PropTypes.func
  ]),
};

export default TextAreaField;
