export const SHOW_FILTERS = 'SHOW_FILTER';
export const HIDE_FILTERS = 'HIDE_FILTER';

export const SAVE_FILTERS = 'SAVE_FILTERS';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';

export const CLEAR_PROPERTY_TYPE_FILTER = 'CLEAR_PROPERTY_TYPE_FILTER';
export const CLEAR_PRICE_RANGE_FILTER = 'CLEAR_PRICE_RANGE_FILTER';
export const CLEAR_PRICE_PER_AREA_RANGE_FILTER = 'CLEAR_PRICE_PER_AREA_RANGE_FILTER';
export const CLEAR_ROOMS_AND_SPACES_FILTER = 'CLEAR_ROOMS_AND_SPACES_FILTER';

export function showFilters(filter) {
  return {
    type: SHOW_FILTERS,
    filter,
  };
}

export function hideFilters(filter) {
  return {
    type: HIDE_FILTERS,
    filter,
  };
}

export function saveFilters(filters) {
  return {
    type: SAVE_FILTERS,
    filters,
  };
}

export function clearFilters() {
  return {
    type: CLEAR_FILTERS,
  };
}

export function clearPropertyTypeFilter() {
  return {
    type: CLEAR_PROPERTY_TYPE_FILTER,
  };
}

export function clearPriceRangeFilter() {
  return {
    type: CLEAR_PRICE_RANGE_FILTER,
  };
}

export function clearPricePerAreaRangeFilter() {
  return {
    type: CLEAR_PRICE_PER_AREA_RANGE_FILTER,
  };
}

export function clearRoomsAndSpacesFilter() {
  return {
    type: CLEAR_ROOMS_AND_SPACES_FILTER,
  };
}
