import {
  REQUEST_IS_FOLLOWING_STATUS,
  REQUEST_IS_FOLLOWING_STATUS_SUCCESS,
  REQUEST_IS_FOLLOWING_STATUS_FAILURE,
  SET_FOLLOWING_SUCCESS,
  SET_FOLLOWING_FAILURE,
} from './FollowActions';

function requestIsFollowingStatus(state) {
  return {
    ...state,
    isFollowingError: null,
  };
}

function requestIsFollowingStatusSuccess(state, action) {
  return {
    ...state,
    following: {
      ...state.following,
      [action.id]: action.status,
    },
  };
}

function requestIsFollowingStatusFailure(state, action) {
  return {
    ...state,
    isFollowingError: action.error,
  };
}

function setFollowingSuccess(state, action) {
  return {
    ...state,
    following: {
      ...state.following,
      [action.id]: action.status,
    },
    setFollowingError: null,
  };
}

function setFollowingFailure(state, action) {
  return {
    ...state,
    setFollowingError: action.error,
  };
}

const initialState = {
  isFollowingError: null,
  setFollowingError: null,
  following: {},
};

export default function FollowReducers(state = initialState, action) {
  switch (action.type) {
    case REQUEST_IS_FOLLOWING_STATUS:
      return requestIsFollowingStatus(state);
    case REQUEST_IS_FOLLOWING_STATUS_SUCCESS:
      return requestIsFollowingStatusSuccess(state, action);
    case REQUEST_IS_FOLLOWING_STATUS_FAILURE:
      return requestIsFollowingStatusFailure(state, action);

    case SET_FOLLOWING_SUCCESS:
      return setFollowingSuccess(state, action);
    case SET_FOLLOWING_FAILURE:
      return setFollowingFailure(state, action);

    default:
      return state;
  }
}
