import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

import useEnvironment from 'hooks/useEnvironment';
import useIsAcceptingCookies from 'hooks/useIsAcceptingCookies';
import useLoggedInUser from 'hooks/useLoggedInUser';
import useTranslate from 'hooks/useTranslate';
import Icon, { ICONS } from 'components/Icon/Icon';
import colours from 'components/App/InvestoristColours.scss';

import { hideAcceptCookiesPopup, selectIsVisible } from './acceptCookiesPopupSlice';

import './AcceptCookiesPopup.scss';

function AcceptCookiesPopup({ isStatusPage }) {
  const isVisible = useSelector(selectIsVisible);
  const {
    isAcceptingCookies,
    isAcceptingCookiesSet,
    setIsAcceptingCookies
  } = useIsAcceptingCookies();
  const { isLoggedInUser, isLoggedInUserLoading } = useLoggedInUser();
  const { portalName } = useEnvironment() || {};
  const translate = useTranslate();
  const dispatch = useDispatch();
  const [isAcceptShowing, setIsAcceptShowing] = useState(
    isAcceptingCookies || !isAcceptingCookiesSet
  );

  if (!isVisible
    && (
      isStatusPage
      || isLoggedInUser
      || isLoggedInUserLoading
      || isAcceptingCookiesSet
    )
  ) {
    return null;
  }

  const [accept, decline] = ['Accept', 'Decline'];

  const onChoice = (isValueAccept) => {
    setIsAcceptingCookies(isValueAccept);
    dispatch(hideAcceptCookiesPopup());
  };
  const renderSubmitChoiceButton = () => {
    const value = isAcceptShowing ? accept : decline;
    const icon = isAcceptShowing ? ICONS.tick : ICONS.close;
    const colour = isAcceptShowing ? colours.whitePure : colours.primary;
    return (
      <Button
        className={`${value.toLowerCase()}_cookies`}
        value={value}
        onClick={() => onChoice(isAcceptShowing)}>
        <Icon icon={icon} size={15} colour={colour} />
        {' '}
        {translate(`acceptCookies.${value}`)}
      </Button>
    );
  };

  const toggleChoice = () => setIsAcceptShowing(!isAcceptShowing);
  const renderAdjustSettingsButton = () => (
    <Button
      className="toggle_choice"
      onClick={() => toggleChoice()}>
      {translate('acceptCookies.toggleChoice')}
    </Button>
  );

  return (
    <Modal show className="accept_cookies_modal">
      <Modal.Body className="accept_cookies_body">
        <div className="accept_cookies_popup_wrapper">
          <div className="accept_cookies_popup">
            <div className="accept_cookies_column">
              <div className="accept_cookies_svg">
                <Icon
                  icon={ICONS.cookies}
                  colour="white"
                  stroke="white"
                  strokeWidth="0.4"
                  width="50px"
                  height="50px"
                  viewBox="0 0 15 15" />
              </div>
              <div className="main_text">
                <p>{translate('acceptCookies.pleaseAcceptMessage', { portalName })}</p>
              </div>
              <div className="secondary_text">
                <p>{translate('acceptCookies.youCanChangeYourDecision')}</p>
              </div>
              <div className="accept_cookies_buttons">
                {renderSubmitChoiceButton()}
              </div>
            </div>
            <div className="accept_cookies_toggle">
              {renderAdjustSettingsButton()}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

AcceptCookiesPopup.propTypes = {
  isStatusPage: PropTypes.bool.isRequired
};

export default AcceptCookiesPopup;
