import moment from 'moment';

import {
  FETCH_CURRENT_USER_ALERTS,
  FETCH_CURRENT_USER_ALERTS_SUCCESS,
  FETCH_CURRENT_USER_ALERTS_FAILURE,
  DISMISS_ALERT,
} from './AlertsActions';

function fetchCurrentUserAlerts(state) {
  return {
    ...state,
    currentUserAlertsLoading: true,
  };
}

function fetchCurrentUserAlertsSuccess(state, action) {
  const newUserAlerts = [...action.alerts];
  newUserAlerts.forEach((alert) => {
    let oldAlertData = null;
    if (state.currentUserAlerts) {
      oldAlertData = state.currentUserAlerts.find(
        (element) => element.type === alert.type && element.severity === alert.severity
      );
      if (oldAlertData && oldAlertData.dismissUntil) {
        alert.dismissUntil = oldAlertData.dismissUntil;
      }
    }
  });
  return {
    ...state,
    currentUserAlertsLoading: false,
    currentUserAlerts: newUserAlerts,
  };
}

function fetchCurrentUserAlertsFailure(state, action) {
  return {
    ...state,
    currentUserAlertsLoading: false,
    currentUserAlertsError: action.error,
  };
}

function dismissAlert(state, action) {
  const alerts = [...state.currentUserAlerts];
  alerts.forEach((alert) => {
    if (alert.type === action.alertType) {
      // on dismissal the alert won't show up again for 16 hours
      alert.dismissUntil = moment.utc().unix() + (16 * 60 * 60);
    }
  });
  return {
    ...state,
    currentUserAlerts: [...alerts],
  };
}

const initialState = {
  currentUserAlertsLoading: false,
  currentUserAlertsError: null,
  currentUserAlerts: null,
};

export default function AlertsReducers(state = initialState, action) {
  switch (action.type) {
    case FETCH_CURRENT_USER_ALERTS:
      return fetchCurrentUserAlerts(state);
    case FETCH_CURRENT_USER_ALERTS_SUCCESS:
      return fetchCurrentUserAlertsSuccess(state, action);
    case FETCH_CURRENT_USER_ALERTS_FAILURE:
      return fetchCurrentUserAlertsFailure(state, action);

    case DISMISS_ALERT:
      return dismissAlert(state, action);

    default:
      return state;
  }
}
