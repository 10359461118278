import React from 'react';
import PropTypes from 'prop-types';

function InputField({
  label,
  labelDescription,
  meta,
  children
}) {
  const { touched, error } = meta;

  return (
  <div test-attr="component-input-field" className="bumpTop">
    <p test-attr="label" className="formLabel">
      {label}
    </p>
    {
      labelDescription
      && (
        <p test-attr="label-description" className="bumpBottomTiny labelDesc">
          {labelDescription}
        </p>
      )
    }
    {children}
    {
      touched
      && error
      && (
        <p test-attr="error" className="errorMessage text-left bumpTopHalf">
          *
          {error}
        </p>
      )
    }
  </div>
  );
}

InputField.defaultProps = {
  labelDescription: null,
};

InputField.propTypes = {
  label: PropTypes.string.isRequired,
  labelDescription: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    error: PropTypes.string,
  }).isRequired,
  children: PropTypes.any.isRequired,
};

export default InputField;
