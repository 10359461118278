import {
  FETCH_LOGGED_IN_USER_DETAILS,
  FETCH_LOGGED_IN_USER_DETAILS_SUCCESS,
  FETCH_LOGGED_IN_USER_DETAILS_FAILURE,

  FETCH_QUICK_SEARCH_SECTIONS,
  FETCH_QUICK_SEARCH_SECTIONS_SUCCESS,
  FETCH_QUICK_SEARCH_SECTIONS_FAILURE,

  SET_MEASUREMENT_UNITS,

  SET_PRESENTATION_MODE,

  SET_CONNECTION_UP,
  SET_CONNECTION_DOWN,
  SET_CONNECTION_RETRY_TIME,

  SET_TRIAL_POPUP_SHOWN_DATE,
  RESET_TRIAL_POPUP_SHOWN_DATE,

  CHANGE_ONBOARDING_PAGE,

  SEND_TRIAL_UPGRADE_REQUEST,
  SEND_TRIAL_UPGRADE_REQUEST_SUCCESS,
  SEND_TRIAL_UPGRADE_REQUEST_FAILURE,

  GET_LOGGED_IN_USER_SUBSCRIPTION,
  GET_LOGGED_IN_USER_SUBSCRIPTION_SUCCESS,
  GET_LOGGED_IN_USER_SUBSCRIPTION_FAILURE,
} from './LoggedInUserActions';

function fetchLoggedInUserDetails(state) {
  return {
    ...state,
    loggedInUserLoading: true,
  };
}

function fetchLoggedInUserDetailsSuccess(state, action) {
  return {
    ...state,
    loggedInUserLoading: false,
    loggedInUser: action.loggedInUser,
  };
}

function fetchLoggedInUserDetailsFailure(state, action) {
  return {
    ...state,
    loggedInUserLoading: false,
    loggedInUserError: action.error,
  };
}

function fetchQuickSearchSections(state) {
  return {
    ...state,
    quickSearchSectionsLoading: true,
  };
}

function fetchQuickSearchSectionsSuccess(state, action) {
  return {
    ...state,
    quickSearchSectionsLoading: false,
    quickSearchSections: action.quickSearchSections,
  };
}

function fetchQuickSearchSectionsFailure(state, action) {
  return {
    ...state,
    quickSearchSectionsLoading: false,
    quickSearchSectionsError: action.error,
  };
}

function setMeasurementUnits(state, action) {
  localStorage.setItem('measurementUnits', action.units);
  return {
    ...state,
    measurementUnits: action.units,
  };
}

function setPresentationMode(state, action) {
  localStorage.setItem('presentationMode', action.state ? 'true' : 'false');
  return {
    ...state,
    presentationMode: action.state,
  };
}

function setConnectionUp(state) {
  return {
    ...state,
    connectionStatus: 'NORMAL',
    connectionRetryTime: 10,
    connectionDownTime: 0,
  };
}

function setConnectionDown(state) {
  if (state.connectionStatus === 'NORMAL') {
    return {
      ...state,
      connectionStatus: 'DOWN',
      connectionDownTime: Date.now(),
    };
  }
  return state;
}

function setConnectionRetryTime(state, action) {
  return {
    ...state,
    connectionRetryTime: action.time,
    connectionDownTime: Date.now(),
  };
}

function setTrialPopUpShownDate(state, action) {
  localStorage.setItem('trialPopUpShownDate', action.date);
  return {
    ...state,
    trialPopUpShownDate: action.date,
  };
}

function changeOnboardingPage(state, action) {
  return {
    ...state,
    currentOnboardingPage: action.newPage,
  };
}

function sendTrialUpgradeRequest(state) {
  return {
    ...state,
    trialUpgradeResult: null,
    trialUpgradeRequestLoading: true,
    trialUpgradeRequestError: null,
  };
}

function sendTrialUpgradeRequestSuccess(state, action) {
  return {
    ...state,
    trialUpgradeRequestLoading: false,
    trialUpgradeResult: action.trialUpgradeResult,
  };
}

function sendTrialUpgradeRequestFailure(state, action) {
  return {
    ...state,
    trialUpgradeRequestLoading: false,
    trialUpgradeRequestError: action.error,
  };
}

function getLoggedInUserSubscription(state) {
  return {
    ...state,
    getLoggedInUserSubscriptionLoading: true,
    getLoggedInUserSubscriptionError: null,
  };
}

function getLoggedInUserSubscriptionSuccess(state, action) {
  if (state.loggedInUser) {
    const subscription = {
      ...state.loggedInUser.subscription
    };
    if (action.subscription) {
      subscription.type = action.subscription.type;
    } else {
      delete subscription.type;
    }
    return {
      ...state,
      getLoggedInUserSubscriptionLoading: false,
      loggedInUser: {
        ...state.loggedInUser,
        subscription
      }
    };
  }
  return {
    ...state,
    getLoggedInUserSubscriptionLoading: false,
  };
}

function getLoggedInUserSubscriptionError(state, action) {
  return {
    ...state,
    getLoggedInUserSubscriptionLoading: false,
    getLoggedInUserSubscriptionError: action.error,
  };
}

const initialState = {
  loggedInUserLoading: false,
  loggedInUserError: null,
  quickSearchSectionsError: null,
  quickSearchSectionsLoading: false,
  loggedInUser: null,
  quickSearchSections: [],
  measurementUnits: 'METRIC',
  presentationMode: false,
  connectionStatus: 'NORMAL',
  connectionRetryTime: 10,
  connectionDownTime: 0,
  trialPopUpShownDate: '',
  trialUpgradeResult: null,
  trialUpgradeRequestLoading: false,
  trialUpgradeRequestError: null,
  currentOnboardingPage: 0,
  getLoggedInUserSubscriptionLoading: false,
  getLoggedInUserSubscriptionError: null,
};

function resetTrialPopUpShownDate(state) {
  return {
    ...state,
    trialPopUpShownDate: initialState.trialPopUpShownDate,
  };
}

export default function LoggedInUserReducers(state = initialState, action) {
  switch (action.type) {
    case FETCH_LOGGED_IN_USER_DETAILS:
      return fetchLoggedInUserDetails(state);
    case FETCH_LOGGED_IN_USER_DETAILS_SUCCESS:
      return fetchLoggedInUserDetailsSuccess(state, action);
    case FETCH_LOGGED_IN_USER_DETAILS_FAILURE:
      return fetchLoggedInUserDetailsFailure(state, action);

    case FETCH_QUICK_SEARCH_SECTIONS:
      return fetchQuickSearchSections(state);
    case FETCH_QUICK_SEARCH_SECTIONS_SUCCESS:
      return fetchQuickSearchSectionsSuccess(state, action);
    case FETCH_QUICK_SEARCH_SECTIONS_FAILURE:
      return fetchQuickSearchSectionsFailure(state, action);

    case SET_MEASUREMENT_UNITS:
      return setMeasurementUnits(state, action);

    case SET_PRESENTATION_MODE:
      return setPresentationMode(state, action);

    case SET_CONNECTION_UP:
      return setConnectionUp(state);
    case SET_CONNECTION_DOWN:
      return setConnectionDown(state);
    case SET_CONNECTION_RETRY_TIME:
      return setConnectionRetryTime(state, action);

    case SET_TRIAL_POPUP_SHOWN_DATE:
      return setTrialPopUpShownDate(state, action);
    case RESET_TRIAL_POPUP_SHOWN_DATE:
      return resetTrialPopUpShownDate(state, action);

    case CHANGE_ONBOARDING_PAGE:
      return changeOnboardingPage(state, action);

    case SEND_TRIAL_UPGRADE_REQUEST:
      return sendTrialUpgradeRequest(state);
    case SEND_TRIAL_UPGRADE_REQUEST_SUCCESS:
      return sendTrialUpgradeRequestSuccess(state, action);
    case SEND_TRIAL_UPGRADE_REQUEST_FAILURE:
      return sendTrialUpgradeRequestFailure(state, action);

    case GET_LOGGED_IN_USER_SUBSCRIPTION:
      return getLoggedInUserSubscription(state);
    case GET_LOGGED_IN_USER_SUBSCRIPTION_SUCCESS:
      return getLoggedInUserSubscriptionSuccess(state, action);
    case GET_LOGGED_IN_USER_SUBSCRIPTION_FAILURE:
      return getLoggedInUserSubscriptionError(state, action);

    default:
      return state;
  }
}
