import { getActiveLanguage } from 'react-localize-redux';
import qs from 'qs';
import getSymbolFromCurrency from 'currency-symbol-map';

import Color from 'color';
import { getPageLoadId, getReferrerUrl } from 'components/App/AppSelectors';
import { isUserASeller } from 'components/LoggedInUser/LoggedInUserSelectors';
import { REDACTION_REASON } from 'utility/redactionReasons';
import { supportedLanguages } from 'utility/constants';

import { cookieType } from './constants';
import getCookieValue from './getCookieValue';

export function isOnBrochurePage() {
  return /neo\/brochure/i.test(window.location.pathname);
}

export function isOnProjectPage() {
  return /neo\/project/i.test(window.location.pathname);
}

export function removeUndefinedProperties(obj, recurse = true) {
  const newObj = {};

  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    switch (typeof value) {
      case 'object':
        newObj[key] = recurse && value ? removeUndefinedProperties(value, true) : value;
        break;

      case 'undefined':
        break;

      default:
        newObj[key] = value;
        break;
    }
  });

  return newObj;
}

let localTranslate = null;
let reduxStore = null;

export function initialiseUtility(currentTranslate, store) {
  localTranslate = currentTranslate;
  reduxStore = store;
}

export function translate(text, data = null) {
  return localTranslate(text, data);
}

export function isSupportedLanguageCode(code) {
  return supportedLanguages.find((language) => language.code === code) !== undefined;
}

export function dispatch(func) {
  reduxStore.dispatch(func);
}

export function getCurrentPageLoadId() {
  return getPageLoadId(reduxStore.getState());
}

export function getCurrentReferrerUrl() {
  return getReferrerUrl(reduxStore.getState());
}

export function getCurrentLanguageCode() {
  const defaultCode = 'en';
  const language = reduxStore ? getActiveLanguage(reduxStore.getState().localize) : undefined;
  return language ? language.code : defaultCode;
}

export const getSymbolFromCurrencyCode = (code) => {
  const symbol = getSymbolFromCurrency(code);

  if (!symbol) {
    return '$';
  }

  return symbol;
};

export function isCurrentUserASeller() {
  return isUserASeller(reduxStore.getState());
}

export function getStringFromInteger(value, tag = null) {
  if (value == null) {
    return translate('common.TBA');
  }

  let out = value.toLocaleString();

  if (tag !== null) {
    if (value === 1) {
      out += ' ' + translate(tag);
    } else {
      out += ' ' + translate(tag + '+plural');
    }
  }

  return out;
}

const getMultiplicityTag = (value) => (value === 1 ? '+singular' : '+plural');

export function getStringFrom2Integers(value1, value2, tag) {
  return translate(
    `${tag}${getMultiplicityTag(value1)}${getMultiplicityTag(value2)}`, { value1, value2 }
  );
}

export function getBedroomStringFromInteger(value, withTag, shortened) {
  if (value == null) {
    return translate('common.TBA');
  }

  let out = '';
  if (value === 0) {
    out += shortened ? translate('common.studio+short') : translate('common.studio');
  } else {
    out = value.toLocaleString();
  }

  if (withTag && value !== 0) {
    if (value === 1) {
      out += ' ' + translate('common.bedroom');
    } else {
      out += ' ' + translate('common.bedroom+plural');
    }
  }

  return out;
}

export function getStringOnlyFromInteger(value, tag = null) {
  if (value == null) {
    return translate('common.TBA');
  }

  let out = value.toLocaleString();

  if (tag !== null) {
    if (value === 1) {
      out = ' ' + translate(tag);
    } else {
      out = ' ' + translate(tag + '+plural');
    }
  }

  return out;
}

export function getStringFromFloor(floor) {
  if (floor == null) {
    return translate('common.TBA');
  }

  if (Number(floor) === 0) {
    return translate('common.floorZero');
  }

  return getStringFromInteger(floor);
}

export function getStringFromFloat(value, scale = 1.0) {
  if (value == null || !Number.isFinite(value)) {
    return translate('common.TBA');
  }
  return (Math.round((value * 100) / scale) / 100).toLocaleString();
}

export function getStringFromCurrencyType(amount, currency, shortForm = false) {
  if (amount == null || currency == null || !Number.isFinite(amount)) {
    return translate('common.TBA');
  }

  if (shortForm) {
    return amount.toLocaleString();
  }

  return amount.toLocaleString(undefined, { style: 'currency', currency }).replace(/\.00$/, '');
}

export function getStringFromCurrency(currency) {
  if (currency == null) {
    return translate('common.TBA');
  }
  return getStringFromCurrencyType(currency.value, currency.type);
}

export function getStringFromCurrencyRange(currencyRange, shortForm = false) {
  if (currencyRange == null) {
    return translate('common.TBA');
  }

  let out = getStringFromCurrencyType(currencyRange.minValue, currencyRange.type, shortForm);
  if (currencyRange.minValue !== currencyRange.maxValue) {
    out = out + ' - ' + getStringFromFloat(currencyRange.maxValue);
  }
  return out;
}

export function getStringFromPayment(payment) {
  if (payment) {
    if (payment.percentValue) {
      return getStringFromFloat(payment.percentValue) + '%';
    }
    if (payment.fixedValue && payment.fixedValue.value) {
      return getStringFromCurrency(payment.fixedValue);
    }
  }

  return translate('common.TBA');
}

export function getStringFromIntegerRange(intRange, tag = null) {
  if (intRange == null || intRange.minValue == null || intRange.maxValue == null) {
    return translate('common.TBA');
  }

  let out = '';

  out += getStringFromInteger(intRange.minValue);
  if (intRange.minValue !== intRange.maxValue) {
    out += ' - ' + getStringFromInteger(intRange.maxValue);
  }

  if (tag !== null) {
    if (intRange.maxValue === 1) {
      out += ' ' + translate(tag);
    } else {
      out += ' ' + translate(tag + '+plural');
    }
  }

  return out;
}

export function getBedroomStringFromIntegerRange(intRange, withTag, shortened) {
  if (intRange == null || intRange.minValue == null || intRange.maxValue == null) {
    return translate('common.TBA');
  }

  let out = '';

  if (intRange.minValue === 0) {
    out += shortened ? translate('common.studio+short') : translate('common.studio');
  } else {
    out += getStringFromInteger(intRange.minValue);
  }

  if (intRange.minValue !== intRange.maxValue) {
    out += ' - ' + getStringFromInteger(intRange.maxValue);
  }

  if (withTag && intRange.maxValue !== 0) {
    if (intRange.maxValue === 1) {
      out += ' ' + translate('common.bedroom');
    } else {
      out += ' ' + translate('common.bedroom+plural');
    }
  }

  return out;
}

export function getStringFromFloatRange(floatRange, tag = null) {
  if (floatRange == null || floatRange.minValue == null || floatRange.maxValue == null) {
    return translate('common.TBA');
  }

  let out = getStringFromFloat(floatRange.minValue);
  if (floatRange.minValue !== floatRange.maxValue) {
    out += ' - ' + getStringFromFloat(floatRange.maxValue);
  }

  if (tag !== null) {
    out += ' ' + translate(tag);
  }

  return out;
}

export function getStringFromPaymentRange(payment) {
  if (payment && (payment.percentValue || (payment.fixedValue && payment.fixedValue.maxValue))) {
    if (payment.percentValue && (payment.fixedValue && payment.fixedValue.maxValue)) {
      return translate('common.mixedCommissions');
    }
    if (payment.percentValue) {
      return getStringFromFloatRange(payment.percentValue) + '%';
    }
    if (payment.fixedValue && payment.fixedValue.maxValue) {
      return getStringFromCurrencyRange(payment.fixedValue);
    }
  }

  return translate('common.TBA');
}

export const SQM_TO_SQF = 10.7639;

export function getStringFromPricePerArea(currency, measurementUnits = 'METRIC') {
  if (currency == null) {
    return translate('common.TBA');
  }

  let amount = currency.value;
  if (amount == null || !Number.isFinite(amount)) {
    return translate('common.TBA');
  }

  if (measurementUnits === 'IMPERIAL') {
    amount /= SQM_TO_SQF;
  }

  return amount.toLocaleString(undefined, { style: 'currency', currency: currency.type }).replace(/\.00$/, '');
}

export function getAreaUnits(measurementUnits = 'METRIC') {
  return measurementUnits === 'METRIC' ? translate('common.sqm') : translate('common.sqf');
}

export function getPricePerAreaRangeHeader(currencyType, measurementUnits = 'METRIC', shortForm = false) {
  if (currencyType == null) {
    return translate('common.TBA');
  }

  const symbol = getSymbolFromCurrencyCode(currencyType);
  const areaUnits = getAreaUnits(measurementUnits);
  return shortForm ? `${symbol}/${areaUnits}` : `${symbol}/${areaUnits} ${translate('common.range')}`;
}

export function getStringFromPricePerAreaRange(currencyRange, measurementUnits = 'METRIC', shortForm) {
  if (currencyRange == null) {
    return translate('common.TBA');
  }

  let minAmount = currencyRange.minValue;
  let maxAmount = currencyRange.maxValue;

  if (measurementUnits === 'IMPERIAL') {
    minAmount /= SQM_TO_SQF;
    maxAmount /= SQM_TO_SQF;
  }

  minAmount = Math.floor(minAmount);
  maxAmount = Math.floor(maxAmount);

  let out = shortForm
    ? minAmount.toLocaleString()
    : getStringFromCurrencyType(minAmount, currencyRange.type);

  if (minAmount !== maxAmount) {
    out = out + ' - ' + getStringFromFloat(maxAmount);
  }

  return out;
}

export function getStringFromBuiltArea(area, measurementUnits = 'METRIC', tag = null) {
  if (area == null) {
    return translate('common.TBA');
  }

  let value = area;
  let units = translate('common.sqm');

  if (measurementUnits === 'IMPERIAL') {
    value = area * SQM_TO_SQF;
    units = translate('common.sqf');
  }

  let out = `${getStringFromFloat(value)} ${units}`;

  if (tag !== null) {
    out += ' ' + translate(tag);
  }

  return out;
}

export function getStringFromBuiltAreaRange(floatRange, measurementUnits = 'METRIC', tag = null, shortForm = false) {
  if (floatRange == null || floatRange.minValue == null || floatRange.maxValue == null) {
    return translate('common.TBA');
  }

  let minArea = floatRange.minValue;
  let maxArea = floatRange.maxValue;
  let units = shortForm ? null : translate('common.sqm');

  if (measurementUnits === 'IMPERIAL') {
    minArea *= SQM_TO_SQF;
    maxArea *= SQM_TO_SQF;
    units = shortForm ? null : translate('common.sqf');
  }

  minArea = Math.floor(minArea);
  maxArea = Math.floor(maxArea);

  let out = getStringFromInteger(minArea);
  if (minArea !== maxArea) {
    out += ' - ' + getStringFromInteger(maxArea);
  }

  if (!shortForm) {
    out += ' ' + units;
  }

  if (tag !== null) {
    out += ' ' + translate(tag);
  }

  return out;
}

const SQM_TO_ACRE = 0.000247105;
const SQM_TO_ACRE_THRESHOLD = 1011.71;

function getAcreUnits(area) {
  if (area.toFixed(2) === '1.00') {
    return translate('common.acre');
  }
  return translate('common.acre+plural');
}

export function getStringFromLandArea(area, measurementUnits = 'METRIC', tag = null) {
  if (area == null) {
    return translate('common.TBA');
  }

  if (area < SQM_TO_ACRE_THRESHOLD && measurementUnits === 'METRIC') {
    return getStringFromBuiltArea(area, measurementUnits, tag);
  }

  const value = area * SQM_TO_ACRE;

  let out = `${getStringFromFloat(value)} ${getAcreUnits(value)}`;

  if (tag !== null) {
    out += ' ' + translate(tag);
  }

  return out;
}

export function getStringFromLandAreaRange(floatRange, measurementUnits = 'METRIC', tag = null) {
  if (floatRange == null || floatRange.minValue == null || floatRange.maxValue == null) {
    return translate('common.TBA');
  }

  if (floatRange.minValue < SQM_TO_ACRE_THRESHOLD && floatRange.maxValue < SQM_TO_ACRE_THRESHOLD) {
    return getStringFromBuiltAreaRange(floatRange, measurementUnits, tag);
  }

  const minArea = floatRange.minValue * SQM_TO_ACRE;
  const maxArea = floatRange.maxValue * SQM_TO_ACRE;

  let out = getStringFromFloat(minArea);
  if (floatRange.minValue !== floatRange.maxValue) {
    out += ' - ' + getStringFromFloat(maxArea);
  }

  out += ' ' + getAcreUnits(minArea);

  if (tag !== null) {
    out += ' ' + translate(tag);
  }

  return out;
}

export function getStringFromIntegerFraction(countValue, ofValue, tag = null) {
  if (countValue == null || ofValue == null) {
    return translate('common.TBA');
  }
  let out = '';

  if (countValue === ofValue || ofValue < countValue) { // avoid "5 of 5" or "5 of 0" or "10 of 2"
    out += getStringFromInteger(countValue);
  } else {
    out += getStringFromInteger(countValue) + ' ' + translate('common.of') + ' ' + getStringFromInteger(ofValue);
  }

  if (tag !== null) {
    if ((ofValue < countValue) ? (countValue === 1) : (ofValue === 1)) {
      out += ' ' + translate(tag);
    } else {
      out += ' ' + translate(tag + '+plural');
    }
  }

  return out;
}

export function getMonthStringFromDate(date) {
  return translate('common.month.' + (date.getMonth() + 1));
}

export function getMonthYearStringFromDate(dateDescription) {
  if (dateDescription == null) {
    return translate('common.TBA');
  }

  const date = new Date(dateDescription);
  const year = date.getFullYear();

  if (year < 2000) {
    return translate('common.TBA');
  }

  return `${getMonthStringFromDate(date)} ${year}`;
}

export function getStringFromDate(date) {
  if (date !== null) {
    const dateObject = new Date(date);

    if (dateObject.getFullYear() >= 2000 && dateObject.getFullYear() < 2500) {
      return dateObject.toLocaleDateString();
    }
  }
  return translate('common.TBA');
}

export function getStringFromDateRange(startDate, endDate) {
  if (startDate == null || endDate == null) {
    return translate('common.TBA');
  }

  const startDateString = getStringFromDate(startDate);
  const endDateString = getStringFromDate(endDate);

  if (startDateString === endDateString) {
    return startDateString;
  }
  return startDateString + ' ' + translate('common.to') + ' ' + endDateString;
}

export function getPropertyType(propertyType) {
  const typeCount = propertyType.length;
  return (typeCount === 0 || typeCount > 1) ? 'MIXED' : propertyType[0];
}

export function getStringFromPropertyType(propertyType) {
  return translate('stockType.' + (propertyType.length > 1 ? 'MIXED' : propertyType[0]));
}

export function toInitialUpperCase(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function convertBytesToMegabytes(bytes) {
  if (!bytes || bytes < 1000) {
    return 0;
  }
  const megabytes = bytes / 1000000;
  if (bytes < 10000) {
    return megabytes.toFixed(3);
  }
  return megabytes.toFixed(2);
}

export function darkenColor(colour) {
  const color = Color(colour).alpha(0.5).darken(0.2);
  return color.hex();
}

export function lightenOrDarkenColor(colour) {
  const isLightColor = Color(colour).isLight();
  const color = isLightColor
    ? Color(colour).darken(0.5)
    : Color(colour).lighten(0.5);
  return color.hex();
}

export function hasUserCookies() {
  return getCookieValue(cookieType.userId) !== '';
}

export function isUserABot() {
  const botPattern = '(googlebot/|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)';
  const regex = new RegExp(botPattern, 'i');
  const { userAgent } = navigator;
  return regex.test(userAgent);
}

export function parseQueryString(queryString) {
  const query = queryString[0] === '?' ? queryString.substring(1) : queryString;
  return qs.parse(query);
}

export function isNullOrEmpty(str) {
  return !str || str.trim().length === 0;
}

export const getRedactionReason = (path, type, redactions) => {
  let redactionReason = '';
  if (redactions) {
    if (path === null) {
      redactions.forEach((redactionSet) => {
        if (!redactionReason) {
          redactionSet.redactions.forEach((redaction) => {
            if (!redactionReason && redaction.type === type) {
              redactionReason = redactionSet.reason;
            }
          });
        }
      });
    } else {
      redactions.forEach((redactionSet) => {
        if (!redactionReason) {
          redactionSet.redactions.forEach((redaction) => {
            if (!redactionReason && redaction.path === path && redaction.type === type) {
              redactionReason = redactionSet.reason;
            }
          });
        }
      });
    }
  }
  if (redactionReason === '') {
    redactionReason = REDACTION_REASON.UNRESTRICTED;
  }
  return redactionReason;
};

export const hasRedaction = (path, type, redactions) => {
  let found = false;
  if (redactions) {
    if (path === null) {
      redactions.forEach((redactionSet) => {
        if (!found) {
          redactionSet.redactions.forEach((redaction) => {
            if (!found && redaction.type === type) {
              found = true;
            }
          });
        }
      });
    } else {
      redactions.forEach((redactionSet) => {
        if (!found) {
          redactionSet.redactions.forEach((redaction) => {
            if (!found && redaction.path === path && redaction.type === type) {
              found = true;
            }
          });
        }
      });
    }
  }
  return found;
};

export const isUserAuthenticated = () => {
  const userId = getCookieValue(cookieType.userId);

  return Boolean(userId);
};

export const isValidUrl = (urlString) => {
  const pattern = new RegExp('^(https?:\\/\\/)?' // protocol
    + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' // domain name
    + '((\\d{1,3}\\.){3}\\d{1,3}))' // OR ip (v4) address
    + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // port and path
    + '(\\?[;&a-z\\d%_.~+=-]*)?' // query string
    + '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locater
  if (!pattern.test(urlString)) {
    return false;
  }
  return true;
};

export const getScaledImageUrl = (originalUrl, cloudflareOptions) => {
  if (isValidUrl(originalUrl)) {
    const url = new URL(originalUrl);
    const { origin, pathname } = url;
    return `${origin}/cdn-cgi/image/${cloudflareOptions}${pathname}`;
  }
  return originalUrl;
};
