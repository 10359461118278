import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getActiveLanguage, getLanguages, setActiveLanguage } from 'react-localize-redux';
import moment from 'moment';

import { changeLanguageThunk } from 'components/LoggedInUser/LoggedInUserActions';

function useLanguage() {
  const dispatch = useDispatch();
  const activeLanguage = useSelector((state) => getActiveLanguage(state.localize));
  const languages = useSelector((state) => getLanguages(state.localize));

  const activeLanguageCode = activeLanguage.code;
  const changeLanguage = (languageCode) => {
    if (languageCode !== activeLanguageCode) {
      dispatch(setActiveLanguage(languageCode));

      moment.locale(languageCode);
    }
  };

  const [previousLanguageCode, setPreviousLanguageCode] = useState(activeLanguageCode);
  useEffect(() => {
    if (previousLanguageCode && previousLanguageCode !== activeLanguageCode) {
      dispatch(changeLanguageThunk());
    }

    setPreviousLanguageCode(activeLanguageCode);
  }, [activeLanguageCode]);

  return {
    activeLanguage,
    languages,
    changeLanguage
  };
}

export default useLanguage;
