import { createSelector } from 'reselect';

import { setSeed, shuffleArray } from 'utility/random';

import { SEARCH_TYPE } from 'components/SearchPage/searchTypes';

const searchQuerySelector = (state) => state.search.searchQuery;
const searchFiltersSelector = (state) => state.search.searchFilters;
const searchQueryViewportLoadingSelector = (state) => state.search.searchQueryViewportLoading;
const searchQueryViewportViewportSelector = (state) => state.search.searchQueryViewportViewport;
const searchQueryViewportCountryCodeSelector = (state) => (
  state.search.searchQueryViewportCountryCode
);
const searchQueryViewportStateOrRegionSelector = (state) => (
  state.search.searchQueryViewportStateOrRegion
);
const searchQueryViewportErrorSelector = (state) => state.search.searchQueryViewportError;
export const projectSearchLoadingSelector = (state) => state.search.projectSearchLoading;
const projectSearchViewportSelector = (state) => state.search.projectSearchViewport;
const projectSearchCountryCodeSelector = (state) => state.search.projectSearchCountryCode;
const projectSearchStateOrRegionSelector = (state) => state.search.projectSearchStateOrRegion;
const projectSearchResultsSelector = (state) => state.search.projectSearchResults;
const projectSearchErrorSelector = (state) => state.search.projectSearchError;
const viewportSearchLoadingSelector = (state) => state.search.viewportSearchLoading;
const viewportSearchViewportSelector = (state) => state.search.viewportSearchViewport;
const viewportSearchExcludedViewportSelector = (state) => (
  state.search.viewportSearchExcludedViewport
);
const viewportSearchResultsSelector = (state) => state.search.viewportSearchResults;
const viewportSearchErrorSelector = (state) => state.search.viewportSearchError;
const viewportSearchLastViewportSelector = (state) => state.search.viewportSearchLastViewport;
const viewportSearchCountSelector = (state) => state.search.viewportSearchCount;
const locationSearchLoadingSelector = (state) => state.search.locationSearchLoading;
const locationSearchViewportSelector = (state) => state.search.locationSearchViewport;
const locationSearchRegionIdSelector = (state) => state.search.locationSearchRegionId;
const locationSearchResultsSelector = (state) => state.search.locationSearchResults;
const locationSearchErrorSelector = (state) => state.search.locationSearchError;
const searchSeedSelector = (state) => state.search.searchSeed;
const hoveredResultSelector = (state) => state.search.hoveredResult;
const fetchAllProjectsLoadingSelector = (state) => state.search.fetchAllProjectsLoading;
const fetchAllProjectsErrorSelector = (state) => state.search.fetchAllProjectsError;
const fetchAllProjectsViewportSelector = (state) => state.search.fetchAllProjectsViewport;
const fetchAllProjectsResultsSelector = (state) => state.search.fetchAllProjectsResults;
const currentMapViewportSelector = (state) => state.search.currentMapViewport;

const projectsCacheSelector = (state) => state.cache.projects;
const organisationsCacheSelector = (state) => state.cache.organisations;
const featuredProjectsCacheSelector = (state) => state.cache.featuredProjects;

export const getSearchQuery = createSelector(
  [searchQuerySelector],
  (savedSearchQuery) => savedSearchQuery,
);

export const getSearchFilters = createSelector(
  [searchFiltersSelector],
  (filter) => filter,
);

export const getSearchPageLoading = createSelector(
  [
    searchQueryViewportLoadingSelector,
    projectSearchLoadingSelector,
    locationSearchLoadingSelector,
  ],
  (viewportLoading, searchLoading, locationLoading) => (
    viewportLoading || searchLoading || locationLoading
  ),
);

export const getSearchPageError = createSelector(
  [
    searchQueryViewportErrorSelector,
    projectSearchErrorSelector,
    viewportSearchErrorSelector,
    locationSearchErrorSelector,
  ],
  (searchQueryError, projectSearchError, viewportSearchError, locationSearchError) => (
    searchQueryError || projectSearchError || viewportSearchError || locationSearchError
  ),
);

export const getSearchViewport = createSelector(
  [
    projectSearchViewportSelector,
    viewportSearchViewportSelector,
    searchQueryViewportViewportSelector,
    locationSearchViewportSelector,
  ],
  (
    projectSearchViewport,
    viewportSearchViewport,
    searchQueryViewport,
    locationSearchViewport,
  ) => (
    projectSearchViewport
      || viewportSearchViewport
      || searchQueryViewport
      || locationSearchViewport
  ),
);

export const getSearchCountryCode = createSelector(
  [searchQueryViewportCountryCodeSelector, projectSearchCountryCodeSelector],
  (viewportCountryCode, searchCountryCode) => (viewportCountryCode || searchCountryCode),
);

export const getSearchStateOrRegion = createSelector(
  [searchQueryViewportStateOrRegionSelector, projectSearchStateOrRegionSelector],
  (viewportStateOrRegion, searchStateOrRegion) => (viewportStateOrRegion || searchStateOrRegion),
);

export const getProjectSearchResults = createSelector(
  [
    projectSearchResultsSelector,
    projectsCacheSelector,
    organisationsCacheSelector,
    featuredProjectsCacheSelector,
  ],
  (
    projectSearchResults,
    projects,
    organisations,
    featuredProjects,
  ) => {
    const projectList = [];
    if (projectSearchResults) {
      projectSearchResults.forEach((projectId) => {
        if (projects && projects[projectId]) {
          const project = projects[projectId];
          delete project.featuredData;
          if (project.seller && organisations[project.seller]) {
            project.seller = organisations[project.seller];
          }
          if (
            featuredProjects
            && featuredProjects.featuredProjectInfo
            && featuredProjects.featuredProjectInfo[projectId]
          ) {
            project.featuredData = featuredProjects.featuredProjectInfo[projectId];
          }
          projectList.push(project);
        }
      });
    }
    return projectList;
  },
);

export const getViewportSearchLoading = createSelector(
  [viewportSearchLoadingSelector],
  (loading) => loading,
);

export const getViewportSearchExcludedViewport = createSelector(
  [viewportSearchExcludedViewportSelector],
  (excludedViewport) => excludedViewport,
);

function inBoundingBox(northeast, southwest, point) {
  let isLongitudeInRange = null;

  if (point.longitude < southwest.longitude) {
    point.longitude += 360;
  } else if (point.longitude > northeast.longitude) {
    point.longitude -= 360;
  }

  if (northeast.longitude < southwest.longitude) {
    isLongitudeInRange = point.longitude >= southwest.longitude
      || point.longitude <= northeast.longitude;
  } else {
    isLongitudeInRange = point.longitude >= southwest.longitude
      && point.longitude <= northeast.longitude;
  }
  return point.latitude >= southwest.latitude
    && point.latitude <= northeast.latitude
    && isLongitudeInRange;
}

export const getViewportSearchResults = createSelector(
  [
    viewportSearchResultsSelector,
    projectsCacheSelector,
    organisationsCacheSelector,
    featuredProjectsCacheSelector,
    getSearchViewport,
  ],
  (
    viewportSearchResults,
    projects,
    organisations,
    featuredProjects,
    viewport,
  ) => {
    const projectList = [];
    if (viewportSearchResults) {
      viewportSearchResults.forEach((projectId) => {
        if (projects && projects[projectId]) {
          const project = projects[projectId];
          delete project.featuredData;
          if (
            viewport
            && project.geocoordinates
            && inBoundingBox(viewport.northeast, viewport.southwest, project.geocoordinates)
          ) {
            if (project.seller && organisations[project.seller]) {
              project.seller = organisations[project.seller];
            }
            if (
              featuredProjects
              && featuredProjects.featuredProjectInfo
              && featuredProjects.featuredProjectInfo[projectId]
            ) {
              project.featuredData = featuredProjects.featuredProjectInfo[projectId];
            }
            projectList.push(project);
          }
        }
      });
    }
    return projectList;
  },
);

export const getLastViewportSearchViewport = createSelector(
  [viewportSearchLastViewportSelector],
  (lastViewport) => lastViewport,
);

export const getViewportSearchCount = createSelector(
  [viewportSearchCountSelector],
  (viewportSearchCount) => viewportSearchCount,
);

export const getHoveredResult = createSelector(
  [hoveredResultSelector],
  (hoveredResult) => hoveredResult,
);

function isStateFeaturedProject(featuredProjectData) {
  return featuredProjectData.type === 'STATE';
}

export const getExtraFeaturedProjects = createSelector(
  [
    getSearchQuery,
    viewportSearchResultsSelector,
    getSearchViewport,
    projectsCacheSelector,
    organisationsCacheSelector,
    featuredProjectsCacheSelector,
    getSearchPageLoading,
    getSearchStateOrRegion,
    searchSeedSelector,
  ],
  (
    searchQuery,
    searchResults,
    viewport,
    projects,
    organisations,
    featuredProjects,
    searchLoading,
    stateOrRegionCode,
    searchSeed,
  ) => {
    if (searchQuery && searchQuery.searchType === SEARCH_TYPE.LOCATION) {
      if (!searchLoading && featuredProjects && featuredProjects.featuredProjectInfo) {
        // If there are not enough featured projects in the current results return all possible
        // featured projects for the current search location
        const regionalFeaturedProjectsList = [];
        if (searchResults.length !== 0 && stateOrRegionCode) {
          Object.keys(featuredProjects.featuredProjectInfo).forEach((key) => {
            const featuredProjectData = featuredProjects.featuredProjectInfo[key];
            if (
              projects
              && featuredProjectData
              && isStateFeaturedProject(featuredProjectData)
              && featuredProjectData.stateOrRegion
              && featuredProjectData.stateOrRegion.abbreviation === stateOrRegionCode
            ) {
              const project = projects[featuredProjectData.projectId];
              if (project) {
                if (project.seller && organisations[project.seller]) {
                  project.seller = organisations[project.seller];
                }
                project.featuredData = featuredProjectData;
                regionalFeaturedProjectsList.push(project);
              }
            }
          });
        }

        setSeed(searchSeed);
        const selectedFeatureProjects = shuffleArray(regionalFeaturedProjectsList).slice(0, 2);

        let regionalFeaturedProjectsCount = 0;
        searchResults.forEach((projectId) => {
          if (
            projects
            && projects[projectId]
            && projects[projectId].geocoordinates
            && viewport
            && inBoundingBox(
              viewport.northeast,
              viewport.southwest,
              projects[projectId].geocoordinates,
            )
            && featuredProjects.featuredProjectInfo[projectId]
            && isStateFeaturedProject(featuredProjects.featuredProjectInfo[projectId])
          ) {
            regionalFeaturedProjectsCount += 1;
          }
        });

        if (regionalFeaturedProjectsCount < 2) {
          let extraFeaturedProjects = [];
          if (regionalFeaturedProjectsCount === 0) {
            extraFeaturedProjects = selectedFeatureProjects;
          } else if (regionalFeaturedProjectsCount === 1) {
            if (selectedFeatureProjects.length === 2) {
              if (!searchResults.includes(selectedFeatureProjects[1].id)) {
                extraFeaturedProjects.push(selectedFeatureProjects[1]);
              } else if (!searchResults.includes(selectedFeatureProjects[0].id)) {
                extraFeaturedProjects.push(selectedFeatureProjects[0]);
              }
            } else if (
              selectedFeatureProjects.length === 1
              && !searchResults.includes(selectedFeatureProjects[0].id)
            ) {
              extraFeaturedProjects.push(selectedFeatureProjects[0]);
            }
          }
          return extraFeaturedProjects;
        }
      }
    }

    return [];
  },
);

export const getLocationSearchRegionId = createSelector(
  [locationSearchRegionIdSelector],
  (locationSearchRegionId) => locationSearchRegionId,
);

export const getLocationSearchResults = createSelector(
  [
    locationSearchResultsSelector,
    projectsCacheSelector,
    organisationsCacheSelector,
    featuredProjectsCacheSelector,
  ],
  (
    locationSearchResults,
    projects,
    organisations,
    featuredProjects,
  ) => {
    const projectList = [];
    if (locationSearchResults) {
      locationSearchResults.forEach((projectId) => {
        if (projects && projects[projectId]) {
          const project = projects[projectId];
          delete project.featuredData;
          if (project.seller && organisations && organisations[project.seller]) {
            project.seller = organisations[project.seller];
          }
          if (
            featuredProjects
            && featuredProjects.featuredProjectInfo
            && featuredProjects.featuredProjectInfo[projectId]
          ) {
            project.featuredData = featuredProjects.featuredProjectInfo[projectId];
          }
          projectList.push(project);
        }
      });
    }
    return projectList;
  },
);

export const getAllProjectsLoading = createSelector(
  [fetchAllProjectsLoadingSelector],
  (state) => state,
);

export const getAllProjectsError = createSelector(
  [fetchAllProjectsErrorSelector],
  (state) => state,
);

export const getAllProjects = createSelector(
  [
    fetchAllProjectsResultsSelector,
    projectsCacheSelector,
    organisationsCacheSelector,
    featuredProjectsCacheSelector,
  ],
  (
    projectList,
    projects,
    organisations,
    featuredProjects,
  ) => {
    const allProjects = [];
    if (projectList && Object.keys(projects).length) {
      projectList.forEach((projectId) => {
        const project = projects[projectId];
        if (project) {
          if (project.seller && organisations[project.seller]) {
            project.seller = organisations[project.seller];
          }
          if (
            featuredProjects
            && featuredProjects.featuredProjectInfo
            && featuredProjects.featuredProjectInfo[projectId]
          ) {
            project.featuredData = featuredProjects.featuredProjectInfo[projectId];
          }
          allProjects.push(project);
        }
      });
    }
    return allProjects;
  },
);

export const getCurrentMapViewport = createSelector(
  [currentMapViewportSelector],
  (state) => state,
);

export const getAllVisibleProjects = createSelector(
  [
    fetchAllProjectsResultsSelector,
    projectsCacheSelector,
    organisationsCacheSelector,
    featuredProjectsCacheSelector,
    getCurrentMapViewport,
  ],
  (
    projectList,
    projects,
    organisations,
    featuredProjects,
    viewport,
  ) => {
    const allProjects = [];
    if (projectList && Object.keys(projects).length) {
      projectList.forEach((projectId) => {
        const project = projects[projectId];
        if (
          project
          && viewport
          && project.geocoordinates
          && inBoundingBox(viewport.northeast, viewport.southwest, project.geocoordinates)
        ) {
          if (project.seller && organisations[project.seller]) {
            project.seller = organisations[project.seller];
          }
          if (
            featuredProjects
            && featuredProjects.featuredProjectInfo
            && featuredProjects.featuredProjectInfo[projectId]
          ) {
            project.featuredData = featuredProjects.featuredProjectInfo[projectId];
          }
          allProjects.push(project);
        }
      });
    }
    return allProjects;
  },
);

export const getAllProjectsViewport = createSelector(
  [fetchAllProjectsViewportSelector],
  (state) => state,
);
