/* eslint-disable no-shadow */
/* eslint-disable quotes */
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useIsAcceptingCookies from 'hooks/useIsAcceptingCookies';
import useLoggedInUser from 'hooks/useLoggedInUser';

import {
  getActiveCampaignAccountNumber,
} from 'components/Integration/IntegrationSelectors';

function buildActiveCampaignScript(accountNumber, userEmail, shouldTriggerUpdate) {
  const emailScript = userEmail !== null ? `vgo('setEmail', '${userEmail}');` : '';
  const allowTrackingScript = shouldTriggerUpdate ? `vgo('process', 'allowTracking');` : '';

  return `
    (function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias=n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function(){(e[e.visitorGlobalObjectAlias].q=e[e.visitorGlobalObjectAlias].q||[]).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new Date).getTime();r=t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)})(window,document,"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js","vgo");
    vgo('setAccount', ${accountNumber});
    ${emailScript}
    vgo('setTrackByDefault', false);
    ${allowTrackingScript}
  `;
}

let lastTrackedLocationPathname = null;

function useActiveCampaignSiteTrackingScript() {
  const accountNumber = useSelector((state) => getActiveCampaignAccountNumber(state));
  const { loggedInUser, isLoggedInUser } = useLoggedInUser();
  const location = useLocation();
  const { isAcceptingCookies } = useIsAcceptingCookies();

  // User needs to have accepted use of cookies if not logged in to conform with EU's GDPR laws.
  const isTrackingPermitted = isLoggedInUser || isAcceptingCookies;

  const currentLocationPathname = location && location.pathname;

  const userEmail = loggedInUser && loggedInUser.email;

  useEffect(() => {
    lastTrackedLocationPathname = null;
  }, []);

  useEffect(() => {
    if (accountNumber === null || !isTrackingPermitted) {
      return () => { };
    }

    const script = document.createElement('script');

    const shouldTriggerUpdate = lastTrackedLocationPathname !== currentLocationPathname;

    script.type = 'text/javascript';
    script.async = true;
    script.innerHTML = buildActiveCampaignScript(
      accountNumber,
      userEmail,
      shouldTriggerUpdate
    );

    document.body.appendChild(script);

    if (shouldTriggerUpdate) {
      lastTrackedLocationPathname = currentLocationPathname;
    }

    return () => { document.body.removeChild(script); };
  }, [accountNumber, userEmail, isTrackingPermitted]);

  useEffect(() => {
    if (
      lastTrackedLocationPathname !== currentLocationPathname
      && isTrackingPermitted
      && window.vgo
      && typeof window.vgo === 'function'
    ) {
      window.vgo('update');
      lastTrackedLocationPathname = currentLocationPathname;
    }
  }, [currentLocationPathname]);
}

export default useActiveCampaignSiteTrackingScript;
