import { publicProjectEnquiryApi } from 'services/publicProjectEnquiry.api';
import { showAlertPopup } from 'components/AlertPopup/AlertPopupActions';

export const PUBLIC_PROJECT_ENQUIRY_SUCCESS = 'PUBLIC_PROJECT_ENQUIRY_SUCCESS';
export const PUBLIC_PROJECT_ENQUIRY_FAILURE = 'PUBLIC_PROJECT_ENQUIRY_FAILURE';

export function publicProjectEnquirySuccess() {
  return {
    type: PUBLIC_PROJECT_ENQUIRY_SUCCESS,
  };
}

export function publicProjectEnquiryFailure() {
  return {
    type: PUBLIC_PROJECT_ENQUIRY_FAILURE,
  };
}

export function sendPublicProjectEnquiry(
  firstName,
  lastName,
  email,
  countryCode,
  phone,
  companyName,
  projectId,
  message
) {
  function onSuccess(dispatch) {
    dispatch(publicProjectEnquirySuccess());
    dispatch(showAlertPopup('public-project-enquiry-request-success'));
  }

  function onFailure(dispatch, error) {
    dispatch(publicProjectEnquiryFailure(error));
    dispatch(showAlertPopup('public-project-enquiry-request-failure'));
  }

  return (dispatch) => {
    publicProjectEnquiryApi(
      firstName,
      lastName,
      email,
      countryCode,
      phone,
      companyName,
      projectId,
      message
    )
      .then((result) => {
        if (result.error) {
          onFailure(dispatch, new Error(result.error));
          return;
        }

        onSuccess(dispatch);
      })
      .catch((error) => {
        onFailure(dispatch, error);
      });
  };
}
