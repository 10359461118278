import { graphQLQuery } from './api';

export function customerCallbackRequestApi(callbackType, projectId = null) {
  const mutationString = `
  mutation customerCallbackRequest($callbackType: CustomerCallbackType!, $projectId: ID) {
    customerCallbackRequest(callbackType: $callbackType, projectId: $projectId)
  }`;
  const variables = {
    callbackType,
    projectId
  };
  return graphQLQuery(mutationString, variables);
}
