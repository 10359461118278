import {
  FETCH_FEATURED_PROJECTS,
  FETCH_FEATURED_PROJECTS_SUCCESS,
  FETCH_FEATURED_PROJECTS_FAILURE,

  FETCH_RECENTLY_UPDATED_PROJECTS,
  FETCH_RECENTLY_UPDATED_PROJECTS_SUCCESS,
  FETCH_RECENTLY_UPDATED_PROJECTS_FAILURE,
} from './ProjectActions';

function fetchFeaturedProjects(state) {
  return {
    ...state,
    featuredProjectsLoading: true,
    featuredProjectsError: null,
  };
}

function fetchFeaturedProjectsSuccess(state) {
  return {
    ...state,
    featuredProjectsLoading: false,
  };
}

function fetchFeaturedProjectsFailure(state, action) {
  return {
    ...state,
    featuredProjectsLoading: false,
    featuredProjectsError: action.error,
  };
}

function fetchRecentlyUpdatedProjects(state) {
  return {
    ...state,
    recentlyUpdatedProjectsLoading: true,
    recentlyUpdatedProjectsError: null,
    recentlyUpdatedProjectsViewport: null,
  };
}

function fetchRecentlyUpdatedProjectsSuccess(state, action) {
  return {
    ...state,
    recentlyUpdatedProjectsLoading: false,
    recentlyUpdatedProjectsViewport: action.results.viewport,
  };
}

function fetchRecentlyUpdatedProjectsFailure(state, action) {
  return {
    ...state,
    recentlyUpdatedProjectsLoading: false,
    recentlyUpdatedProjectsError: action.error,
    recentlyUpdatedProjectsViewport: null,
  };
}

const initialState = {
  projectDetailsLoading: false,
  projectDetailsError: null,
  projectImagesLoading: false,
  projectImagesError: null,
  projectSellerLoading: false,
  projectSellerError: null,
  projectDocumentsListLoading: false,
  projectDocumentsListError: null,
  featuredProjectsLoading: false,
  featuredProjectsError: null,
  recentlyUpdatedProjectsLoading: false,
  recentlyUpdatedProjectsError: null,
  recentlyUpdatedProjectsViewport: null,
  projectOverviewLoading: false,
  projectOverviewError: null,
};

export default function ProjectReducers(state = initialState, action) {
  switch (action.type) {
    case FETCH_FEATURED_PROJECTS:
      return fetchFeaturedProjects(state);
    case FETCH_FEATURED_PROJECTS_SUCCESS:
      return fetchFeaturedProjectsSuccess(state);
    case FETCH_FEATURED_PROJECTS_FAILURE:
      return fetchFeaturedProjectsFailure(state, action);

    case FETCH_RECENTLY_UPDATED_PROJECTS:
      return fetchRecentlyUpdatedProjects(state);
    case FETCH_RECENTLY_UPDATED_PROJECTS_SUCCESS:
      return fetchRecentlyUpdatedProjectsSuccess(state, action);
    case FETCH_RECENTLY_UPDATED_PROJECTS_FAILURE:
      return fetchRecentlyUpdatedProjectsFailure(state, action);

    default:
      return state;
  }
}
