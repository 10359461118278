import { useSelector } from 'react-redux';

import { getEnvironment } from 'components/Environment/EnvironmentSelectors';
import { getPortalName } from 'utility/portal';

function useEnvironment() {
  const environment = useSelector(getEnvironment);

  return {
    environment,
    portalName: getPortalName(environment),
  };
}

export default useEnvironment;
