import {
  FETCH_COUNTRIES,
  FETCH_COUNTRIES_SUCCESS,
  FETCH_COUNTRIES_FAILURE,
} from './CountriesActions';

function fetchCountries(state) {
  return {
    ...state,
    countriesLoading: true,
    countriesError: null,
  };
}

function fetchCountriesSuccess(state, action) {
  return {
    ...state,
    countriesLoading: false,
    countries: action.countries,
  };
}

function fetchCountriesFailure(state, action) {
  return {
    ...state,
    countriesLoading: false,
    countriesError: action.error,
  };
}

const initialState = {
  countries: null,
  countriesLoading: false,
  countriesError: null,
};

export default function CountriesReducers(state = initialState, action) {
  switch (action.type) {
    case FETCH_COUNTRIES:
      return fetchCountries(state);
    case FETCH_COUNTRIES_SUCCESS:
      return fetchCountriesSuccess(state, action);
    case FETCH_COUNTRIES_FAILURE:
      return fetchCountriesFailure(state, action);

    default:
      return state;
  }
}
