import fetch from 'isomorphic-fetch';

import {
  dispatch,
  removeUndefinedProperties
} from 'utility/utility';
import getAuthenticationToken from 'utility/getAuthenticationToken';
import getRequestHeaders from 'utility/getRequestHeaders';
import { history } from 'utility/history';
import { setConnectionUp, setConnectionDown } from 'components/LoggedInUser/LoggedInUserActions';
import merge from 'lodash/merge';
import { configure } from './configuration';

import ApiVersionError from './ApiVersionError';
import HttpResponseError from './HttpResponseError';
import TimeoutError from './TimeoutError';
import NoConnectionError from './NoConnectionError';

const fetchRequestDefaultOptions = {
  timeoutValue: 20000
};

function fetchRequest(headers, body, options) {
  return configure()
    .then((config) => {
      const {
        timeoutValue
      } = {
        ...fetchRequestDefaultOptions,
        ...options
      };

      const startTime = Date.now();
      let hasTimedOut = false;
      let hasErrored = false;

      let fetchTimeout;
      const timeoutPromise = new Promise((_, reject) => {
        fetchTimeout = setTimeout(() => {
          hasTimedOut = true;
          if (hasErrored) {
            return;
          }
          dispatch(setConnectionDown());
          reject(new TimeoutError());
        }, timeoutValue);
      });

      const fetchPromise = fetch(config.apiUrl, {
        method: 'POST',
        headers,
        body
      })
        .then((response) => {
          // Clear the timeout as cleanup
          clearTimeout(fetchTimeout);
          if (hasTimedOut) {
            return response;
          }

          const deltaTime = Date.now() - startTime;
          if (config.logging && config.logging.logApiResponseTimesToConsole) {
            // eslint-disable-next-line
            console.log(body + ' ==> ' + deltaTime + 'ms');
          }

          return response;
        })
        .catch((error) => {
          if (!navigator.onLine) {
            dispatch(setConnectionDown());
            hasErrored = true;
            throw new NoConnectionError(error);
          } else {
            if (hasTimedOut) return;
            hasErrored = true;
            throw error;
          }
        });

      return Promise.race([timeoutPromise, fetchPromise]);
    });
}

const graphQLQueryDefaultOptions = {
  useAuthenticationToken: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  extractData: (response) => response.json(),
  errorHandler: (error) => {
    if (error instanceof HttpResponseError) {
      switch (error.response.status) {
        case 401:
          if (!history.location.pathname.includes('/neo/Status/Login')) {
            history.replace(`/neo/Status/Login?redirectUrl=${encodeURIComponent(window.location.pathname + window.location.search)}`);
            return;
          }

          break;

        case 403:
          if (getAuthenticationToken()) {
            history.replace('/neo/Status/Unavailable');
            return;
          }

          if (!history.location.pathname.includes('/neo/Status/Login')) {
            history.replace(`/neo/Status/Login?redirectUrl=${encodeURIComponent(window.location.pathname + window.location.search)}`);
            return;
          }

          break;

        case 404:
          history.replace('/neo/Status/Unavailable');
          return;

        default:
          if (error.response.status >= 500 && error.response.status < 600) {
            dispatch(setConnectionDown());
            if (history.location.pathname !== '/neo/Status/ConnectionIssue') {
              history.push('/neo/Status/ConnectionIssue');
            }
            return;
          }
      }
    }
    throw error;
  }
};

export function graphQLQuery(queryString, variables, options) {
  const {
    useAuthenticationToken,
    failureStatusHandler,
    headers,
    extractData,
    errorHandler
  } = removeUndefinedProperties(merge(
    {},
    graphQLQueryDefaultOptions,
    {
      headers: getRequestHeaders()
    },
    options
  ));

  if (!useAuthenticationToken) {
    delete headers.AuthenticationToken;
  }
  if (headers.referrerUrl === null) {
    delete headers.PageReferrer;
  }

  const body = `{
    "query": "` + queryString + `",
    "variables": ` + JSON.stringify(variables) + `
  }`;

  let requestPromise = fetchRequest(
    headers,
    body,
    removeUndefinedProperties({ failureStatusHandler }, false)
  ).then((response) => {
    if (!response.ok) {
      throw new HttpResponseError(response);
    }

    dispatch(setConnectionUp());

    // Force a page reload when the api version changes to prevent erroneous schema calls.
    const lastRecordedApiVersion = localStorage.getItem('apiVersion');
    const responseApiVersion = response.headers.get('ApiVersion');
    if (!lastRecordedApiVersion) {
      localStorage.setItem('apiVersion', responseApiVersion);
    } else if (lastRecordedApiVersion !== responseApiVersion) {
      throw new ApiVersionError(lastRecordedApiVersion, responseApiVersion);
    }
    return response;
  }).catch((error) => {
    if (error instanceof ApiVersionError) {
      localStorage.removeItem('apiVersion');
      window.location.reload();
    }
    throw error;
  });

  if (errorHandler) {
    requestPromise = requestPromise.catch(errorHandler);
  }

  if (extractData) {
    requestPromise = requestPromise.then(extractData);
  }

  return requestPromise;
}
