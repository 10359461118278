import { combineReducers, createStore, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import ReduxThunk from 'redux-thunk';
import { reducer as formReducer } from 'redux-form';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { localizeReducer } from 'react-localize-redux';

import AppReducers from 'components/App/AppReducers';
import CacheReducers from 'cache/CacheReducers';
import LoggedInUserReducers from 'components/LoggedInUser/LoggedInUserReducers';
import SearchReducers from 'components/Search/SearchReducers';
import SearchFiltersReducers from 'components/SearchFilters/SearchFiltersReducers';
import ProjectPageReducers from 'components/ProjectPage/ProjectReducers';
import MessagingReducers from 'components/Messaging/MessagingReducers';
import FollowReducers from 'components/Follow/FollowReducers';
import IntegrationReducers from 'components/Integration/IntegrationReducers';
import MessagePopupReducers from 'components/MessagePopup/MessagePopupReducers';
import AlertPopupReducers from 'components/AlertPopup/AlertPopupReducers';
import BrochureReducers from 'components/Brochure/BrochureReducers';
import AlertsReducers from 'components/Alerts/AlertsReducers';
import AdvertisementsReducers from 'components/Advertisements/AdvertisementsReducers';
import EnvironmentReducers from 'components/Environment/EnvironmentReducers';
import PortalSettingsReducers from 'components/PortalSettings/PortalSettingsReducers';
import EventsReducers from 'components/Events/EventsReducers';
import UserSettingsReducers from 'components/UserSettings/UserSettingsReducers';
import DashboardPageReducers from 'components/DashboardPage/DashboardPageReducers';
import SignupDialogReducers from 'components/SignupDialog/SignupDialogReducers';
import SigninNotificationPopupReducers from 'components/SignInNotificationPopup/SigninNotificationPopupReducers';
import PublicProjectEnquiryDialogReducers from 'components/PublicProjectEnquiryDialog/PublicProjectEnquiryDialogReducers';
import PortalReducers from 'components/Portal/PortalReducers';
import AssetReducers from 'components/Asset/AssetReducers';
import SignupReducers from 'components/Signup/SignupReducers';
import GeolocationReducers from 'components/Geolocation/GeolocationReducers';
import CountriesReducers from 'components/Countries/CountriesReducers';
import AgentProfileReducers from 'components/AgentProfile/AgentProfileReducers';
import acceptCookiesPopupReducer from 'components/AcceptCookiesPopup/acceptCookiesPopupSlice';
import { configure } from 'services/configuration';

import SearchPageReducers from './SearchSuggestionsReducers';

const loggerMiddleware = createLogger();

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
  whitelist: [
    'cache',
    'search',
    'follow',
    'alerts',
    'advertisements',
    'userSettings'
  ]
};

export const rootReducer = combineReducers({
  acceptCookiesPopup: acceptCookiesPopupReducer,
  advertisements: AdvertisementsReducers,
  alertPopup: AlertPopupReducers,
  alerts: AlertsReducers,
  app: AppReducers,
  asset: AssetReducers,
  brochure: BrochureReducers,
  cache: CacheReducers,
  countries: CountriesReducers,
  dashboard: DashboardPageReducers,
  environment: EnvironmentReducers,
  events: EventsReducers,
  follow: FollowReducers,
  form: formReducer,
  geolocation: GeolocationReducers,
  integration: IntegrationReducers,
  localize: localizeReducer,
  messagePopup: MessagePopupReducers,
  messaging: MessagingReducers,
  portal: PortalReducers,
  portalSettings: PortalSettingsReducers,
  project: ProjectPageReducers,
  publicProjectEnquiryDialog: PublicProjectEnquiryDialogReducers,
  search: SearchReducers,
  searchFilters: SearchFiltersReducers,
  searchPage: SearchPageReducers,
  signup: SignupReducers,
  signupDialog: SignupDialogReducers,
  signinNotificationPopup: SigninNotificationPopupReducers,
  user: LoggedInUserReducers,
  userSettings: UserSettingsReducers,
  agentProfile: AgentProfileReducers,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const middlewares = [ReduxThunk];

export default function configureStore(initialState) {
  return configure()
    .then((config) => {
      if (config.logging.logRedux) {
        middlewares.push(loggerMiddleware);
      }

      const store = createStore(
        persistedReducer,
        initialState,
        applyMiddleware(...middlewares)
      );

      const persistor = persistStore(store);
      return { store, persistor };
    });
}
