import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

import { translate } from 'utility/utility';
import Icon, { ICONS } from 'components/Icon/Icon';
import loader from 'assets/icons/loader.svg';

import './loading-generic.png';
import './loading-screen-generic-mobile@2x.png';
import './loading-screen-map-results.png';
import './loading-results-mobile@2x.png';
import './loading-project-property.png';
import './loading-screen-project-mobile@2x.png';

import './Loader.scss';

export default class Loader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      timedout: false,
    };
  }

  componentDidMount() {
    const { loaderDelay, loaderTimeout } = this.props;
    this.delayFunction = setTimeout(() => {
      this.setState({
        show: true,
      });
    }, loaderDelay);

    this.timeoutFunction = setTimeout(() => {
      this.setState({
        timedout: true,
      });
    }, loaderTimeout);
  }

  componentWillUnmount() {
    clearTimeout(this.delayFunction);
    clearTimeout(this.timeoutFunction);
  }

  render() {
    const { loaderText, config } = this.props;
    const { show, timedout } = this.state;

    if (show) {
      if (timedout) {
        return (
          <div className={config.page ? 'loader pageLoader tile' : 'loader tile'}>
            {
              !config.disableSpinner
              && (
                <div className="loaderContent">
                  <img src={loader} alt="Loading spinner" />
                  {
                    config.page
                    && <h1 className="bumpTop">{translate(loaderText)}</h1>
                  }
                </div>
              )
            }
            <div className="loaderContent">
              <p className="darkGreyP">{translate('loader.longLoadMessage')}</p>
              <Button
                bsSize="large"
                onClick={() => window.location.reload()}>
                <Icon icon={ICONS.reload} size={15} colour="white" />
                {translate('common.reloadPage')}
              </Button>
            </div>
          </div>
        );
      }
      return (
        <div className={config.page ? 'loader pageLoader tile' : 'loader tile'}>
          {
            !config.disableSpinner
            && (
              <div className="text-center">
                <img src={loader} alt="Loading spinner" />
                {
                  config.page
                  && <h1 className="bumpTop">{translate(loaderText)}</h1>
                }
              </div>
            )
          }
        </div>
      );
    }
    return null;
  }
}

Loader.defaultProps = {
  config: {
    page: false,
    disableSpinner: false,
  },
  loaderText: 'loader.generalText',
  loaderDelay: 0,
  loaderTimeout: 5000,
};

Loader.propTypes = {
  config: PropTypes.shape({
    page: PropTypes.bool,
    disableSpinner: PropTypes.bool,
  }),
  loaderText: PropTypes.string,
  loaderDelay: PropTypes.number,
  loaderTimeout: PropTypes.number,
};
