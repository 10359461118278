import { isTalkjsOpen } from 'utility/messaging';

let intercomIsOpen = false;

const canUseDOM = !!(
  typeof window !== 'undefined'
  && window.document
  && window.document.createElement
);

export function updateIntercom(intercomIntegration, languageOverride) {
  if (canUseDOM && window.Intercom) {
    const { app_id, user } = intercomIntegration; // eslint-disable-line camelcase
    // eslint-disable-next-line camelcase
    window.intercomSettings = { app_id, ...user, language_override: languageOverride };
    window.Intercom('update', { ...window.intercomSettings });
  }
}

export function hideIntercomLauncher() {
  if (canUseDOM && window.Intercom && !intercomIsOpen) {
    window.Intercom('shutdown');
  }
}

function bootIntercom() {
  if (window.intercomSettings) {
    window.Intercom('boot', { ...window.intercomSettings });
    window.Intercom('onShow', () => {
      if (isTalkjsOpen()) {
        window.Intercom('hide');
      } else {
        intercomIsOpen = true;
      }
    });
    window.Intercom('onHide', () => { intercomIsOpen = false; });
    if (isTalkjsOpen()) {
      hideIntercomLauncher();
    }
  }
}

export function showIntercomLauncher() {
  if (canUseDOM && window.Intercom) {
    bootIntercom();
  }
}

export function initialiseIntercom(intercomIntegration, language) {
  if (canUseDOM && intercomIntegration) {
    let languageOverride = 'en';
    if (language) {
      if (language === 'zh-cn') {
        languageOverride = 'zh-CN';
      } else {
        languageOverride = language.slice(0, 2);
      }
    }

    if (!window.Intercom) {
      const intercomScript = document.createElement('script');
      intercomScript.type = 'text/javascript';
      intercomScript.async = true;
      intercomScript.src = 'https://widget.intercom.io/widget/' + intercomIntegration.app_id;
      document.head.appendChild(intercomScript);

      setTimeout(() => {
        if (window.Intercom) {
          // eslint-disable-next-line camelcase
          const { app_id, user } = intercomIntegration;
          // eslint-disable-next-line camelcase
          window.intercomSettings = { app_id, ...user, language_override: languageOverride };
          bootIntercom();
        }
      }, 3000);
    } else {
      updateIntercom(intercomIntegration, languageOverride);
    }
  }
}

export function shutdownIntercom() {
  if (canUseDOM && window.Intercom) {
    window.Intercom('shutdown');
    delete window.Intercom;
  }
}

export function isIntercomOpen() {
  return intercomIsOpen;
}
