import getCookieValue from 'utility/getCookieValue';
import { cookieType } from 'utility/constants';

function getAuthenticationToken() {
  let userId = getCookieValue(cookieType.userId);
  let agentId = getCookieValue(cookieType.agentId);
  let sellerId = getCookieValue(cookieType.sellerId);

  if (userId) userId = btoa(userId);
  if (agentId) agentId = btoa(agentId);
  if (sellerId) sellerId = btoa(sellerId);

  if (!userId && (!agentId || sellerId)) {
    return undefined;
  }

  return `(${userId}) (${agentId}) (${sellerId})`;
}

export default getAuthenticationToken;
