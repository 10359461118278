import { useSelector, useDispatch } from 'react-redux';
import { setPresentationMode } from 'components/LoggedInUser/LoggedInUserActions';

function usePresentationMode() {
  const isPresentationMode = useSelector((state) => state.user.presentationMode);

  const dispatch = useDispatch();
  const togglePresentationMode = () => dispatch(setPresentationMode(!isPresentationMode));

  return {
    isPresentationMode,
    togglePresentationMode
  };
}

export default usePresentationMode;
