import Cookies from 'universal-cookie';
import { cookieType } from './constants';

const cookieNames = {
  [cookieType.userId]: 'iv.user.id',
  [cookieType.userType]: 'iv.user.type',
  [cookieType.agentId]: 'iv.nwp.id',
  [cookieType.sellerId]: 'iv.dev.id'
};

export default function getCookieValue(type) {
  const cookies = new Cookies();

  const cookieName = cookieNames[type];
  if (!cookieName) {
    return '';
  }

  return cookies.get(cookieName) || '';
}
