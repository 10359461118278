export const DISPLAY_POPUP = 'DISPLAY_POPUP';
export const DISMISS_POPUP = 'DISMISS_POPUP';

export function displayPopup(popup, data) {
  return {
    type: DISPLAY_POPUP,
    popup,
    data,
  };
}

export function dismissPopup(popup) {
  return {
    type: DISMISS_POPUP,
    popup,
  };
}
