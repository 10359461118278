import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loadable from 'react-loadable';
import {
  Redirect, Route, Switch, useLocation, withRouter
} from 'react-router-dom';

import {
  parseQueryString,
  isNullOrEmpty,
  hasUserCookies,
  isSupportedLanguageCode
} from 'utility/utility';

import useLanguage from 'hooks/useLanguage';

import {
  setReferrerUrl,
  createPageLoadId,
  recordPageLoadThunk,
} from 'components/App/AppActions';

import { getReferrerUrl } from 'components/App/AppSelectors';
import { getLoggedInUserSubscriptionThunk } from 'components/LoggedInUser/LoggedInUserActions';
import LegacyPage from 'components/LegacyPage';

import ComponentLoader from './components/ComponentLoader/ComponentLoader';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import RefreshRoute from './components/RefreshRoute/RefreshRoute';

function getLoadable(loader) {
  return Loadable({
    loader,
    loading: ComponentLoader,
    delay: 300, // delay loading indicator for 300ms
    timeout: 5000 // timeout after 5 seconds
  });
}

const AsyncIndexPage = getLoadable(() => import(/* webpackChunkName: "index-page" */ 'components/IndexPage/IndexPage'));
const AsyncDashboardPage = getLoadable(() => import(/* webpackChunkName: "dashboard-page" */ 'components/DashboardPage/DashboardPage'));
const AsyncSearchPage = getLoadable(() => import(/* webpackChunkName: "search-page" */ 'components/SearchPage/SearchPage'));
const AsyncSearchResultsPage = getLoadable(() => import(/* webpackChunkName: "search-results-page" */ 'components/SearchResultsPage/SearchResultsPage'));
const AsyncProjectPage = getLoadable(() => import(/* webpackChunkName: "project-page" */ 'components/ProjectPage/ProjectPage'));
const AsyncStockPage = getLoadable(() => import(/* webpackChunkName: "stock-page" */ 'components/StockPage/StockPage'));
const AsyncMessagePage = getLoadable(() => import(/* webpackChunkName: "message-page" */ 'components/MessagePage/MessagePage'));
const AsyncStatusPage = getLoadable(() => import(/* webpackChunkName: "status-page" */ 'components/StatusPage/StatusPage'));
const AsyncBrochurePage = getLoadable(() => import(/* webpackChunkName: "brochure-page" */ 'components/BrochurePage/BrochurePage'));
const AsyncPortalSettingsPage = getLoadable(() => import(/* webpackChunkName: "portal-settings-page" */ 'components/PortalSettings/PortalSettings'));
const AsyncCreatePortalPage = getLoadable(() => import(/* webpackChunkName: "create-portal-page" */ 'components/CreatePortalPage/CreatePortalPage'));
const AsyncBrandedPortalPage = getLoadable(() => import(/* webpackChunkName: "branded-portal-page" */ 'components/BrandedPortalPage/BrandedPortalPage'));
const AsyncMapViewPage = getLoadable(() => import(/* webpackChunkName: "map-view-page" */ 'components/MapViewPage/MapViewPage'));
const AsyncDisclaimerPage = getLoadable(() => import(/* webpackChunkName: "disclaimer-page" */ 'components/Disclaimer/Disclaimer'));
const AsyncBlankProjectPage = getLoadable(() => import(/* webpackChunkName: "branded-portal-page" */ 'components/BlankProjectPage/BlankProjectPage'));
const AsyncVerificationPage = getLoadable(() => import(/* webpackChunkName: "verification-page" */ 'components/VerificationPage/VerificationPage'));
const AsyncSignupPage = getLoadable(() => import(/* webpackChunkName: "signup-page" */ 'components/SignupPage/SignupPage'));
const AsyncDirectMessagePage = getLoadable(() => import(/* webpackChunkName: "direct-message-page" */ 'components/DirectMessagePage/DirectMessagePage'));
const AsyncAgentProfilePage = getLoadable(() => import(/* webpackChunkName: "agent-profile-page" */ 'components/AgentProfilePage/AgentProfilePage'));

function Routes() {
  const dispatch = useDispatch();
  const referrerUrl = useSelector(getReferrerUrl);
  const { pathname, search } = useLocation();
  const { changeLanguage } = useLanguage();

  useEffect(() => {
    if (
      // arrived to neo from a link outside of a web browser (ie: email)
      referrerUrl === null
      && isNullOrEmpty(document.referrer)
    ) {
      dispatch(createPageLoadId());
      dispatch(recordPageLoadThunk());
      dispatch(setReferrerUrl(`${window.location.href}`));
    } else if (
      // arrived to neo from an outside link (ie: legacy page, linkedin link, etc)
      referrerUrl === null
      && !isNullOrEmpty(document.referrer)
      && document.referrer !== window.location.href
    ) {
      dispatch(setReferrerUrl(`${document.referrer}`));
      dispatch(createPageLoadId());
      dispatch(recordPageLoadThunk());
      dispatch(setReferrerUrl(`${window.location.href}`));
    } else if (
      // linked to another neo page from a neo page
      !isNullOrEmpty(referrerUrl)
      && referrerUrl !== window.location.href
    ) {
      dispatch(createPageLoadId());
      dispatch(recordPageLoadThunk());
      dispatch(setReferrerUrl(`${window.location.href}`));
    }

    const languageCode = parseQueryString(search).lang;
    if (languageCode && isSupportedLanguageCode(languageCode)) {
      changeLanguage(languageCode);
    }
  }, []);

  useEffect(() => {
    const isLegacyPage = /^\/legacy.*/.test(pathname);

    if (!isLegacyPage) {
      dispatch(createPageLoadId());
      dispatch(recordPageLoadThunk());
      dispatch(setReferrerUrl(`${window.location.href}`));

      if (hasUserCookies()) {
        dispatch(getLoggedInUserSubscriptionThunk());
      }
    }
  }, [pathname]);

  const matchProjectId = ':projectId';
  const matchStockId = ':stockId(Stock-\\d+|StockDistribution-\\d+-\\d+)';
  const matchBrochureId = ':brochureId(Brochure-[0-9a-f]{32})';
  const matchRemainingPathSegments = ':remainingPathSegments(.*)';
  const matchUserId = ':userId';
  const matchAgentId = ':agentId';

  return (
    <Switch>
      {/* redirect default URLs to front-page for the specific user type */}
      <Route exact path="/" component={AsyncIndexPage} />
      <Route exact path="/neo" component={AsyncIndexPage} />

      {/* Redirects to the legacy site should still be handled by react router
          so that navigation away from a page with a RouteLeavingGuard to a legacy
          page will correctly show the LeavePagePopup. */}
      <Route exact path={'/legacy' + matchRemainingPathSegments} component={LegacyPage} />

      {/* primary private routes */}
      <PrivateRoute exact path="/neo/Dashboard" component={AsyncDashboardPage} />
      <Route exact path="/neo/Search" component={AsyncSearchPage} />
      <PrivateRoute exact path={'/neo/Stock/' + matchStockId} component={AsyncStockPage} />
      <PrivateRoute exact path="/neo/Messages" component={AsyncMessagePage} />
      <PrivateRoute exact path="/neo/PortalSettings" component={AsyncPortalSettingsPage} />
      <PrivateRoute exact path="/neo/CreatePortal" component={AsyncCreatePortalPage} />
      <PrivateRoute exact path="/neo/Portal" component={AsyncBrandedPortalPage} />
      <PrivateRoute exact path="/neo/MapView" component={AsyncMapViewPage} />
      <PrivateRoute exact path="/neo/BlankProjectPage" component={AsyncBlankProjectPage} />
      <PrivateRoute exact path="/neo/Disclaimer" component={AsyncDisclaimerPage} />
      <PrivateRoute exact path={'/neo/DirectMessage/' + matchUserId} component={AsyncDirectMessagePage} />
      <PrivateRoute exact path={'/neo/AgentProfile/' + matchAgentId} component={AsyncAgentProfilePage} />

      {/* primary public routes */}
      <Route exact path={'/neo/Project/' + matchProjectId} component={AsyncProjectPage} />
      <Route exact path={'/neo/Brochure/' + matchBrochureId} component={AsyncBrochurePage} />
      <Route exact path="/neo/SearchResults/:location*" component={AsyncSearchResultsPage} />
      <Route exact path="/neo/Signup" component={AsyncSignupPage} />

      {/* email verification */}
      <Route exact path="/neo/Verify" component={AsyncVerificationPage} />

      {/* error routes */}
      <Route exact path="/neo/Status/:status" component={AsyncStatusPage} />

      {/* handle page refreshes */}
      <RefreshRoute path="/neo/Refresh" />

      {/* default routes */}
      <Redirect to="/neo/Status/InvalidPage" />

      <ComponentLoader />
    </Switch>
  );
}

export default withRouter(Routes);
