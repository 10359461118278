import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';

import australianFlag from 'assets/images/australian-flag.png';

export default function PhoneSection() {
  return (
    <Row className="text-center bumpTop phoneSection">
      <Col sm={12}>
        <img src={australianFlag} alt="Australia" />
        <p className="bumpTopHalf"><Translate id="common.australia" /></p>
        <div className="visible-xs">
          <a className="bumpTopHalf phoneNumber" href="mailto:support@investorist.com">
            support@investorist.com
          </a>
        </div>
        <div className="hidden-xs">
          <p className="bumpTopHalf phoneNumber">support@investorist.com </p>
        </div>
      </Col>
    </Row>
  );
}

