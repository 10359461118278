import { graphQLQuery } from './api';

export function loadProjectEventStatisticsApi(timeRangeFilter) {
  const queryString = `query projectEventStatistics($timeRangeFilter: TimeRangeFilterInput) {
    projectEventStatistics(timeRangeFilter: $timeRangeFilter) {    
      projectEventStatistics {
        id
        projectId
        connectionDetails {
          id
          status
          unlockCost {
            value
            type
          }
        }
        agentId
        userId
        portalId
        countryId
        state
        city
        projectEnquiryCount
        projectEnquiryCountOther
        uniqueProjectsForProjectEnquiryCountOther
        projectViewCount
        projectViewCountOther
        uniqueProjectsForProjectViewCountOther
        stockViewCount
        stockViewCountOther
        uniqueProjectsForStockViewCountOther
        projectPriceListDownloadCount
        projectDocumentDownloads
        stockDocumentDownloads {
          stockName
          documents
        }
        timeOfLastEvent
        conversationExternalIds
      }
      agents {
        id
        name
        logo {
          id
          mimeType
          url
        }
      }
      users {
        id
        firstName
        lastName
        profileImage {
          url
        }
      }
      projects {
        id
        name
        logo {
          id
          mimeType
          url
        }
        images { id url }
      }
      countries {
        id
        name
      }
      portals {
        id
        environment {
          id
          portalName
          logoMainImage { id mimeType url }
        }
        contact {
          firstName
          lastName
          organisation { id name }
          profileImage { url }
        }
      }
    }
  }`;
  const variables = {
    timeRangeFilter,
  };
  return graphQLQuery(queryString, variables);
}

export function projectEventCountsByTimeStepApi(eventTypeFilter, timeRangeFilter, projectIdFilter) {
  const queryString = `
  query projectEventCountsByTimeStep($eventTypeFilter: GraphableEventType!, $timeRangeFilter: TimeRangeFilterInput, $projectIdFilter: ID)
  {
    projectEventCountsByTimeStep(eventTypeFilter: $eventTypeFilter, timeRangeFilter: $timeRangeFilter, projectIdFilter: $projectIdFilter)
    {
      count
      distinctAgentsCount
      distinctProjectsCount
      timeRange { minValue maxValue }
    }
  }`;
  const variables = {
    eventTypeFilter,
    timeRangeFilter,
    projectIdFilter,
  };
  return graphQLQuery(queryString, variables);
}

export function unlockConnectionApi(id, projectId) {
  const mutationString = `
  mutation ($id: ID!, $projectId: ID) {
    unlockConnection (id: $id, projectId: $projectId)
  }`;
  const variables = {
    id,
    projectId,
  };
  return graphQLQuery(mutationString, variables);
}

export function recordDocumentDownload(entityId, documentId) {
  const mutationString = `
    mutation ($entityId: InvestoristID!, $documentId: InvestoristID!) {
      recordDocumentDownload (entityId: $entityId, documentId: $documentId)
    }
  `;

  const variables = {
    entityId,
    documentId
  };

  return graphQLQuery(mutationString, variables);
}

export function recordPriceListDownload(projectId) {
  const mutationString = `
    mutation ($projectId: InvestoristID!) {
      recordPriceListDownload (projectId: $projectId)
    }
  `;

  const variables = {
    projectId,
  };

  return graphQLQuery(mutationString, variables);
}
