import { graphQLQuery } from './api';

export function getCurrentUserAlertsApi() {
  const queryString = `
  query {
    currentUserAlerts {
      severity
      type
      ... on ProjectStaleAlert {
        count
      }
      ... on AccountBalanceAlert {
        balance
        currencyCode
      }
    }
  }`;
  const variables = {
  };
  return graphQLQuery(queryString, variables);
}
