import {
  SHOW_ALERT_POPUP,
  HIDE_ALERT_POPUP,
} from './AlertPopupActions';

function showAlertPopup(state, { tag, customData }) {
  return {
    ...state,
    tag,
    customData
  };
}

function hideAlertPopup(state) {
  return {
    ...state,
    tag: '',
    customData: null
  };
}

const initialState = {
  tag: '',
  customData: null
};

export default function AlertPopupReducers(state = initialState, action) {
  switch (action.type) {
    case SHOW_ALERT_POPUP:
      return showAlertPopup(state, action);
    case HIDE_ALERT_POPUP:
      return hideAlertPopup(state);

    default:
      return state;
  }
}
