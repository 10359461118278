import ApiError from 'services/ApiError';
import HttpResponseError from 'services/HttpResponseError';
import NoConnectionError from 'services/NoConnectionError';
import TimeoutError from 'services/TimeoutError';
import { initiateChatApi } from 'services/messaging.api';
import { showAlertPopup } from 'components/AlertPopup/AlertPopupActions';
import getCookieValue from 'utility/getCookieValue';
import { cookieType } from 'utility/constants';
import { history } from 'utility/history';
import { postRequest } from 'utility/requests';
import { externalUrlPath } from 'utility/redirect';

export const INITIATE_CHAT = 'INITIATE_CHAT';
export const RESEND_CHAT = 'RESEND_CHAT';
export const CHAT_FAILED = 'CHAT_FAILED';
export const UPDATE_CONVERSATIONS_COUNT = 'UPDATE_CONVERSATIONS_COUNT';
export const UPDATE_LEGACY_CONVERSATIONS_COUNT = 'UPDATE_LEGACY_CONVERSATIONS_COUNT';

export function initiateChat(id, message) {
  return {
    type: INITIATE_CHAT,
    id,
    message,
  };
}

export function resendChat() {
  return {
    type: RESEND_CHAT,
  };
}

export function chatFailed(error) {
  return {
    type: CHAT_FAILED,
    error,
  };
}

export function updateConversationsCount(count) {
  return {
    type: UPDATE_CONVERSATIONS_COUNT,
    count,
  };
}

export function updateLegacyConversationsCount(count) {
  return {
    type: UPDATE_LEGACY_CONVERSATIONS_COUNT,
    count,
  };
}

// Messaging thunks
export function initiateChatThunk(id, message, contextId) {
  return (dispatch) => {
    dispatch(initiateChat(id, message));
    return initiateChatApi(id, message, contextId)
      .catch((error) => {
        if (error instanceof HttpResponseError) {
          return error.response.json();
        }
        throw error;
      })
      .then((result) => {
        if (result.errors) {
          throw new ApiError(result.errors);
        }
        return result;
      })
      .then((result) => {
        dispatch(showAlertPopup('messaging-success'));
        return result.data.initiateChat;
      })
      .catch((error) => {
        if (error instanceof TimeoutError || error instanceof NoConnectionError) {
          if (history.location.pathname !== '/neo/Status/ConnectionIssue') {
            history.push('/neo/Status/ConnectionIssue');
          }
        } else {
          dispatch(chatFailed(error));
          dispatch(showAlertPopup('messaging-failure'));
          throw error;
        }
      });
  };
}

export function resendChatThunk() {
  return (dispatch, getState) => {
    const id = getState().messaging.chatId;
    const message = getState().messaging.chatMessage;
    dispatch(initiateChatThunk(id, message));
  };
}

// This will be removed after the legacy code moved to Neo API
export function getLegacyUnreadConversationsCountThunk() {
  return (dispatch) => {
    const userIdCookieValue = getCookieValue(cookieType.userId);
    const userTypeCookieValue = getCookieValue(cookieType.userType);
    const obj = {
      userIdCookieValue,
      userTypeCookieValue,
    };
    const url = new URL(externalUrlPath('/Message/UnreadCountNeo'));
    Object.keys(obj).forEach((key) => url.searchParams.append(key, obj[key]));
    postRequest(url)
      .then((result) => {
        if (!result.error) {
          dispatch(updateLegacyConversationsCount(result.unreadCount));
        }
      // eslint-disable-next-line
      }, (error) => {}); // needed to allow google bot to index our pages
  };
}
