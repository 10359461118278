import { createSelector } from 'reselect';

const environmentSelector = (state) => state.environment.environment;
const environmentLoadingSelector = (state) => state.environment.environmentLoading;
const environmentErrorSelector = (state) => state.environment.environmentError;

export const getEnvironment = createSelector(
  [environmentSelector],
  (state) => state,
);

export const getEnvironmentLoading = createSelector(
  [environmentLoadingSelector],
  (state) => state,
);

export const getEnvironmentError = createSelector(
  [environmentErrorSelector],
  (state) => state,
);
