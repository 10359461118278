import { loadProjectEventStatisticsApi, projectEventCountsByTimeStepApi, unlockConnectionApi } from 'services/event.api';
import { cacheProjectEventStatistics } from 'cache/CacheActions';

export const FETCH_PROJECT_EVENT_STATISTICS = 'FETCH_PROJECT_EVENT_STATISTICS';
export const FETCH_PROJECT_EVENT_STATISTICS_SUCCESS = 'FETCH_PROJECT_EVENT_STATISTICS_SUCCESS';
export const FETCH_PROJECT_EVENT_STATISTICS_FAILURE = 'FETCH_PROJECT_EVENT_STATISTICS_FAILURE';

export const FETCH_PROJECT_EVENT_COUNTS_BY_TIME_STEP = 'FETCH_PROJECT_EVENT_COUNTS_BY_TIME_STEP';
export const FETCH_PROJECT_EVENT_COUNTS_BY_TIME_STEP_SUCCESS = 'FETCH_PROJECT_EVENT_COUNTS_BY_TIME_STEP_SUCCESS';
export const FETCH_PROJECT_EVENT_COUNTS_BY_TIME_STEP_FAILURE = 'FETCH_PROJECT_EVENT_COUNTS_BY_TIME_STEP_FAILURE';

export const UNLOCK_CONNECTION_SUCCESS = 'UNLOCK_CONNECTION_SUCCESS';
export const UNLOCK_CONNECTION_FAILURE = 'UNLOCK_CONNECTION_FAILURE';

export function fetchProjectEventStatistics() {
  return {
    type: FETCH_PROJECT_EVENT_STATISTICS,
  };
}

export function fetchProjectEventStatisticsSuccess(ProjectEventStatisticsResult) {
  return {
    type: FETCH_PROJECT_EVENT_STATISTICS_SUCCESS,
    ProjectEventStatisticsResult,
  };
}

export function fetchProjectEventStatisticsFailure(error) {
  return {
    type: FETCH_PROJECT_EVENT_STATISTICS_FAILURE,
    error,
  };
}

export function fetchProjectEventCountsByTimeStep() {
  return {
    type: FETCH_PROJECT_EVENT_COUNTS_BY_TIME_STEP,
  };
}

export function fetchProjectEventCountsByTimeStepSuccess(eventType, countsByTimeStep) {
  return {
    type: FETCH_PROJECT_EVENT_COUNTS_BY_TIME_STEP_SUCCESS,
    eventType,
    countsByTimeStep,
  };
}

export function fetchProjectEventCountsByTimeStepFailure(error) {
  return {
    type: FETCH_PROJECT_EVENT_COUNTS_BY_TIME_STEP_FAILURE,
    error,
  };
}

export function unlockConnectionSuccess() {
  return {
    type: UNLOCK_CONNECTION_SUCCESS,
  };
}

export function unlockConnectionFailure() {
  return {
    type: UNLOCK_CONNECTION_FAILURE,
  };
}

export function fetchProjectEventStatisticsThunk(timeRangeFilter) {
  return (dispatch) => {
    dispatch(fetchProjectEventStatistics());
    loadProjectEventStatisticsApi(timeRangeFilter)
      .then((result) => {
        if (result.error) {
          dispatch(fetchProjectEventStatisticsFailure(new Error(result.error)));
        } else {
          dispatch(cacheProjectEventStatistics(result.data.projectEventStatistics));
          dispatch(fetchProjectEventStatisticsSuccess(result.data.projectEventStatistics));
        }
      }, (error) => {
        dispatch(fetchProjectEventStatisticsFailure(error));
      });
  };
}

export function fetchProjectEventCountsByTimeStepThunk(
  eventTypeFilter,
  timeRangeFilter,
  projectIdFilter,
) {
  return (dispatch) => {
    dispatch(fetchProjectEventCountsByTimeStep());
    projectEventCountsByTimeStepApi(eventTypeFilter, timeRangeFilter, projectIdFilter)
      .then((result) => {
        if (result.error) {
          dispatch(fetchProjectEventCountsByTimeStepFailure(new Error(result.error)));
        } else {
          const data = result.data.projectEventCountsByTimeStep;
          dispatch(fetchProjectEventCountsByTimeStepSuccess(eventTypeFilter, data));
        }
      }, (error) => {
        dispatch(fetchProjectEventCountsByTimeStepFailure(error));
      });
  };
}

export function unlockConnectionThunk(onSuccess, onFailure, connectionId, projectId) {
  return (dispatch, getState) => {
    const timeRangeFilter = getState().userSettings.dashboardTimeRangeFilter;

    unlockConnectionApi(connectionId, projectId)
      .then((result) => {
        if (result.error) {
          dispatch(unlockConnectionFailure(new Error(result.error)));
          onFailure({ connectionId, projectId });
          return false;
        }

        if (result.data.unlockConnection === false) {
          onFailure({ connectionId, projectId });
          return false;
        }

        dispatch(unlockConnectionSuccess());
        onSuccess();
        return true;
      })
      .catch((error) => {
        dispatch(unlockConnectionFailure(error));
        onFailure({ connectionId, projectId });
      })
      .then((wasSuccess) => {
        if (wasSuccess) {
          dispatch(fetchProjectEventStatisticsThunk(timeRangeFilter));
        }
      });
  };
}
