import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Translate, withLocalize } from 'react-localize-redux';
import {
  Modal,
  Button,
  Row,
  Col,
} from 'react-bootstrap';
import isEqual from 'lodash/isEqual';

import Icon, { ICONS } from 'components/Icon/Icon';
import moment from 'moment';
import { setTrialPopUpShownDate as setTrialPopUpShownDateAction, sendTrialUpgradeRequestThunk } from 'components/LoggedInUser/LoggedInUserActions';
import {
  getTrialPopUpShownDate,
  getCurrentOnboardingPage,
  getTrialUpgradeResult,
  getTrialUpgradeRequestLoading,
} from 'components/LoggedInUser/LoggedInUserSelectors';
import { maxAgentOnboardingPages } from 'utility/constants';
import { PERMISSION, hasPermission } from 'utility/permissions';
import PhoneSection from 'components/PhoneSection/PhoneSection';
import { customerCallbackRequestThunk } from 'components/Customer/CustomerActions';
import AlertPopup from 'components/AlertPopup/AlertPopup';
import colours from 'components/App/InvestoristColours.scss';

const currentDate = moment().format('YYYY-MM-DD');
const savedOnboardingPageAgent = localStorage.getItem('lastOnboardingPageAgent');

class TrialPopup extends Component {
  static isTrialExtended(props) {
    const { loggedInUser, trialUpgradeResult } = props;

    const trialUpgradeExpiryDate = trialUpgradeResult && trialUpgradeResult.data.expiryDate;

    return loggedInUser
      && loggedInUser.subscription
      && loggedInUser.subscription.expiryDate !== trialUpgradeExpiryDate;
  }

  static shouldShow(props) {
    const { trialPopUpShownDate, loggedInUser, currentOnboardingPage } = props;

    const canShowTrialPopup = trialPopUpShownDate !== currentDate;

    const canSeeOnboarding = hasPermission(loggedInUser, PERMISSION.CAN_SEE_ONBOARDING);

    const seenAllOnboarding = currentOnboardingPage >= maxAgentOnboardingPages
      || savedOnboardingPageAgent >= maxAgentOnboardingPages;

    return canShowTrialPopup && (!canSeeOnboarding || seenAllOnboarding);
  }

  static getDerivedStateFromProps(props, state) {
    const newState = { };

    if (!isEqual(props.trialUpgradeResult, state.lastTrialUpgradeResult)) {
      newState.lastTrialUpgradeResult = props.trialUpgradeResult;
    }

    if (props.trialUpgradeRequestLoading !== state.lastIsLoading) {
      newState.lastIsLoading = props.trialUpgradeRequestLoading;
    }

    const isTrialExtended = TrialPopup.isTrialExtended(props);
    if (isTrialExtended !== state.lastIsTrialExtended) {
      newState.lastIsTrialExtended = isTrialExtended;
    }

    const propsShouldShow = TrialPopup.shouldShow(props);
    if (propsShouldShow !== state.shouldShow) {
      newState.shouldShow = propsShouldShow;
    }

    return Object.keys(newState).length > 0 ? newState : null;
  }

  constructor(props) {
    super(props);

    this.state = {
      lastTrialUpgradeResult: props.trialUpgradeResult, // eslint-disable-line
      shouldShow: TrialPopup.shouldShow(props), // eslint-disable-line
      lastIsLoading: false, // eslint-disable-line
      lastIsTrialExtended: TrialPopup.isTrialExtended(props) // eslint-disable-line
    };

    this.onHide = this.onHide.bind(this);
    this.onClickTrialUpgrade = this.onClickTrialUpgrade.bind(this);
    this.customerCallbackRequest = this.customerCallbackRequest.bind(this);
  }

  onHide() {
    const { setTrialPopUpShownDate } = this.props;
    const { trialUpgradeResult } = this.state;
    setTrialPopUpShownDate(currentDate);
    this.setState({
      shouldShow: false,
    });
    if (trialUpgradeResult && trialUpgradeResult.succeeded === true) {
      window.location.reload();
    }
  }

  onClickTrialUpgrade() {
    const { sendTrialUpgrageRequest, trialUpgradeRequestLoading } = this.props;
    const { trialUpgradeResult } = this.state;
    if (trialUpgradeResult) {
      this.onHide();
    } else {
      this.setState({
        isLoading: trialUpgradeRequestLoading,
      });
      sendTrialUpgrageRequest();
    }
  }

  customerCallbackRequest(callBackType) {
    const { customerCallbackRequest } = this.props;
    customerCallbackRequest(callBackType);
    this.onHide();
  }

  render() {
    const {
      translate,
      loggedInUser,
    } = this.props;

    if (
      loggedInUser
      && loggedInUser.subscription
      && (
        (
          loggedInUser.subscription.type === 'TRIAL'
          && !loggedInUser.subscription.upgradeRequested
        )
        || loggedInUser.subscription.type === 'EXPIRED_TRIAL'
      )
    ) {
      const {
        isLoading, isTrialExtended, shouldShow, trialUpgradeResult
      } = this.state;
      const trialExpired = loggedInUser.subscription.type === 'EXPIRED_TRIAL';
      let heading;
      let contentOne;
      let contentTwo;
      let iconName;
      let buttonLabel;
      if (trialUpgradeResult && trialUpgradeResult.succeeded) {
        buttonLabel = 'trial.upgradeRequestSuccess.buttonLabel';
        iconName = ICONS.tickCircle;
        heading = <Translate id="trial.upgradeRequestSuccess.heading" />;
        contentOne = 'trial.upgradeRequestSuccess.contentOne';
        contentTwo = isTrialExtended ? 'trial.upgradeRequestSuccess.contentTwo' : '';
      } else if (trialExpired) {
        contentOne = 'trial.expired.contentOne';
        if (loggedInUser.subscription.upgradeRequested) {
          heading = <Translate id="common.getInTouch" />;
          contentTwo = 'trial.upgradeRequested.content';
          iconName = ICONS.phone;
        } else {
          buttonLabel = 'trial.buttonLabel';
          heading = <Translate id="trial.expired.heading" />;
          contentTwo = 'trial.expired.contentTwo';
          iconName = ICONS.exclamation;
        }
      } else {
        const trialEndDate = moment(loggedInUser.subscription.expiryDate);
        const currDate = moment(new Date());
        const trialExpiryDays = Math.round(moment.duration(trialEndDate.diff(currDate)).asDays());
        const trialHeading = trialExpiryDays > 1 ? 'trial.heading+plural' : 'trial.heading';
        heading = <Translate id={trialHeading} data={{ days: trialExpiryDays }} />;
        contentOne = 'trial.contentOne';
        contentTwo = 'trial.contentTwo';
        iconName = ICONS.exclamation;
        buttonLabel = 'trial.buttonLabel';
      }
      return (
        <div>
          <Modal
            className="trialPopUp"
            show={shouldShow}
            onHide={this.onHide}>
            <Modal.Header closeButton />
            <Modal.Body>
              <div className="text-center">
                <Icon
                  icon={iconName}
                  colour={colours.primaryAccent}
                  size={50} />
                <h1 className="text-center bumpTop">
                  {heading}
                </h1>
                <p className="text-center bumpTop"><Translate id={contentOne} /></p>
                <p className="text-center bumpTop">
                  {
                    contentTwo !== ''
                    && <Translate id={contentTwo} />
                  }
                </p>
                <div className="sectionHeading">
                  {
                    loggedInUser.subscription.upgradeRequested
                    && (
                      <div>
                        <div className="text-center bumpTop">
                          <p className="boldFont darkGreyP">
                            <Translate id="common.phoneSectionHeading" />
                            :
                          </p>
                        </div>
                        <PhoneSection />
                        <Row className="sectionHeading">
                          <Col xs={12} className="text-center">
                            <Button bsSize="large" onClick={() => { this.customerCallbackRequest('EXPIRED_TRIAL'); }}>
                              <Translate id="common.requestCallBack" />
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    )
                  }
                  {
                    !loggedInUser.subscription.upgradeRequested
                    && (
                      <Button
                        disabled={isLoading}
                        onClick={() => { this.onClickTrialUpgrade(); }}>
                        <Translate id={buttonLabel} />
                      </Button>
                    )
                  }
                </div>
                {
                  !trialExpired && !trialUpgradeResult
                    && (
                      <Button className="buttonAsLink greenText sectionHeading" onClick={this.onHide}>
                        <Translate id="trial.continueTrial" />
                      </Button>
                    )
                }
              </div>
            </Modal.Body>
          </Modal>
          <AlertPopup
            tag="customer-callback-request-success"
            heading={translate('alertPopup.customerCallBack.success.heading')}
            content={translate('alertPopup.customerCallBack.success.content')}
            icon={{
              data: ICONS.sent,
              size: 50,
              colour: colours.primaryAccent,
            }} />
          <AlertPopup
            tag="customer-callback-request-failure"
            heading={translate('alertPopup.customerCallBack.failure.heading')}
            content={translate('alertPopup.customerCallBack.failure.content')}
            icon={{
              data: ICONS.failure,
              size: 50,
              colour: colours.alternate,
            }} />
        </div>
      );
    }
    return null;
  }
}

TrialPopup.defaultProps = {
  trialUpgradeResult: null,
  loggedInUser: null,
};

TrialPopup.propTypes = {
  setTrialPopUpShownDate: PropTypes.func.isRequired,
  // eslint-disable-next-line
  trialPopUpShownDate: PropTypes.string.isRequired,
  // eslint-disable-next-line
  currentOnboardingPage: PropTypes.number.isRequired,
  loggedInUser: PropTypes.object,
  sendTrialUpgrageRequest: PropTypes.func.isRequired,
  trialUpgradeResult: PropTypes.object,
  trialUpgradeRequestLoading: PropTypes.bool.isRequired,
  customerCallbackRequest: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    trialPopUpShownDate: getTrialPopUpShownDate(state),
    currentOnboardingPage: getCurrentOnboardingPage(state),
    trialUpgradeResult: getTrialUpgradeResult(state),
    trialUpgradeRequestLoading: getTrialUpgradeRequestLoading(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setTrialPopUpShownDate: (date) => dispatch(setTrialPopUpShownDateAction(date)),
    sendTrialUpgrageRequest: () => dispatch(sendTrialUpgradeRequestThunk()),
    customerCallbackRequest: (callBackType) => dispatch(customerCallbackRequestThunk(callBackType)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(TrialPopup));

