import React from 'react';
import PropTypes from 'prop-types';

import useBranding from 'hooks/useBranding';
import HomeLogo from 'components/Navigation/HomeLogo';
import MainNavigationBar from 'components/Navigation/MainNavigationBar';
import AdministrationBar from 'components/Navigation/AdministrationBar/AdministrationBar';

import './Layout.scss';

function Layout({ children }) {
  useBranding();

  return (
    <div className="layout">
      <div className="layout-header-wrapper">
        <div className="layout-header">
          <div className="layout-header-left">
            <HomeLogo />
            <MainNavigationBar className="hidden-xs" />
          </div>
          <div className="layout-header-right">
            <AdministrationBar />
          </div>
        </div>
      </div>
      <div className="layout-content">
        {children}
      </div>
      <div className="layout-footer visible-xs">
        <MainNavigationBar />
      </div>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.any.isRequired,
};

export default Layout;
