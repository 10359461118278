import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Router } from 'react-router-dom';

import Routes from 'components/Routes/Routes';
import Onboarding from 'components/Onboarding/Onboarding';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import { requestIntegrationServicesThunk } from 'components/Integration/IntegrationActions';
import { hasUserCookies, isOnBrochurePage } from 'utility/utility';
import { history } from 'utility/history';
import { clearCache } from 'utility/cache';
import ConnectionIssueBanner from 'components/ConnectionIssueBanner/ConnectionIssueBanner';
import AcceptCookiesPopup from 'components/AcceptCookiesPopup/AcceptCookiesPopup';
import ActiveCampaignSiteTracker from 'components/ActiveCampaignSiteTracker/ActiveCampaignSiteTracker';
import { setConnectionUp, fetchLoggedInUserDetailsThunk } from 'components/LoggedInUser/LoggedInUserActions';
import { getLoggedInUser, getLoggedInUserLoading, getLoggedInUserError } from 'components/LoggedInUser/LoggedInUserSelectors';
import { getIntegrationServicesLoading, getIntegrationServicesError } from 'components/Integration/IntegrationSelectors';
import { fetchEnvironmentThunk } from 'components/Environment/EnvironmentActions';
import { fetchPortalThunk } from 'components/Portal/PortalActions';
import { getEnvironmentLoading, getEnvironmentError } from 'components/Environment/EnvironmentSelectors';
import Error from 'components/Error/Error';
import Loader from 'components/Loader/Loader';
import LimitedAccessBanner from 'components/LimitedAccessBanner/LimitedAccessBanner';
import TrialPopup from 'components/Trial/TrialPopup/TrialPopup';
import TrialExpiredBanner from 'components/Trial/TrialExpiredBanner/TrialExpiredBanner';
import { fetchCountriesThunk } from 'components/Countries/CountriesActions';
import SignupDialog from 'components/SignupDialog/SignupDialog';
import PublicProjectEnquiryDialog from 'components/PublicProjectEnquiryDialog/PublicProjectEnquiryDialog';

import Layout from 'components/Layout/Layout';

// This is included here so that webpack properly packages the icon files.
import 'assets/icons/favicon.png';
import 'assets/icons/webclip.png';

import EnvironmentFavicon from './components/EnvironmentFavicon/EnvironmentFavicon';
import Title from './components/Title/Title';
import './InvestoristBaseStyle.scss';
import './AppStyle.scss';
import './InvestoristColours.scss';

const integrationServicesReloadWaitMilliseconds = 600000;

class App extends Component {
  static getDerivedStateFromProps(props, state) {
    const { loggedInUser } = props;

    const newState = { };

    if (loggedInUser?.id !== state.lastLoggedInUserId) {
      clearCache();
      newState.lastLoggedInUserId = loggedInUser?.id;
    }

    return (Object.keys(newState).length > 0) ? newState : null;
  }

  static renderContent(content) {
    return (
      <Router history={history}>
        <ScrollToTop>
          <div>
            {content}
          </div>
        </ScrollToTop>
      </Router>
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      lastLoggedInUserId: props.loggedInUser?.id,
    };
  }

  componentDidMount() {
    const {
      connectionUp,
      fetchCountries,
      fetchEnvironment,
      fetchPortal,
      getLoggedInUserDetails,
      integrationServicesError,
      requestIntegrationServices
    } = this.props;

    const isBrochurePage = isOnBrochurePage();

    requestIntegrationServices(!isBrochurePage);
    connectionUp();
    fetchCountries();

    if (!isBrochurePage && hasUserCookies()) {
      getLoggedInUserDetails();
      fetchPortal();
    }

    fetchEnvironment();

    if (integrationServicesError) {
      this.integrationServicesReloadInterval = setInterval(
        () => requestIntegrationServices(!isOnBrochurePage()),
        integrationServicesReloadWaitMilliseconds
      );
    }
  }

  componentDidUpdate() {
    const {
      integrationServicesError,
      requestIntegrationServices
    } = this.props;

    if (integrationServicesError) {
      if (!this.integrationServicesReloadInterval) {
        this.integrationServicesReloadInterval = setInterval(
          () => requestIntegrationServices(!isOnBrochurePage()),
          integrationServicesReloadWaitMilliseconds
        );
      }
    } else if (this.integrationServicesReloadInterval) {
      clearInterval(this.integrationServicesReloadInterval);
      this.intervalId = null;
    }
  }

  componentWillUnmount() {
    if (this.integrationServicesReloadInterval) {
      clearInterval(this.integrationServicesReloadInterval);
    }
  }

  render() {
    const {
      loggedInUser,
      loggedInUserError,
      loggedInUserLoading,
      environmentError,
      environmentLoading,
    } = this.props;

    const isNeoStatusPage = /neo\/status/i.test(window.location.pathname);
    const isNeoLoginStatusPage = /neo\/status\/login/i.test(window.location.pathname);

    const isLoggedInUserRequired = !isNeoStatusPage;
    const isEnvironmentRequired = !isNeoStatusPage || isNeoLoginStatusPage;

    if (
      (isEnvironmentRequired && environmentError)
      || (isLoggedInUserRequired && loggedInUserError)
    ) {
      return App.renderContent(<Error connectionError fullPage />);
    }

    if (
      (isEnvironmentRequired && environmentLoading)
      || (isLoggedInUserRequired && loggedInUserLoading)
    ) {
      return App.renderContent(<Loader config={{ page: true }} />);
    }

    const isPublic = !loggedInUser;

    return App.renderContent(
      <Title>
        <>
          <EnvironmentFavicon />
          <ActiveCampaignSiteTracker />
          <div>
            <LimitedAccessBanner loggedInUser={loggedInUser} />
            <ConnectionIssueBanner />
            <AcceptCookiesPopup isStatusPage={isNeoStatusPage} />
            <TrialExpiredBanner loggedInUser={loggedInUser} />
            <TrialPopup loggedInUser={loggedInUser} />
            <Onboarding loggedInUser={loggedInUser} />
            <Layout>
              <Routes />
            </Layout>
            {isPublic && (
              <>
                <SignupDialog />
                <PublicProjectEnquiryDialog />
              </>
            )}
          </div>
        </>
      </Title>
    );
  }
}

App.defaultProps = {
  integrationServicesError: null,
  loggedInUserError: null,
  environmentError: null,
  loggedInUser: null,
};

App.propTypes = {
  integrationServicesError: PropTypes.objectOf(Error),
  loggedInUserLoading: PropTypes.bool.isRequired,
  loggedInUserError: PropTypes.objectOf(Error),
  environmentLoading: PropTypes.bool.isRequired,
  environmentError: PropTypes.objectOf(Error),
  requestIntegrationServices: PropTypes.func.isRequired,
  connectionUp: PropTypes.func.isRequired,
  loggedInUser: PropTypes.object,
  getLoggedInUserDetails: PropTypes.func.isRequired,
  fetchEnvironment: PropTypes.func.isRequired,
  fetchPortal: PropTypes.func.isRequired,
  fetchCountries: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    integrationServicesLoading: getIntegrationServicesLoading(state),
    integrationServicesError: getIntegrationServicesError(state),
    loggedInUserLoading: getLoggedInUserLoading(state),
    loggedInUserError: getLoggedInUserError(state),
    environmentLoading: getEnvironmentLoading(state),
    environmentError: getEnvironmentError(state),
    loggedInUser: getLoggedInUser(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    requestIntegrationServices: (useAuthenticationToken) => dispatch(
      requestIntegrationServicesThunk(useAuthenticationToken)
    ),
    connectionUp: () => dispatch(setConnectionUp()),
    getLoggedInUserDetails: () => dispatch(fetchLoggedInUserDetailsThunk()),
    fetchEnvironment: () => dispatch(fetchEnvironmentThunk()),
    fetchPortal: () => dispatch(fetchPortalThunk()),
    fetchCountries: () => dispatch(fetchCountriesThunk()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
