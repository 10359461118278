import { distributedProjectStatisticsApi, setEnvironmentApi } from 'services/portal.api';
import { showAlertPopup } from 'components/AlertPopup/AlertPopupActions';
import { fetchEnvironmentThunk } from 'components/Environment/EnvironmentActions';

export const SET_ENVIRONMENT_SUCCESS = 'SET_ENVIRONMENT_SUCCESS';
export const SET_ENVIRONMENT_FAILURE = 'SET_ENVIRONMENT_FAILURE';
export const SET_ENVIRONMENT_MUTATIONS = 'SET_ENVIRONMENT_MUTATIONS';
export const FETCH_DISTRIBUTED_PROJECT_STATISTICS = 'FETCH_DISTRIBUTED_PROJECT_STATISTICS';
export const FETCH_DISTRIBUTED_PROJECT_STATISTICS_SUCCESS = 'FETCH_DISTRIBUTED_PROJECT_STATISTICS_SUCCESS';
export const FETCH_DISTRIBUTED_PROJECT_STATISTICS_FAILURE = 'FETCH_DISTRIBUTED_PROJECT_STATISTICS_FAILURE';
export const SET_VALIDATE_ENVIRONMENT_MUTATIONS = 'SET_VALIDATE_ENVIRONMENT_MUTATIONS';
export const SET_SELECTED_TAB = 'SET_SELECTED_TAB';

export function setEnvironmentSuccess() {
  return {
    type: SET_ENVIRONMENT_SUCCESS,
  };
}

export function setEnvironmentFailure() {
  return {
    type: SET_ENVIRONMENT_FAILURE,
  };
}

export function setEnvironmentMutations(environmentMutations) {
  return {
    type: SET_ENVIRONMENT_MUTATIONS,
    environmentMutations,
  };
}

export function setValidateEnvironmentMutations(validate) {
  return {
    type: SET_VALIDATE_ENVIRONMENT_MUTATIONS,
    validate,
  };
}

export function setSelectedTab(tab) {
  return {
    type: SET_SELECTED_TAB,
    tab,
  };
}

export function fetchDistributedProjectStatistics() {
  return {
    type: FETCH_DISTRIBUTED_PROJECT_STATISTICS,
  };
}

export function fetchDistributedProjectStatisticsSuccess(projectDistributionStatistics) {
  return {
    type: FETCH_DISTRIBUTED_PROJECT_STATISTICS_SUCCESS,
    projectDistributionStatistics,
  };
}

export function fetchDistributedProjectStatisticsFailure(error) {
  return {
    type: FETCH_DISTRIBUTED_PROJECT_STATISTICS_FAILURE,
    error,
  };
}

export function fetchDistributedProjectStatisticsThunk() {
  return (dispatch) => {
    dispatch(fetchDistributedProjectStatistics());
    distributedProjectStatisticsApi()
      .then((result) => {
        if (result.error) {
          dispatch(fetchDistributedProjectStatisticsFailure(new Error(result.error)));
        } else {
          dispatch(fetchDistributedProjectStatisticsSuccess(result.data.distributedProjectStatistics)); // eslint-disable-line
        }
      }, (error) => {
        dispatch(fetchDistributedProjectStatisticsFailure(error));
      });
  };
}

function showFailurePopup(dispatch, error) {
  dispatch(setEnvironmentFailure(error));
  dispatch(showAlertPopup('set-environment-failure'));
}

function showSuccessPopup(dispatch) {
  dispatch(setEnvironmentSuccess());
  dispatch(showAlertPopup('set-environment-success'));
  dispatch(fetchEnvironmentThunk());
}

export function updateEnvironment(shouldShowSuccessPopup = false) {
  return (dispatch, getState) => new Promise((resolve, reject) => {
    const environmentMutations = getState().portalSettings.environmentMutations; // eslint-disable-line

    setEnvironmentApi(
      environmentMutations.portalName,
      environmentMutations.homepageType,
      environmentMutations.homepageHeading,
      environmentMutations.disclaimer,
      environmentMutations.mainBrandColour,
      environmentMutations.secondaryBrandColour,
      environmentMutations.logoMainImage ? environmentMutations.logoMainImage.id : null,
      environmentMutations.logoWhiteImage ? environmentMutations.logoWhiteImage.id : null,
      environmentMutations.loginBackgroundImage
        ? environmentMutations.loginBackgroundImage.id : null,
      environmentMutations.homepageBackgroundImage
        ? environmentMutations.homepageBackgroundImage.id : null,
      environmentMutations.faviconImage ? environmentMutations.faviconImage.id : null,
      environmentMutations.webclipImage ? environmentMutations.webclipImage.id : null,
      environmentMutations.hasExpandedHeader,
    ).then((result) => {
      if (result.error) {
        showFailurePopup(dispatch, result.error);

        reject(result.error);
      }

      if (shouldShowSuccessPopup) {
        showSuccessPopup(dispatch);
      }

      resolve();
    }).catch((error) => {
      showFailurePopup(dispatch, error);

      reject(error);
    });
  });
}

export function assetUploadConfirmedThunk(assetType, assetId) {
  return (dispatch, getState) => {
    const { environmentMutations } = getState().portalSettings;

    environmentMutations[assetType] = {
      ...environmentMutations[assetType],
      id: assetId,
    };

    dispatch(setEnvironmentMutations(environmentMutations));
  };
}
