import {
  SHOW_PUBLIC_PROJECT_ENQUIRY_DIALOG,
  HIDE_PUBLIC_PROJECT_ENQUIRY_DIALOG
} from './PublicProjectEnquiryDialogActions';

const initialState = {
  isVisible: false,
  projectId: null,
  projectName: null
};

function showPublicProjectEnquiryDialog(state, action) {
  return {
    ...state,
    isVisible: true,
    projectId: action.projectId,
    projectName: action.projectName
  };
}

function hidePublicProjectEnquiryDialog(state) {
  return {
    ...state,
    ...initialState
  };
}

export default function PublicProjectEnquiryDialogReducers(state = initialState, action) {
  switch (action.type) {
    case SHOW_PUBLIC_PROJECT_ENQUIRY_DIALOG:
      return showPublicProjectEnquiryDialog(state, action);

    case HIDE_PUBLIC_PROJECT_ENQUIRY_DIALOG:
      return hidePublicProjectEnquiryDialog(state);

    default:
      return state;
  }
}
