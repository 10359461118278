
import useActiveCampaignSiteTrackingScript from 'hooks/useActiveCampaignSiteTrackingScript';

function ActiveCampaignSiteTracker() {
  useActiveCampaignSiteTrackingScript();

  return null;
}

export default ActiveCampaignSiteTracker;
