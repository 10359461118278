import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Badge } from 'react-bootstrap';

import Icon from 'components/Icon/Icon';

class NavLink extends Component {
  render() {
    const {
      to,
      icon,
      text,
      badge,
      onClick,
      className
    } = this.props;

    return (
      <li className={className}>
        <Link
          to={to}
          onClick={onClick}>
          <div className="icon-text-content-wrapper">
            {icon && <Icon icon={icon} size={15} /> }
            <span>{text}</span>
          </div>
          <Badge>{badge}</Badge>
        </Link>
      </li>
    );
  }
}

NavLink.defaultProps = {
  text: '',
  badge: '',
  icon: null,
  to: null,
  onClick: null,
  className: null,
};

NavLink.propTypes = {
  text: PropTypes.string,
  badge: PropTypes.string,
  icon: PropTypes.string,
  to: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default NavLink;
