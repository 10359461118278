import Talk from 'talkjs';

import getCookieValue from 'utility/getCookieValue';
import { dispatch, isCurrentUserASeller } from 'utility/utility';
import { externalUrlPath } from 'utility/redirect';
import {
  isIntercomOpen,
  hideIntercomLauncher,
  showIntercomLauncher,
} from 'utility/intercom';
import { cookieType } from 'utility/constants';
import { updateConversationsCount } from 'components/Messaging/MessagingActions';

let talkjsSession = null;
let popup = null;
let inbox = null;
let talkjsIsOpen = false;
const roiPanels = {};

function manageRegistrationOfInterestPanel(conversation) {
  const ui = popup === null ? inbox : popup;
  const roiPanel = roiPanels[conversation.id];

  if (conversation.custom && conversation.custom.roiRequest) {
    if (!roiPanel) {
      const urlPath = '/registrationOfInterest/request?conversationId=' + encodeURIComponent(conversation.id) + '&userId=' + encodeURIComponent(getCookieValue(cookieType.userId)) + '&sellerId=' + encodeURIComponent(getCookieValue(cookieType.sellerId));
      ui.createHtmlPanel({
        url: externalUrlPath(urlPath),
        show: true,
        conversation: conversation.id,
      }).then((htmlPanel) => {
        roiPanels[conversation.id] = htmlPanel;
      });
    }
  } else if (roiPanel) {
    roiPanel.destroy();
    roiPanels[conversation.id] = null;
  }
}

function conversationHandler(conversations) {
  dispatch(updateConversationsCount(conversations.length));
}

function popupVisible() {
  const element = document.querySelector('#__talkjs_popup_container');
  return element && element.offsetParent;
}

function messageHandler(message) {
  if (
    talkjsSession
    && !talkjsIsOpen
    && !inbox
    && !message.isByMe
    && message.senderId
    && !popupVisible()
    && !isIntercomOpen()
  ) {
    const conversation = talkjsSession.getOrCreateConversation(message.conversation.id);
    popup = talkjsSession.createPopup(conversation, {
      chatTitleMode: 'subject',
      chatSubtitleMode: 'participants',
      keepOpen: false,
      launcher: 'never',
      showCloseInHeader: true,
    });
    popup.on('open', () => {
      if (isCurrentUserASeller()) {
        manageRegistrationOfInterestPanel(conversation);
      }
    });
    popup.on('close', () => { talkjsIsOpen = false; showIntercomLauncher(); });
    popup.mount();
    talkjsIsOpen = true;
    hideIntercomLauncher();
  }

  if (isCurrentUserASeller()) {
    manageRegistrationOfInterestPanel(message.conversation);
  }
}

export function createTalkjsSession(talkJsIntegration) {
  if (talkjsSession) return;

  const user = new Talk.User(talkJsIntegration.userId);
  talkjsSession = new Talk.Session({
    appId: talkJsIntegration.appId,
    me: user,
    signature: talkJsIntegration.signature,
  });

  talkjsSession.on('message', messageHandler);
  talkjsSession.unreads.on('change', conversationHandler);
}

export function initialiseMessaging(talkJsIntegration) {
  if (talkJsIntegration) {
    Talk.ready.then(() => {
      createTalkjsSession(talkJsIntegration);
    });
  }
}

export function inboxOn(id, conversationId) {
  if (popup) {
    popup.destroy();
    popup = null;
  }

  if (!inbox) {
    inbox = talkjsSession.createInbox({
      chatTitleMode: 'subject',
      chatSubtitleMode: 'participants',
      feedConversationTitleMode: 'subject',
      feedConversationSubtitleMode: 'participants',
      useBrowserHistory: false,
    });
    inbox.on('conversationSelected', (selectedConversation) => {
      if (isCurrentUserASeller()) {
        manageRegistrationOfInterestPanel(selectedConversation.conversation);
      }
    });
    if (conversationId) {
      inbox.select(conversationId);
    }
    inbox.mount(document.getElementById(id));
    talkjsIsOpen = true;
  }
}

export function inboxOff() {
  if (inbox) {
    inbox.destroy();
    inbox = null;
    talkjsIsOpen = false;
  }
}

export function isTalkjsOpen() {
  return talkjsIsOpen;
}
