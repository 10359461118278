import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Row, Col, Form } from 'react-bootstrap';
import { required, length, email } from 'redux-form-validators';

import useTranslate from 'hooks/useTranslate';
import TextInputField from 'components/InputFields/TextInputField/TextInputField';
import TextAreaField from 'components/InputFields/TextAreaField/TextAreaField';
import PhoneInputField from 'components/InputFields/PhoneInputField/PhoneInputField';

import 'react-phone-number-input/style.css';

function setEnquiryTextFocus(enquiryTextArea) {
  enquiryTextArea.current.focus();

  const regex = /\n\n\n\n/;
  const match = enquiryTextArea.current.value.match(regex);
  const editPosition = match && match.index !== -1 && match.index;
  if (editPosition) {
    enquiryTextArea.current.selectionStart = editPosition + 2;
    enquiryTextArea.current.selectionEnd = editPosition + 2;
  }
}

export function PublicEnquiryForm({
  handleSubmit,
  usersCountry,
  change,
  projectId,
  projectName,
}) {
  const translate = useTranslate();

  const enquiryTextArea = useRef();

  useEffect(() => {
    change('projectId', projectId);

    const templateMessage = translate('publicEnquiryForm.messageTemplate', { projectName });
    change('enquiry', templateMessage);

    // We need this to occur after the change to enquiry text so post it to the event queue.
    setTimeout(() => { setEnquiryTextFocus(enquiryTextArea); }, 0);
  }, []);

  return (
    <Form test-attr="form-public-enquiry" className="bumpbottom" onSubmit={handleSubmit}>
      <Field
        inputRef={enquiryTextArea}
        test-attr="field-enquiry"
        name="enquiry"
        component={TextAreaField}
        type="text"
        label={translate('publicEnquiryForm.messageLabel') + '*'}
        validate={[
          required({ msg: translate('publicEnquiryForm.messageRequired') }),
          length({ max: 250 })
        ]} />
      <Row>
        <Col xs={6}>
          <Field
            test-attr="field-first-name"
            name="firstName"
            type="text"
            component={TextInputField}
            label={translate('common.firstName') + '*'}
            placeholder={translate('common.firstName')}
            validate={[required({ msg: translate('common.firstNameRequired') })]} />
        </Col>
        <Col xs={6}>
          <Field
            test-attr="field-last-name"
            name="lastName"
            type="text"
            component={TextInputField}
            label={translate('common.lastName') + '*'}
            placeholder={translate('common.lastName')}
            validate={[required({ msg: translate('common.lastNameRequired') })]} />
        </Col>
      </Row>
      <Field
        test-attr="field-phone"
        name="phone"
        component={PhoneInputField}
        usersCountry={usersCountry}
        change={change}
        label={translate('common.phoneNumber') + '*'}
        placeholder={translate('common.phoneNumber')}
        validate={[
          required({ msg: translate('common.phoneNumberRequired') }),
          length({ max: 254 })
        ]} />
      <Field
        test-attr="field-company-email"
        name="email"
        component={TextInputField}
        type="email"
        label={translate('common.companyEmail') + '*'}
        placeholder={translate('common.companyEmail')}
        validate={[
          required({ msg: translate('common.emailRequired') }),
          email({ msg: translate('common.invalidEmailAddress') }),
          length({ max: 254 })
        ]} />
      <Field
        test-attr="field-company-name"
        name="companyName"
        component={TextInputField}
        type="text"
        label={translate('common.companyName') + '*'}
        placeholder={translate('common.companyName')}
        validate={[
          required({ msg: translate('common.companyNameRequired') }),
          length({ max: 250 })
        ]} />
    </Form>
  );
}

PublicEnquiryForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  usersCountry: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  projectName: PropTypes.string.isRequired,
};

export default reduxForm({ form: 'publicEnquiryForm' })(PublicEnquiryForm);
