import {
  FETCH_PROJECT_EVENT_STATISTICS,
  FETCH_PROJECT_EVENT_STATISTICS_SUCCESS,
  FETCH_PROJECT_EVENT_STATISTICS_FAILURE,
  FETCH_PROJECT_EVENT_COUNTS_BY_TIME_STEP,
  FETCH_PROJECT_EVENT_COUNTS_BY_TIME_STEP_SUCCESS,
  FETCH_PROJECT_EVENT_COUNTS_BY_TIME_STEP_FAILURE,
} from './EventsActions';

function fetchProjectEventStatistics(state) {
  return {
    ...state,
    projectEventStatisticsLoading: true,
    projectEventStatisticsError: null,
  };
}

function fetchProjectEventStatisticsSuccess(state, action) {
  return {
    ...state,
    projectEventStatisticsLoading: false,
    projectEventStatistics: action.ProjectEventStatisticsResult,
  };
}

function fetchProjectEventStatisticsFailure(state, action) {
  return {
    ...state,
    projectEventStatisticsLoading: false,
    projectEventStatisticsError: action.error,
  };
}

function fetchProjectEventCountsByTimeStep(state) {
  return {
    ...state,
    projectEventCountsByTimeStepLoading: true,
    projectEventCountsByTimeStepError: null,
  };
}

function fetchProjectEventCountsByTimeStepSuccess(state, action) {
  return {
    ...state,
    projectEventCountsByTimeStep: {
      ...state.projectEventCountsByTimeStep,
      [action.eventType]: action.countsByTimeStep,
    },
    projectEventCountsByTimeStepLoading: false,
  };
}

function fetchProjectEventCountsByTimeStepFailure(state, action) {
  return {
    ...state,
    projectEventCountsByTimeStepLoading: false,
    projectEventCountsByTimeStepError: action.error,
  };
}

const initialState = {
  projectEventStatistics: null,
  projectEventStatisticsLoading: false,
  projectEventStatisticsError: null,
  projectEventCountsByTimeStep: null,
  projectEventCountsByTimeStepLoading: false,
  projectEventCountsByTimeStepError: null,
};

export default function PortalSettingsReducers(state = initialState, action) {
  switch (action.type) {
    case FETCH_PROJECT_EVENT_STATISTICS:
      return fetchProjectEventStatistics(state);
    case FETCH_PROJECT_EVENT_STATISTICS_SUCCESS:
      return fetchProjectEventStatisticsSuccess(state, action);
    case FETCH_PROJECT_EVENT_STATISTICS_FAILURE:
      return fetchProjectEventStatisticsFailure(state, action);

    case FETCH_PROJECT_EVENT_COUNTS_BY_TIME_STEP:
      return fetchProjectEventCountsByTimeStep(state);
    case FETCH_PROJECT_EVENT_COUNTS_BY_TIME_STEP_SUCCESS:
      return fetchProjectEventCountsByTimeStepSuccess(state, action);
    case FETCH_PROJECT_EVENT_COUNTS_BY_TIME_STEP_FAILURE:
      return fetchProjectEventCountsByTimeStepFailure(state, action);

    default:
      return state;
  }
}
