export const SHOW_SIGN_IN_NOTIFICATION_POPUP = 'SHOW_SIGN_IN_NOTIFICATION_POPUP';
export const HIDE_SIGN_IN_NOTIFICATION_POPUP = 'HIDE_SIGN_IN_NOTIFICATION_POPUP';
export const SHOW_SIGN_IN_VIA_EMAIL_FORM = 'SHOW_SIGN_IN_VIA_EMAIL_FORM';

export function showSignInNotificationPopup(canClose = true) {
  return {
    type: SHOW_SIGN_IN_NOTIFICATION_POPUP,
    canClose,
  };
}

export function hideSignInNotificationPopup() {
  return {
    type: HIDE_SIGN_IN_NOTIFICATION_POPUP,
  };
}

export function showSigninViaEmailForm(showEmailForm) {
  return {
    type: SHOW_SIGN_IN_VIA_EMAIL_FORM,
    showEmailForm,
  };
}
