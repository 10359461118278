import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import useEnvironment from 'hooks/useEnvironment';
import useLoggedInUser from 'hooks/useLoggedInUser';
import useBrochure from 'hooks/useBrochure';
import usePresentationMode from 'hooks/usePresentationMode';

import portalDefaultLogo from 'assets/images/portal-default-logo@2x.png';
import noLogo from 'assets/images/no-logo.png';

import investoristLogoStandard from 'assets/images/Investorist-Logo-Standard.svg';

function getLogoUrl(
  isBrochurePage,
  environment,
  isPresentationMode,
  loggedInUser,
  brochureOwnerOrganisation
) {
  if (!isBrochurePage && environment) {
    return environment.logoMainImage ? environment.logoMainImage.url : portalDefaultLogo;
  }

  if (isPresentationMode) {
    return (loggedInUser && loggedInUser.organisation && loggedInUser.organisation.logo)
      ? loggedInUser.organisation.logo.url
      : '';
  }

  if (isBrochurePage) {
    return (brochureOwnerOrganisation && brochureOwnerOrganisation.logo)
      ? brochureOwnerOrganisation.logo.url
      : noLogo;
  }

  return investoristLogoStandard;
}

function HomeLogo() {
  const { isEnvironmentLoading, environment } = useEnvironment();
  const { isLoggedInUser, loggedInUser } = useLoggedInUser();
  const { isBrochurePage, brochureOwnerOrganisation } = useBrochure();
  const { isPresentationMode } = usePresentationMode();

  if (isEnvironmentLoading) {
    return <div className="home-logo" />;
  }

  const { hasExpandedHeader } = environment || { };

  const logoUrl = getLogoUrl(
    isBrochurePage, environment, isPresentationMode, loggedInUser, brochureOwnerOrganisation
  );

  return (
    <div className={classNames('home-logo', { 'extended-home-logo': hasExpandedHeader })}>
      <Link to={isLoggedInUser ? '/neo' : '/legacy'}>
        <img className={classNames({ minLogoHeight: environment })} src={logoUrl} alt="logo" />
      </Link>
    </div>
  );
}

export default HomeLogo;
