import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Image,
  MenuItem,
  NavDropdown
} from 'react-bootstrap';
import Toggle from 'react-bootstrap-toggle';

import useTranslate from 'hooks/useTranslate';
import useLanguage from 'hooks/useLanguage';
import useRedirectUrl from 'hooks/useRedirectUrl';
import useLoggedInUser from 'hooks/useLoggedInUser';
import useBrochure from 'hooks/useBrochure';
import useMessaging from 'hooks/useMessaging';
import usePresentationMode from 'hooks/usePresentationMode';
import AcceptCookiesPopupButton from 'components/AcceptCookiesPopup/AcceptCookiesPopupButton';
import SigninNotificationButton from 'components/SigninNotificationButton/SigninNotificationButton';
import { ICONS } from 'components/Icon/Icon';
import { shutdownIntercom } from 'utility/intercom';
import { clearCache } from 'utility/cache';
import { isOnProjectPage } from 'utility/utility';
import { MEASUREMENT_UNITS } from 'hooks/useDisplaySettings';

import LanguageButton from './LanguageButton';
import MeasurementButton from './MeasurementButton';
import NavLink from '../NavLink';

import '../../../../node_modules/react-bootstrap-toggle/dist/bootstrap2-toggle.css';

const renderLanguageButtons = (languages, changeLanguage) => languages.map((language) => (
  <LanguageButton
    key={language.code}
    language={language}
    onLanguageChange={() => changeLanguage(language.code)} />
));

const renderMeasurementButtons = () => Object.values(MEASUREMENT_UNITS).map((measurementUnit) => (
  <MeasurementButton key={measurementUnit} measurementUnitsType={measurementUnit} />
));

const renderOptionsMenu = (
  translate, menuTitle, languages, changeLanguage, authenticatedUserOptions = null
) => (
  <NavDropdown
    id="options-menu-dropdown"
    title={menuTitle}
    className="header-dropdown">
    <MenuItem header>{translate('header.dropdown.languageHeading')}</MenuItem>
    <div className="language-buttons">
      {renderLanguageButtons(languages, changeLanguage)}
    </div>
    <MenuItem divider />
    <MenuItem header>{translate('header.dropdown.measurementUnitsHeading')}</MenuItem>
    <div className="measurement-buttons">
      {renderMeasurementButtons()}
    </div>
    {authenticatedUserOptions}
  </NavDropdown>
);

const renderLoginButton = (translate, redirectUrl, isProjectPage) => (
  <>
  {
    isProjectPage
    && (
      <div>
        <SigninNotificationButton />
      </div>
    )
  }
  {
    !isProjectPage
    && (
    <Link to={`/legacy/Home/Login?redirectUrl=${encodeURIComponent(redirectUrl)}`}>
    <Button className="loginButton" block>
      {translate('common.login')}
    </Button>
    </Link>
    )
  }
  </>
);

const renderPublicAdministrationBar = (
  translate, redirectUrl, isBrochurePage, languages, changeLanguage, isPublic, isProjectPage
) => {
  const optionsMenuTitle = translate('common.options');

  return (
    <div className="administration-bar">
      <ul className="nav navbar-nav navbar-right">
        {isPublic && <AcceptCookiesPopupButton />}
        <li>
          {isBrochurePage
            ? renderOptionsMenu(translate, optionsMenuTitle, languages, changeLanguage)
            : renderLoginButton(translate, redirectUrl, isProjectPage)}
        </li>
      </ul>
    </div>
  );
};

const renderAuthenticatedOptionsMenuTitle = ({ profileImage, firstName, lastName }) => (
  <span>
    {profileImage?.url && <span><Image src={profileImage.url} /></span>}
    <span className="hidden-xs">{`${firstName} ${lastName}`}</span>
  </span>
);

const onLogout = () => {
  shutdownIntercom();
  clearCache();
};

const renderAuthenticatedUserOptions = (
  translate, isUserASeller, isPresentationMode, togglePresentationMode, loggedInUser
) => (
  <>
    <MenuItem divider />
    {!isUserASeller && (
      <>
        <div className="presentation-mode-toggle">
          <span>
            {translate('header.dropdown.presentationMode')}
          </span>
          <Toggle
            on={translate('header.dropdown.presentationModeOn')}
            onstyle="success"
            off={translate('header.dropdown.presentationModeOff')}
            width={120}
            height={30}
            onClick={togglePresentationMode}
            active={isPresentationMode} />
        </div>
        <MenuItem divider />
      </>
    )}
    <NavLink
      text={translate('header.dropdown.profile')}
      to={isUserASeller ? '/legacy/Developer/DevProfile' : '/neo/agentProfile/' + loggedInUser?.organisation?.id} />
    <MenuItem divider />
    <NavLink
      text={translate('header.dropdown.logout')}
      onClick={onLogout}
      to="/legacy/Home/Logout" />
  </>
);

const renderAuthenticatedAdministrationBar = (
  translate,
  loggedInUser,
  isUserASeller,
  isPresentationMode,
  togglePresentationMode,
  totalUnreadConversationsCount,
  languages,
  changeLanguage,
) => {
  const optionsMenuTitle = renderAuthenticatedOptionsMenuTitle(loggedInUser);

  const authenticatedUserOptions = renderAuthenticatedUserOptions(
    translate, isUserASeller, isPresentationMode, togglePresentationMode, loggedInUser
  );

  return (
    <div className="administration-bar">
      <ul className="nav navbar-nav navbar-right">
        <NavLink
          className="messages-link"
          icon={ICONS.mail}
          badge={`${totalUnreadConversationsCount || ''}`}
          to="/neo/Messages" />
        {renderOptionsMenu(
          translate, optionsMenuTitle, languages, changeLanguage, authenticatedUserOptions
        )}
      </ul>
    </div>
  );
};

function AdministrationBar() {
  const isProjectPage = isOnProjectPage();
  const translate = useTranslate();
  const { languages, changeLanguage } = useLanguage();
  const {
    loggedInUser,
    isLoggedInUser,
    isLoggedInUserLoading,
    isUserASeller
  } = useLoggedInUser();
  const redirectUrl = useRedirectUrl();
  const { isBrochurePage } = useBrochure();
  const { isPresentationMode, togglePresentationMode } = usePresentationMode();
  const { totalUnreadConversationsCount, fetchUnreadConversationsCounts } = useMessaging();

  useEffect(() => fetchUnreadConversationsCounts(), []);

  if (isLoggedInUserLoading) {
    return <div className="administration-bar" />;
  }

  const isPublic = !isLoggedInUser;
  if (isPublic || isBrochurePage) {
    return renderPublicAdministrationBar(
      translate, redirectUrl, isBrochurePage, languages, changeLanguage, isPublic, isProjectPage
    );
  }

  return renderAuthenticatedAdministrationBar(
    translate,
    loggedInUser,
    isUserASeller,
    isPresentationMode,
    togglePresentationMode,
    totalUnreadConversationsCount,
    languages,
    changeLanguage
  );
}

export default AdministrationBar;
