import { createSelector } from 'reselect';

const loggedInUserLoadingSelector = (state) => state.user.loggedInUserLoading;
const loggedInUserErrorSelector = (state) => state.user.loggedInUserError;
const quickSearchSectionsLoadingSelector = (state) => state.user.quickSearchSectionsLoading;
const quickSearchSectionsErrorSelector = (state) => state.user.quickSearchSectionsError;
const loggedInUserSelector = (state) => state.user.loggedInUser;
const quickSearchSectionsSelector = (state) => state.user.quickSearchSections;
const measurementUnitsSelector = (state) => state.user.measurementUnits;
const presentationModeSelector = (state) => state.user.presentationMode;
const connectionStatusSelector = (state) => state.user.connectionStatus;
const connectionRetryTimeSelector = (state) => state.user.connectionRetryTime;
const connectionDownTimeSelector = (state) => state.user.connectionDownTime;
const trialPopUpShownDateSelector = (state) => state.user.trialPopUpShownDate;
const currentOnboardingPageSelector = (state) => state.user.currentOnboardingPage;
const trialUpgradeResultSelector = (state) => state.user.trialUpgradeResult;
const trialUpgradeRequestLoadingSelector = (state) => state.user.trialUpgradeRequestLoading;
const trialUpgradeRequestErrorSelector = (state) => state.user.trialUpgradeRequestError;
const loggedInUserSubscriptionLoadingSelector = (state) => (
  state.user.getLoggedInUserSubscriptionLoading
);
const loggedInUserSubscriptionErrorSelector = (state) => (
  state.user.getLoggedInUserSubscriptionError
);

export const getLoggedInUserLoading = createSelector(
  [loggedInUserLoadingSelector],
  (state) => state,
);

export const getLoggedInUserError = createSelector(
  [loggedInUserErrorSelector],
  (state) => state,
);

export const getQuickSearchSectionsLoading = createSelector(
  [quickSearchSectionsLoadingSelector],
  (state) => state,
);

export const getQuickSearchSectionsError = createSelector(
  [quickSearchSectionsErrorSelector],
  (state) => state,
);

export const getLoggedInUser = createSelector(
  [loggedInUserSelector],
  (state) => state,
);

export const getQuickSearchSections = createSelector(
  [quickSearchSectionsSelector],
  (state) => state,
);

export const getMeasurementUnits = createSelector(
  [measurementUnitsSelector],
  (state) => state,
);

export const isPresentationMode = createSelector(
  [presentationModeSelector],
  (state) => state,
);

export const isConnectionDown = createSelector(
  [connectionStatusSelector],
  (status) => status === 'DOWN',
);

export const getConnectionRetryTime = createSelector(
  [connectionRetryTimeSelector],
  (state) => state,
);

export const getConnectionDownTime = createSelector(
  [connectionDownTimeSelector],
  (state) => state,
);

export const shouldTryConnectionAgain = createSelector(
  [getConnectionRetryTime, connectionDownTimeSelector],
  (delay, connectionDownTime) => (Date.now() - connectionDownTime >= delay * 1000),
);

export const getTrialPopUpShownDate = createSelector(
  [trialPopUpShownDateSelector],
  (state) => state,
);

export const getCurrentOnboardingPage = createSelector(
  [currentOnboardingPageSelector],
  (state) => state,
);

export const getTrialUpgradeResult = createSelector(
  [trialUpgradeResultSelector],
  (state) => state,
);

export const getTrialUpgradeRequestError = createSelector(
  [trialUpgradeRequestErrorSelector],
  (state) => state,
);

export const getTrialUpgradeRequestLoading = createSelector(
  [trialUpgradeRequestLoadingSelector],
  (state) => state,
);

export const getLoggedInUserSubscription = createSelector(
  [getLoggedInUser],
  (loggedInUser) => (loggedInUser ? loggedInUser.subscription : null),
);

export const getLoggedInUserType = createSelector(
  [getLoggedInUser],
  (loggedInUser) => {
    if (loggedInUser && loggedInUser.organisation) {
      return loggedInUser.organisation.type;
    }
    return null;
  },
);

export const isUserASeller = createSelector(
  [getLoggedInUser],
  (loggedInUser) => loggedInUser && loggedInUser.organisation && loggedInUser.organisation.type === 'SELLER',
);

export const getOrganisationEmail = createSelector(
  [getLoggedInUser],
  (loggedInUser) => loggedInUser && loggedInUser.organisation && loggedInUser.organisation.email,
);

export const getLoggedInUserSubscriptionLoading = createSelector(
  [loggedInUserSubscriptionLoadingSelector],
  (state) => state,
);

export const getLoggedInUserSubscriptionError = createSelector(
  [loggedInUserSubscriptionErrorSelector],
  (state) => state,
);
