import { useState } from 'react';

const useLocalStorage = (key, initialValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = localStorage.getItem(key);

      if (item) {
        // try to parse the item if it is of type object
        // if it fails it means the item is a base type (string, number, etc)
        try {
          return JSON.parse(item);
        } catch (error) {
          return item;
        }
      } else {
        return initialValue;
      }
    } catch (error) {
      return initialValue;
    }
  });

  const setValue = (value) => {
    const valueToStore = value instanceof Function ? value(storedValue) : value;
    setStoredValue(valueToStore);
    // eslint-disable-next-line no-unused-expressions
    valueToStore instanceof Object
      ? localStorage.setItem(key, JSON.stringify(valueToStore))
      : localStorage.setItem(key, valueToStore);
  };

  return [storedValue, setValue];
};

export default useLocalStorage;
