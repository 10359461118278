import { graphQLQuery } from './api';

export function loadProjectDetailsApi(projectId) {
  const queryString = `
  query ($projectId: InvestoristID!) {
    project (id: $projectId) {
      id
      name
      logo { id url }
      description { long }
      address { fullDescription }
      geocoordinates { latitude longitude }
      dates { constructionStartDate constructionEndDate }
      payments {
        reservationDeposit { fixedValue { value type } percentValue }
      }
      stockCount
      ... on ProjectDistribution {
        redactions { reason redactions { path type } }
      }
      stockStatistics {
        count
        stockTypes
        priceRange { minValue maxValue type }
        bedroomsRange { minValue maxValue }
        bathroomsRange { minValue maxValue }
        carparksRange { minValue maxValue }
        studiesRange { minValue maxValue }
        storageRange { minValue maxValue }
        internalBuildSquareMetresRange { minValue maxValue }
        externalBuildSquareMetresRange { minValue maxValue }
        totalBuildSquareMetresRange { minValue maxValue }
        landSquareMetresRange { minValue maxValue }
        agentCommission {
          fixedValue { minValue maxValue type }
          percentValue { minValue maxValue }
        }
      }
      metadata { lastModifiedDate }
      incentives { id description }
      lifeCycleStatus
    }
  }`;
  const variables = {
    projectId,
  };

  return graphQLQuery(queryString, variables, { errorHandler: null });
}

export function loadProjectRedactionsApi(projectId) {
  const queryString = `
  query ($projectId: InvestoristID!) {
    project (id: $projectId) {
      id
      ... on ProjectDistribution {
        redactions { reason redactions { path type } }
      }
    }
  }`;
  const variables = {
    projectId,
  };
  return graphQLQuery(queryString, variables);
}

export function loadProjectDescriptionApi(projectId) {
  const queryString = `
  query ($projectId: InvestoristID!) {
    project (id: $projectId) {
      id
      ... on ProjectDistribution {
        redactions { reason redactions { path type } }
      }
      description { long }
    }
  }`;
  const variables = {
    projectId,
  };
  return graphQLQuery(queryString, variables);
}

export function loadProjectImagesApi(projectId) {
  const queryString = `
  query ($projectId: InvestoristID!) {
    project (id: $projectId) {
      id
      ... on ProjectDistribution {
        redactions { reason redactions { path type } }
      }
      images { id url }
    }
  }`;
  const variables = {
    projectId,
  };

  return graphQLQuery(queryString, variables, { errorHandler: null });
}

export function loadProjectSellerApi(projectId) {
  const queryString = `
  query ($projectId: InvestoristID!) {
    project (id: $projectId) {
      id
      ... on ProjectDistribution {
        redactions { reason redactions { path type } }
      }
      contact { id firstName lastName jobTitle profileImage { id url } }
      seller {
        id
        type
        name
        address { shortDescription }
        logo { id url }
        description { long }
        owner { id firstName lastName jobTitle profileImage { id url } }
        recentActiveDate
      }
    }
  }`;
  const variables = {
    projectId,
  };

  return graphQLQuery(queryString, variables, { errorHandler: null });
}

export function loadProjectDocumentsListApi(projectId) {
  const queryString = `
  query ($projectId: InvestoristID!) {
    project (id: $projectId) {
      id
      ... on ProjectDistribution {
        redactions { reason redactions { path type } }
      }
      documents { id name url mimeType sizeInBytes }
    }
  }`;
  const variables = {
    projectId,
  };

  return graphQLQuery(queryString, variables, { errorHandler: null });
}

export function loadProjectStockListApi(projectId) {
  const queryString = `
  query ($projectId: InvestoristID!) {
    project (id: $projectId) {
      id
      ... on ProjectDistribution {
        redactions { reason redactions { path type } }
        stocks {
          id
          redactions { reason redactions { path type } }
          name
          price { total { value type } }
          status
          floor
          storeys
          areas { internalBuildSquareMetres externalBuildSquareMetres totalBuildSquareMetres landSquareMetres }
          features { general { bedroom { count } bathroom { count } study { count } parkingSpace { count } } }
          documents { id name url mimeType sizeInBytes documentType}
        }
      }
      ... on SellerProject {
        stocks {
          id
          name
          active
          price { total { value type } }
          status
          floor
          storeys
          areas { internalBuildSquareMetres externalBuildSquareMetres totalBuildSquareMetres landSquareMetres }
          features { general { bedroom { count } bathroom { count } study { count } parkingSpace { count } } }
          documents { id name url mimeType sizeInBytes documentType}
        }    
      }
    }
  }`;
  const variables = {
    projectId,
  };

  return graphQLQuery(queryString, variables, { errorHandler: null });
}

export function loadProjectVideosApi(projectId) {
  const queryString = `
  query ($projectId: InvestoristID!) {
    project (id: $projectId) {
      id
      videos {
        id
        type
        order
        ... on AssetVideo {
          asset { id mimeType url }
        }
        ... on LinkVideo {
          uri
        }
        thumbnail {
          type
          ... on AssetThumbnail {
            asset { id mimeType url }
          }
          ... on LinkThumbnail {
            uri
          }
        }
      }
    }
  }`;
  const variables = {
    projectId,
  };

  return graphQLQuery(queryString, variables, { errorHandler: null });
}

export function sendRegistrationOfInterestRequestApi(projectId, message) {
  const mutationString = `
  mutation ($projectId: ID!, $message: String!) {
    sendRegistrationOfInterestRequest (id: $projectId, message: $message)
  }`;
  const variables = {
    projectId,
    message,
  };
  return graphQLQuery(mutationString, variables);
}

export function loadFeaturedProjectsApi() {
  const queryString = `
  query {
    featuredProjects {
      featuredProjectInfo {
        projectId
        priority
        type
        country { code }
        stateOrRegion { abbreviation }
        content
        highlightInSearchResults
        showOnSearchPage
      }
      projects {
        id
        redactions { reason redactions { path type } }
        name
        logo { id url }
        address { fullDescription }
        geocoordinates { latitude longitude }
        seller { id name logo { id url } recentActiveDate }
        images { id url }
        stockCount
        stockStatistics {
          count
          stockTypes
          priceRange { minValue maxValue type }
          bedroomsRange { minValue maxValue }
          bathroomsRange { minValue maxValue }
          carparksRange { minValue maxValue }
          agentCommission {
            fixedValue { minValue maxValue type }
            percentValue { minValue maxValue }
          }
        }
        metadata {
          lastModifiedDate
        }
      }
      staleBy
    }
  }`;
  const variables = {};

  return graphQLQuery(queryString, variables, { errorHandler: null });
}

export function loadRecentlyUpdatedProjectsApi(take) {
  const queryString = `
  query ($take: Int) {
    projectsMostRecentlyUpdated (take: $take) {
      viewport {
        northeast { latitude longitude }
        southwest { latitude longitude }
      }
      projectResults {
        item {
          id
          ... on ProjectDistribution {
            redactions { reason redactions { path type } }
          }
          name
          logo { id url }
          address { fullDescription }
          geocoordinates { latitude longitude }
          seller { id name logo { id url } recentActiveDate }
          images { id url }
          stockCount
          stockStatistics {
            count
            stockTypes
            priceRange { minValue maxValue type }
            bedroomsRange { minValue maxValue }
            bathroomsRange { minValue maxValue }
            carparksRange { minValue maxValue }
            agentCommission {
              fixedValue { minValue maxValue type }
              percentValue { minValue maxValue }
            }
          }
          metadata {
            lastModifiedDate
          }
        }
      }
    }
  }`;
  const variables = {
    take,
  };

  return graphQLQuery(queryString, variables, { errorHandler: null });
}

export function loadProjectOverviewApi(projectId) {
  const queryString = `
  query ($projectId: InvestoristID!) {
    project (id: $projectId) {
      id
      overview {
        bedroomCountStatistics {
          bedroomCount
          priceRange { minValue maxValue type }
          totalBuildSquareMetresRange { minValue maxValue }
          pricePerSquareMetreRange { minValue maxValue type }
          medianPrice { value type }
          medianTotalBuildSquareMetres
        }
        stockStatusCounts {
          stockStatus
          count
        }
      }
    }
  }
  `;
  const variables = {
    projectId,
  };
  return graphQLQuery(queryString, variables, { errorHandler: null });
}

export function loadPriceListPdfApi(projectId) {
  const queryString = `
  query ($projectId: InvestoristID!) {
    project (id: $projectId) {
      type name address { fullDescription } lifeCycleStatus dates { constructionEndDate }
      ... on ProjectDistribution {
        stocks(orderBy: NAME) {
          floor
          name
          type
          status
          features { general { bedroom { count } bathroom { count } study { count } parkingSpace { count } storageLocker { count } } }
          areas { internalBuildSquareMetres externalBuildSquareMetres }
          inOutgoings { outgoingOwnersCorporationPerAnnum { value type } }
          price { total { value type } }
        }
      }
      ... on SellerProject {
        stocks(filter: { active: true }, orderBy: NAME) {
          floor
          name
          type
          status
          features { general { bedroom { count } bathroom { count } study { count } parkingSpace { count } storageLocker { count } } }
          areas { internalBuildSquareMetres externalBuildSquareMetres }
          inOutgoings { outgoingOwnersCorporationPerAnnum { value type } }
          price { total { value type } }
        }    
      }
      images { url }
      seller { logo { url } }
      metadata { lastActiveDate }
    }
  }
  `;
  const variables = {
    projectId,
  };
  const options = {
    headers: {
      Accept: 'application/pdf'
    },
    extractData: (response) => response.blob()
  };

  return graphQLQuery(queryString, variables, options);
}
