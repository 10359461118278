import { graphQLQuery } from './api';

export function isFollowingApi(id) {
  const queryString = `
  query ($id: ID!) {
    isFollowing (id: $id)
  }`;
  const variables = {
    id,
  };
  return graphQLQuery(queryString, variables, { errorHandler: null });
}

export function setFollowingApi(id, on) {
  const mutationString = `
  mutation ($id: ID!, $on: Boolean!) {
    setFollowing (id: $id, on: $on)
  }`;
  const variables = {
    id,
    on,
  };
  return graphQLQuery(mutationString, variables, { errorHandler: null });
}
