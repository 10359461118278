import { createSelector } from 'reselect';

const signupDialogVisibleSelector = (state) => state.signupDialog.visible;
const signupDialogTagSelector = (state) => state.signupDialog.tag;
const customerTypeSelector = (state) => state.signupDialog.customerType;
const canCloseSelector = (state) => state.signupDialog.canClose;

export const getSignupDialogVisible = createSelector(
  [signupDialogVisibleSelector],
  (state) => state,
);

export const getSignupDialogTag = createSelector(
  [signupDialogTagSelector],
  (state) => state,
);

export const getCustomerType = createSelector(
  [customerTypeSelector],
  (state) => state,
);

export const getCanCloseDialog = createSelector(
  [canCloseSelector],
  (state) => state,
);
