import { graphQLQuery } from './api';

export function publicCallbackRequestApi(
  customerType,
  name,
  email,
  phone,
  company,
  projectId,
) {
  const mutationString = `
  mutation publicCallbackRequest($customerType: CustomerType!, $name: String!, $email: String!, $phone: String!, $company: String, $projectId: ID) {
    publicCallbackRequest(customerType: $customerType, name: $name, email: $email, phone: $phone, company: $company, projectId: $projectId)
  }`;
  const variables = {
    customerType,
    name,
    email,
    phone,
    company,
    projectId,
  };
  return graphQLQuery(mutationString, variables);
}
