import { graphQLQuery } from './api';

export function buildBrochureApi(title, description, includedDistributionIds) {
  const mutationString = `
  mutation ($title: String!, $description: String, $includedDistributionIds: [ID]!) {
    buildBrochure(title: $title, description: $description, includedDistributionIds: $includedDistributionIds)
  }`;
  const variables = {
    title,
    description,
    includedDistributionIds,
  };
  return graphQLQuery(mutationString, variables);
}

export function loadBrochureDetailsApi(id) {
  const queryString = `
  query ($id: ID!) {
    brochure (id: $id) {
      id
      owner {
        id
        firstName
        lastName
        email
        phone
        jobTitle
        profileImage { id url }
        organisation {
          id
          type
          name
          email
          phone
          address { shortDescription }
          logo { id url }
          description { long }
        }
      }
      environment {
        portalName
        logoMainImage { id url }
        mainBrandColour
        secondaryBrandColour
        faviconImage { id url }
      }
      title
      description
    }
  }`;
  const variables = {
    id,
  };

  return graphQLQuery(
    queryString,
    variables,
    {
      useAuthenticationToken: false,
      errorHandler: null
    }
  );
}

export function loadBrochureProjectDetailsApi(id) {
  const queryString = `
  query ($id: ID!) {
    brochure (id: $id) {
      id
      projects {
        id
        redactions { reason redactions { path type } }
        name
        logo { id url }
        description { long }
        address { fullDescription }
        geocoordinates { latitude longitude }
        dates { constructionStartDate constructionEndDate }
        payments {
          reservationDeposit { fixedValue { value type } percentValue }
        }
        stockCount
        stockStatistics {
          count
          stockTypes
          priceRange { minValue maxValue type }
          bedroomsRange { minValue maxValue }
          bathroomsRange { minValue maxValue }
          carparksRange { minValue maxValue }
          studiesRange { minValue maxValue }
          storageRange { minValue maxValue }
          internalBuildSquareMetresRange { minValue maxValue }
          externalBuildSquareMetresRange { minValue maxValue }
          totalBuildSquareMetresRange { minValue maxValue }
          landSquareMetresRange { minValue maxValue }
        }
      }
    }
  }`;
  const variables = {
    id,
  };

  return graphQLQuery(
    queryString,
    variables,
    {
      useAuthenticationToken: false,
      errorHandler: null
    }
  );
}

export function loadBrochureProjectOverviewApi(id) {
  const queryString = `
  query ($id: ID!) {
    brochure (id: $id) {
      id
      projects {
        id
        redactions { reason redactions { path type } }
        overview {
          bedroomCountStatistics {
            bedroomCount
            priceRange { minValue maxValue type }
            totalBuildSquareMetresRange { minValue maxValue }
            pricePerSquareMetreRange { minValue maxValue type }
            medianPrice { value type }
            medianTotalBuildSquareMetres
          }
          stockStatusCounts {
            stockStatus
            count
          }
        }
      }
    }
  }`;
  const variables = {
    id,
  };

  return graphQLQuery(
    queryString,
    variables,
    {
      useAuthenticationToken: false,
      errorHandler: null
    }
  );
}

export function loadBrochureProjectImagesApi(id) {
  const queryString = `
  query ($id: ID!) {
    brochure (id: $id) {
      id
      projects {
        id
        redactions { reason redactions { path type } }
        images { id url }
      }
    }
  }`;
  const variables = {
    id,
  };

  return graphQLQuery(
    queryString,
    variables,
    {
      useAuthenticationToken: false,
      errorHandler: null
    }
  );
}

export function loadBrochureStockListApi(id) {
  const queryString = `
  query ($id: ID!) {
    brochure (id: $id) {
      id
      stocks {
        id
        redactions { reason redactions { path type } }
        status
        name
        floor
        storeys
        type
        price { total { value type } }
        areas { internalBuildSquareMetres externalBuildSquareMetres totalBuildSquareMetres landSquareMetres }
        features { general { bedroom { count } bathroom { count } study { count } parkingSpace { count } } }
        documents { id name url mimeType sizeInBytes documentType}
      }
    }
  }`;
  const variables = {
    id,
  };

  return graphQLQuery(
    queryString,
    variables,
    {
      useAuthenticationToken: false,
      errorHandler: null
    }
  );
}
