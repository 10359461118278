import {
  GET_CURRENT_POSITION_RESULTS,
  GET_CURRENT_POSITION_FAILURE,
} from './GeolocationActions';

function getCurrentPositionResults(state, action) {
  return {
    ...state,
    currentPosition: action.results
  };
}

function getCurrentPositionFailure(state) {
  return {
    ...state,
    currentPosition: null
  };
}

const initialState = {
  currentPosition: null
};

export default function GeolocationReducers(state = initialState, action) {
  switch (action.type) {
    case GET_CURRENT_POSITION_RESULTS:
      return getCurrentPositionResults(state, action);

    case GET_CURRENT_POSITION_FAILURE:
      return getCurrentPositionFailure(state, action);

    default:
      return state;
  }
}
