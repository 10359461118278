/* eslint-disable no-shadow */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Modal,
  Button
} from 'react-bootstrap';
import { getTranslate } from 'react-localize-redux';

import { getPublicProjectEnquiryDialog } from 'components/PublicProjectEnquiryDialog/PublicProjectEnquiryDialogSelectors';
import { hidePublicProjectEnquiryDialog } from 'components/PublicProjectEnquiryDialog/PublicProjectEnquiryDialogActions';
import PublicEnquiryForm from 'components/PublicProjectEnquiryDialog/components/PublicEnquiryForm';
import { getUsersCountry } from 'components/Integration/IntegrationSelectors';
import { sendPublicProjectEnquiry } from 'components/PublicProjectEnquiry/PublicProjectEnquiryActions';
import AlertPopup from 'components/AlertPopup/AlertPopup';
import { ICONS } from 'components/Icon/Icon';
import colours from 'components/App/InvestoristColours.scss';

import 'components/PublicProjectEnquiryDialog/PublicProjectEnquiryDialog.scss';

export class PublicProjectEnquiryDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSendButtonEnabled: true,
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(values) {
    const { hidePublicProjectEnquiryDialog, sendPublicProjectEnquiry } = this.props;
    sendPublicProjectEnquiry(
      values.firstName,
      values.lastName,
      values.email,
      values.countryCode,
      values.phone,
      values.companyName,
      values.projectId,
      values.enquiry);

    hidePublicProjectEnquiryDialog();
  }

  render() {
    const {
      publicProjectEnquiryDialog: {
        isVisible,
        projectId,
        projectName,
      },
      hidePublicProjectEnquiryDialog,
      usersCountry,
      translate,
    } = this.props;

    const {
      isSendButtonEnabled,
    } = this.state;

    return (
      <>
        <Modal
          test-attr="component-public-project-enquiry-dialog"
          className="publicProjectEnquiryDialog"
          show={isVisible}
          backdrop="static"
          onHide={() => { hidePublicProjectEnquiryDialog(); }}>
          <Modal.Header closeButton />
          <Modal.Body>
            <div className="text-center">
              <h1 className="text-center">
                {translate('publicProjectEnquiryDialog.heading')}
              </h1>
              <div className="bumpTop">
                {
                  isVisible && projectId && projectName
                  && (
<PublicEnquiryForm
  ref={(node) => { this.formRef = node; }}
  onSubmit={this.onSubmit}
  usersCountry={usersCountry}
  projectId={projectId}
  projectName={projectName} />
                  )
                }
              </div>
              <Button
                test-attr="component-submit-button"
                className="sectionHeading btn-lg"
                disabled={!isSendButtonEnabled}
                onClick={() => { this.formRef.submit(); }}>
                {translate('common.send')}
              </Button>
            </div>
          </Modal.Body>
        </Modal>
        <AlertPopup
          tag="public-project-enquiry-request-success"
          heading={translate('publicProjectEnquiryDialog.successPopup.heading')}
          content={translate('publicProjectEnquiryDialog.successPopup.heading')}
          icon={{
            data: ICONS.sent,
            size: 50,
            colour: colours.primaryAccent,
          }} />
        <AlertPopup
          tag="public-project-enquiry-request-failure"
          heading={translate('publicProjectEnquiryDialog.failurePopup.heading')}
          icon={{
            data: ICONS.failure,
            size: 50,
            colour: colours.alternate,
          }} />
      </>
    );
  }
}

PublicProjectEnquiryDialog.defaultProps = {
  usersCountry: 'AU',
};

PublicProjectEnquiryDialog.propTypes = {
  publicProjectEnquiryDialog: PropTypes.shape({
    isVisible: PropTypes.bool.isRequired,
    projectId: PropTypes.string,
    projectName: PropTypes.string,
  }).isRequired,
  hidePublicProjectEnquiryDialog: PropTypes.func.isRequired,
  sendPublicProjectEnquiry: PropTypes.func.isRequired,
  usersCountry: PropTypes.string,
  translate: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    publicProjectEnquiryDialog: getPublicProjectEnquiryDialog(state),
    usersCountry: getUsersCountry(state),
    translate: getTranslate(state.localize),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    hidePublicProjectEnquiryDialog: () => dispatch(hidePublicProjectEnquiryDialog()),
    sendPublicProjectEnquiry:
      (
        firstName,
        lastName,
        email,
        countryCode,
        phone,
        companyName,
        projectId,
        message
      ) => dispatch(
        sendPublicProjectEnquiry(
          firstName,
          lastName,
          email,
          countryCode,
          phone,
          companyName,
          projectId,
          message
        )
      ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PublicProjectEnquiryDialog);
