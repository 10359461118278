import { graphQLQuery } from './api';

export function setEnvironmentApi(
  portalName,
  homepageType,
  homepageHeading,
  disclaimer,
  mainBrandColour,
  secondaryBrandColour,
  logoMainImageAssetId,
  logoWhiteImageAssetId,
  loginBackgroundImageAssetId,
  homepageBackgroundImageAssetId,
  faviconImageAssetId,
  webclipImageAssetId,
  hasExpandedHeader,
) {
  const mutationString = `
    mutation (
      $portalName: String!,
      $homepageType: HomepageType!,
      $homepageHeading: String,
      $disclaimer: String,
      $mainBrandColour: String!,
      $secondaryBrandColour: String!,
      $logoMainImageAssetId: ID,
      $logoWhiteImageAssetId: ID,
      $loginBackgroundImageAssetId: ID,
      $homepageBackgroundImageAssetId: ID,
      $faviconImageAssetId: ID,
      $webclipImageAssetId: ID,
      $hasExpandedHeader: Boolean!) {
        setEnvironment (
          portalName: $portalName,
          homepageType: $homepageType,
          homepageHeading: $homepageHeading,
          disclaimer: $disclaimer,
          mainBrandColour: $mainBrandColour,
          secondaryBrandColour: $secondaryBrandColour,
          logoMainImageAssetId: $logoMainImageAssetId,
          logoWhiteImageAssetId: $logoWhiteImageAssetId,
          loginBackgroundImageAssetId: $loginBackgroundImageAssetId,
          homepageBackgroundImageAssetId: $homepageBackgroundImageAssetId,
          faviconImageAssetId: $faviconImageAssetId,
          webclipImageAssetId: $webclipImageAssetId,
          hasExpandedHeader: $hasExpandedHeader,
        )
    }`;
  const variables = {
    portalName,
    homepageType,
    homepageHeading,
    disclaimer,
    mainBrandColour,
    secondaryBrandColour,
    logoMainImageAssetId,
    logoWhiteImageAssetId,
    loginBackgroundImageAssetId,
    homepageBackgroundImageAssetId,
    faviconImageAssetId,
    webclipImageAssetId,
    hasExpandedHeader,
  };
  return graphQLQuery(mutationString, variables);
}

export function distributedProjectStatisticsApi() {
  const queryString = `
  query distributedProjectStatistics
  {
    distributedProjectStatistics
    {
        count
    }
  }`;
  const variables = {
  };
  return graphQLQuery(queryString, variables);
}

export function loadPortalApi() {
  const queryString = `
  query portal {
    portal {
      id
      contact {
        id
        firstName
        lastName
        organisation {
          id
        }
      }
      owner {
        id
      }
    }
  }`;
  const variables = {
  };
  return graphQLQuery(queryString, variables);
}

export function createPortalApi(domainName, ownerId) {
  const mutationString = `
    mutation ($domainName: String!, $ownerId: ID!) {
      createPortal(domainName: $domainName, ownerId: $ownerId) {
        portalId                
      }
    }`;

  const variables = {
    domainName,
    ownerId
  };

  return graphQLQuery(mutationString, variables, { errorHandler: null });
}

export function portalPotentialOwnersApi(partialSearchTerm) {
  const queryString = `
    query ($partialSearchTerm: String!) {
      autocompletePotentialPortalOwner(partialSearchTerm: $partialSearchTerm) {
        searchTerm
        searchType
        searchItemId
      }
    }`;

  const variables = {
    partialSearchTerm
  };

  return graphQLQuery(queryString, variables);
}
