import React from 'react';
import PropTypes from 'prop-types';

import { isUserABot, isOnBrochurePage } from 'utility/utility';
import { PERMISSION, hasPermission } from 'utility/permissions';

import AgentOnboarding from './components/AgentOnboarding/AgentOnboarding';
import PendingAgentOnboarding from './components/PendingAgentOnboarding/PendingAgentOnboarding';
import SellerOnboarding from './components/SellerOnboarding/SellerOnboarding';

import './Onboarding.scss';

const Onboarding = ({ loggedInUser }) => {
  if (isOnBrochurePage() || !loggedInUser) {
    return null;
  }

  const organisationType = loggedInUser.organisation
    ? loggedInUser.organisation.type
    : undefined;

  const canSeeOnboarding = hasPermission(loggedInUser, PERMISSION.CAN_SEE_ONBOARDING);

  const currentSubscription = loggedInUser && loggedInUser.subscription
    ? loggedInUser.subscription.type
    : '';

  if (isUserABot()) {
    return null;
  }

  if (canSeeOnboarding) {
    if (organisationType === 'AGENT') {
      if (currentSubscription === 'PENDING') {
        return <PendingAgentOnboarding />;
      }
      return <AgentOnboarding />;
    } if (organisationType === 'SELLER') {
      return <SellerOnboarding />;
    }
  }

  return null;
};

Onboarding.defaultProps = {
  loggedInUser: null,
};

Onboarding.propTypes = {
  loggedInUser: PropTypes.object,
};

export default Onboarding;
