import { createSelector } from 'reselect';

const unreadConversationsCountSelector = (state) => state.messaging.unreadConversationsCount;
const unreadLegacyConversationsCountSelector = (state) => (
  state.messaging.unreadLegacyConversationsCount
);

export const getUnreadConversationsCount = createSelector(
  [unreadConversationsCountSelector],
  (state) => state,
);

export const getUnreadLegacyConversationsCount = createSelector(
  [unreadLegacyConversationsCountSelector],
  (state) => state,
);
