import {
  FETCH_PORTAL,
  FETCH_PORTAL_SUCCESS,
  FETCH_PORTAL_FAILURE,
} from './PortalActions';

function fetchPortal(state) {
  return {
    ...state,
    portalLoading: true,
    portalError: null,
  };
}

function fetchPortalSuccess(state, action) {
  return {
    ...state,
    portalLoading: false,
    portal: action.portal,
  };
}

function fetchPortalFailure(state, action) {
  return {
    ...state,
    portalLoading: false,
    portalError: action.error,
  };
}

const initialState = {
  portal: null,
  portalLoading: false,
  portalError: null,
};

export default function PortalReducers(state = initialState, action) {
  switch (action.type) {
    case FETCH_PORTAL:
      return fetchPortal(state);
    case FETCH_PORTAL_SUCCESS:
      return fetchPortalSuccess(state, action);
    case FETCH_PORTAL_FAILURE:
      return fetchPortalFailure(state, action);

    default:
      return state;
  }
}
