import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import Icon from 'components/Icon/Icon';

import { getAlertPopupTag } from './AlertPopupSelectors';
import { hideAlertPopup } from './AlertPopupActions';

import SimpleAlertPopup from './SimpleAlertPopup';

export default function AlertPopup({
  icon,
  tag,
  onHide,
  ...passThrough
}) {
  const isVisible = useSelector((state) => getAlertPopupTag(state) === tag);
  const dispatch = useDispatch();

  return (
    <SimpleAlertPopup
      icon={icon && (
        <div test-attr="icon-container" className={icon.customClasses ? icon.customClasses : ''}>
          <Icon icon={icon.data} size={icon.size} colour={icon.colour} />
        </div>
      )}
      isVisible={isVisible}
      onHide={() => {
        if (onHide) {
          onHide();
        }

        dispatch(hideAlertPopup(tag));
      }}
      {...passThrough} />
  );
}

AlertPopup.defaultProps = {
  content: null,
  icon: null,
  button: null,
  closeable: true,
  onHide: null,
};

AlertPopup.propTypes = {
  tag: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.any,
        value: PropTypes.any
      })
    )
  ]),
  icon: PropTypes.shape({
    data: PropTypes.string,
    size: PropTypes.number,
    colour: PropTypes.string,
    customClasses: PropTypes.string,
  }),
  button: PropTypes.shape({
    text: PropTypes.string,
    className: PropTypes.string,
    func: PropTypes.func
  }),
  closeable: PropTypes.bool,
  onHide: PropTypes.func,
};
