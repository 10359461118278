import { normalize, schema } from 'normalizr';
import assign from 'lodash/assign';
import pick from 'lodash/pick';

// update cacheVersionNumber when making backwards incompatible changes to the cache
import { cacheVersionNumber } from 'utility/constants';

import {
  CACHE_PROJECT,
  CACHE_STOCK,
  CACHE_FEATURED_PROJECTS,
  CACHE_SEARCH_RESULTS,
  CACHE_RECENTLY_UPDATED_PROJECTS,
  CACHE_BROCHURE,
  CACHE_PROJECT_EVENT_STATISTICS,
  CACHE_PROJECT_VIDEO_REFERENCE,
  CACHE_VIDEO,
  CACHE_VIDEOS,
  PURGE_VIDEO,
  PURGE_PROJECT_VIDEO_REFERENCE
} from './CacheActions';

// Merges objects to 2 levels of depth; so the objects themselves will be merged,
// and the matching properties of the objects will be merged shallowly.
function merge2Deep(destination, ...sources) {
  sources.filter((source) => typeof source === 'object')
    .forEach((source) => {
      Object.entries(source)
        .forEach(([key, value]) => {
          let object = destination[key];
          switch (typeof object) {
            case 'undefined':
              object = {};
              destination[key] = object;
              break;

            case 'object':
              if (object === null) {
                object = {};
                destination[key] = object;
              }
              break;

            default:
              return;
          }

          assign(object, value);
        });
    });

  return destination;
}

const organisationSchema = new schema.Entity('organisation');
const stockSchema = new schema.Entity('stock');
const videoSchema = new schema.Entity('video', {}, { idAttribute: 'id' });
const projectSchema = new schema.Entity('project', {
  seller: organisationSchema,
  stocks: [stockSchema],
  videos: [videoSchema]
}, {
  idAttribute: 'id'
});
stockSchema.define({
  project: projectSchema,
});

const userSchema = new schema.Entity('user');
const brochureSchema = new schema.Entity('brochure', {
  owner: userSchema,
  projects: [projectSchema],
  stocks: [stockSchema],
});

const countrySchema = new schema.Entity('country');


function cacheProject(state, project) {
  if (!project) {
    return state;
  }

  const normalisedData = normalize(project, projectSchema);

  const newState = {
    ...state,
  };

  if (normalisedData.entities && normalisedData.entities.project) {
    newState.projects = merge2Deep({}, state.projects, normalisedData.entities.project);
  }

  if (normalisedData.entities && normalisedData.entities.organisation) {
    newState.organisations = merge2Deep(
      {},
      newState.organisations,
      normalisedData.entities.organisation,
    );
  }

  if (normalisedData.entities && normalisedData.entities.stock) {
    newState.stocks = merge2Deep({}, newState.stocks, normalisedData.entities.stock);
    // replace the stock list in the project as it could contain out of date information
    const { stocks } = normalisedData.entities.project[normalisedData.result];
    newState.projects[normalisedData.result].stocks = [...stocks];
  }

  if (normalisedData.entities && normalisedData.entities.video) {
    newState.videos = merge2Deep({}, newState.videos, normalisedData.entities.video);
    // replace the video list in the project as it could contain out of date information
    const { videos } = normalisedData.entities.project[normalisedData.result];
    newState.projects[normalisedData.result].videos = [...videos];
  }

  return newState;
}

function cacheStock(state, action) {
  const normalisedData = normalize(action.stock, stockSchema);

  const newState = {
    ...state,
  };
  if (normalisedData.entities && normalisedData.entities.stock) {
    newState.stocks = merge2Deep({}, newState.stocks, normalisedData.entities.stock);
  }
  if (normalisedData.entities && normalisedData.entities.project) {
    newState.projects = merge2Deep({}, newState.projects, normalisedData.entities.project);
  }
  if (normalisedData.entities && normalisedData.entities.organisation) {
    newState.organisations = merge2Deep(
      {},
      newState.organisations,
      normalisedData.entities.organisation,
    );
  }
  return newState;
}

function cacheVideo(state, { video }) {
  const newState = {
    ...state
  };

  const normalizedData = normalize(video, videoSchema);

  newState.videos = merge2Deep({}, newState.videos, normalizedData.entities.video);

  return newState;
}

function cacheVideos(state, { videos }) {
  const newState = {
    ...state
  };

  const normalizedData = normalize(videos, [videoSchema]);

  newState.videos = merge2Deep({}, newState.videos, normalizedData.entities.video);

  return newState;
}

function cacheProjectVideoReference(state, { projectId, videoId }) {
  const newState = {
    ...state
  };

  const project = state.projects[projectId];

  if (!project) {
    return newState;
  }

  if (project.videos) {
    if (project.videos.indexOf(videoId) < 0) {
      project.videos.push(videoId);
    }
  } else {
    project.videos = [videoId];
  }

  return newState;
}

function purgeVideo(state, videoId) {
  const videos = state.videos || { };
  const keys = Object.keys(videos);
  const keyExists = keys.find((id) => id === videoId) !== undefined;
  return keyExists ? { ...state, videos: pick(videos, keys.filter((k) => k !== videoId)) } : state;
}

function purgeProjectVideoReference(state, projectId, videoId) {
  const projects = state.projects || { };
  const projectKeys = Object.keys(projects);
  const projectKeyExists = projectKeys.find((id) => id === projectId) !== undefined;
  if (!projectKeyExists) {
    return state;
  }

  const videosState = projects[projectId].videos;
  const videoKeyExists = videosState.find((id) => id === videoId) !== undefined;
  return videoKeyExists
    ? {
      ...state,
      projects: {
        ...projects,
        [projectId]: {
          ...projects[projectId],
          videos: videosState.filter((id) => id !== videoId)
        }
      }
    }
    : state;
}

function cacheSearchResults(state, action) {
  const newState = {
    ...state,
  };
  if (action.results) {
    action.results.forEach((result) => {
      const normalisedData = normalize(result.item, projectSchema);
      if (normalisedData.entities && normalisedData.entities.project) {
        newState.projects = merge2Deep({}, newState.projects, normalisedData.entities.project);
      }
      if (normalisedData.entities && normalisedData.entities.organisation) {
        newState.organisations = merge2Deep(
          {},
          newState.organisations,
          normalisedData.entities.organisation,
        );
      }
    });
  }
  return newState;
}

function cacheFeaturedProjects(state, action) {
  let newState = {
    ...state,
  };

  if (action.featured.projects) {
    action.featured.projects.forEach((project) => {
      const normalisedData = normalize(project, projectSchema);
      if (normalisedData.entities && normalisedData.entities.project) {
        newState.projects = merge2Deep({}, newState.projects, normalisedData.entities.project);
      }
      if (normalisedData.entities && normalisedData.entities.organisation) {
        newState.organisations = merge2Deep(
          {},
          newState.organisations,
          normalisedData.entities.organisation,
        );
      }
    });
  }

  let hashMap = {};
  action.featured.featuredProjectInfo.forEach((featured) => {
    hashMap = {
      ...hashMap,
      [featured.projectId]: {
        ...featured,
      },
    };
  });

  newState = {
    ...newState,
    featuredProjects: {
      staleBy: action.featured.staleBy,
      featuredProjectInfo: hashMap,
    },
  };
  return newState;
}

function cacheRecentlyUpdatedProjects(state, action) {
  const newState = {
    ...state,
  };
  const recentlyUpdatedProjects = [];

  if (action.recentlyUpdated) {
    action.recentlyUpdated.forEach((project) => {
      const normalisedData = normalize(project.item, projectSchema);
      if (normalisedData.entities && normalisedData.entities.project) {
        newState.projects = merge2Deep({}, newState.projects, normalisedData.entities.project);
      }
      if (normalisedData.entities && normalisedData.entities.organisation) {
        newState.organisations = merge2Deep(
          {},
          newState.organisations,
          normalisedData.entities.organisation,
        );
      }
      recentlyUpdatedProjects.push(project.item.id);
    });
  }

  newState.recentlyUpdatedProjects = recentlyUpdatedProjects;
  return newState;
}

function cacheBrochure(state, action) {
  const normalisedData = normalize(action.brochure, brochureSchema);

  const newState = {
    ...state,
  };
  if (normalisedData.entities && normalisedData.entities.brochure) {
    newState.brochures = merge2Deep({}, newState.brochures, normalisedData.entities.brochure);
  }
  if (normalisedData.entities && normalisedData.entities.project) {
    newState.projects = merge2Deep({}, newState.projects, normalisedData.entities.project);
  }
  if (normalisedData.entities && normalisedData.entities.organisation) {
    newState.organisations = merge2Deep(
      {},
      newState.organisations,
      normalisedData.entities.organisation,
    );
  }
  if (normalisedData.entities && normalisedData.entities.user) {
    newState.users = merge2Deep({}, newState.users, normalisedData.entities.user);
  }
  if (normalisedData.entities && normalisedData.entities.stock) {
    newState.stocks = merge2Deep({}, newState.stocks, normalisedData.entities.stock);
  }
  return newState;
}

function cacheProjectEventStatistics(state, action) {
  const newState = {
    ...state,
  };
  if (action.projectEventStatisticResults.projects) {
    action.projectEventStatisticResults.projects.forEach((project) => {
      const normalisedData = normalize(project, projectSchema);
      if (normalisedData.entities && normalisedData.entities.project) {
        newState.projects = merge2Deep({}, newState.projects, normalisedData.entities.project);
      }
    });
  }
  if (action.projectEventStatisticResults.agents) {
    action.projectEventStatisticResults.agents.forEach((organisation) => {
      const normalisedData = normalize(organisation, organisationSchema);
      if (normalisedData.entities && normalisedData.entities.organisation) {
        newState.organisations = merge2Deep(
          {},
          newState.organisations,
          normalisedData.entities.organisation,
        );
      }
    });
  }
  if (action.projectEventStatisticResults.users) {
    action.projectEventStatisticResults.users.forEach((user) => {
      const normalisedData = normalize(user, userSchema);
      if (normalisedData.entities && normalisedData.entities.user) {
        newState.users = merge2Deep({}, newState.users, normalisedData.entities.user);
      }
    });
  }
  if (action.projectEventStatisticResults.countries) {
    action.projectEventStatisticResults.countries.forEach((country) => {
      const normalisedData = normalize(country, countrySchema);
      if (normalisedData.entities && normalisedData.entities.country) {
        newState.countries = merge2Deep({}, newState.countries, normalisedData.entities.country);
      }
    });
  }

  return newState;
}

const initialState = {
  projects: {},
  stocks: {},
  organisations: {},
  users: {},
  brochures: {},
  featuredProjects: {},
  recentlyUpdatedProjects: [],
  projectOverviews: {},
  versionNumber: 0,
  countries: {},
};

function purgeCache() {
  return { ...initialState };
}

function rehydrateCache(state, action) {
  if (action.payload && action.payload.cache.versionNumber !== cacheVersionNumber) {
    const newState = purgeCache();
    newState.versionNumber = cacheVersionNumber;
    return newState;
  }
  return state;
}

export default function CacheReducers(state = initialState, action) {
  switch (action.type) {
    case CACHE_PROJECT:
      return cacheProject(state, action.project);
    case CACHE_STOCK:
      return cacheStock(state, action);
    case CACHE_SEARCH_RESULTS:
      return cacheSearchResults(state, action);
    case CACHE_FEATURED_PROJECTS:
      return cacheFeaturedProjects(state, action);
    case CACHE_RECENTLY_UPDATED_PROJECTS:
      return cacheRecentlyUpdatedProjects(state, action);
    case CACHE_BROCHURE:
      return cacheBrochure(state, action);
    case CACHE_PROJECT_EVENT_STATISTICS:
      return cacheProjectEventStatistics(state, action);
    case CACHE_PROJECT_VIDEO_REFERENCE:
      return cacheProjectVideoReference(state, action);
    case CACHE_VIDEO:
      return cacheVideo(state, action);
    case CACHE_VIDEOS:
      return cacheVideos(state, action);
    case PURGE_VIDEO:
      return purgeVideo(state, action.videoId);
    case PURGE_PROJECT_VIDEO_REFERENCE:
      return purgeProjectVideoReference(state, action.projectId, action.videoId);

    case 'persist/REHYDRATE':
      return rehydrateCache(state, action);
    case 'persist/PURGE':
      return purgeCache();

    default:
      return state;
  }
}
