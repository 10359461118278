import { useDispatch } from 'react-redux';
import { showSignupDialog } from 'components/SignupDialog/SignupDialogActions';

function useSignup() {
  const dispatch = useDispatch();

  return (signupType, customerType) => dispatch(showSignupDialog(signupType, customerType));
}

export default useSignup;
