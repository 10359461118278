import PropTypes from 'prop-types';

import { externalUrlPath } from 'utility/redirect';

const LegacyPage = ({
  location: { search, hash },
  match: { params: { remainingPathSegments } }
}) => {
  window.location.replace(externalUrlPath(`${remainingPathSegments}${search}${hash}`));

  return null;
};

LegacyPage.propTypes = {
  match: PropTypes.object.isRequired
};

export default LegacyPage;
